import * as React from "react";
import {SVGProps} from "react";

export const IconStaff = (props: SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", width = "1em", height = "1em", ...rest} = props;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M11.9999 3.5C12.6937 3.5 13.372 3.70574 13.9489 4.0912C14.5258 4.47667 14.9754 5.02454 15.2409 5.66555C15.5064 6.30655 15.5759 7.01189 15.4405 7.69238C15.3052 8.37286 14.9711 8.99793 14.4805 9.48853C13.9898 9.97913 13.3648 10.3132 12.6843 10.4486C12.0038 10.584 11.2985 10.5145 10.6575 10.249C10.0165 9.98346 9.46859 9.53383 9.08313 8.95694C8.69766 8.38005 8.49192 7.70182 8.49192 7.008C8.49324 6.07803 8.86326 5.18652 9.52085 4.52893C10.1784 3.87134 11.0699 3.50132 11.9999 3.5V3.5ZM11.9999 2C11.0095 2.0002 10.0413 2.29408 9.2179 2.84449C8.39447 3.39489 7.75274 4.1771 7.37385 5.09221C6.99496 6.00732 6.89592 7.01423 7.08927 7.98563C7.28262 8.95702 7.75966 9.84926 8.46008 10.5495C9.16051 11.2498 10.0528 11.7267 11.0243 11.9198C11.9957 12.113 13.0026 12.0138 13.9176 11.6347C14.8327 11.2556 15.6147 10.6137 16.165 9.79019C16.7152 8.96665 17.0089 7.99845 17.0089 7.008C17.0089 6.35026 16.8794 5.69895 16.6276 5.09129C16.3759 4.48363 16.0069 3.9315 15.5418 3.46646C15.0766 3.00141 14.5244 2.63254 13.9167 2.38092C13.309 2.12931 12.6577 1.99987 11.9999 2V2Z"
                fill={color}
            />
            <path
                d="M14 15.457C14.9283 15.457 15.8185 15.8258 16.4749 16.4822C17.1313 17.1385 17.5 18.0288 17.5 18.957V20.033C17.5 20.1656 17.4473 20.2928 17.3536 20.3866C17.2598 20.4804 17.1326 20.533 17 20.533H7C6.86739 20.533 6.74021 20.4804 6.64645 20.3866C6.55268 20.2928 6.5 20.1656 6.5 20.033V18.957C6.5 18.0288 6.86875 17.1385 7.52513 16.4822C8.1815 15.8258 9.07174 15.457 10 15.457H14V15.457ZM14 13.957H10C8.67392 13.957 7.40215 14.4838 6.46447 15.4215C5.52678 16.3592 5 17.6309 5 18.957V20.033C5 20.5635 5.21071 21.0722 5.58579 21.4472C5.96086 21.8223 6.46957 22.033 7 22.033H17C17.5304 22.033 18.0391 21.8223 18.4142 21.4472C18.7893 21.0722 19 20.5635 19 20.033V18.957C19 17.6309 18.4732 16.3592 17.5355 15.4215C16.5979 14.4838 15.3261 13.957 14 13.957Z"
                fill={color}
            />
        </svg>
    );
};
