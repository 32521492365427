import gql from "graphql-tag";
import * as React from "react";
import {useQuery} from "react-apollo";
import {useParams} from "react-router-dom";
import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";
import universityFragment from "shared/University/fragments/universityFragment";

import {
    UniversityFeatureField,
    Maybe,
    GetUniversityProviderQueryQuery,
    GetUniversityProviderQueryQueryVariables,
    CustomAttributeField,
    CountryField,
} from "../../../types/generated";
import {universityCreateVisitorLogoUrl} from "../../../util/universityCreateVisitorLogoUrl";

const SLUG_ERROR_MESSAGE =
    "GraphQL error: 404 Not Found: The requested URL was not found on the server. If you entered the URL manually please check your spelling and try again.";

export const GetUniversityProviderQuery = gql`
    query GetUniversityProviderQuery($universitySlug: String) {
        university(slug: $universitySlug) {
            ...UniversityFull
            mentorMessagesCount
            customAttributes(destination: "applicant_profile", active: true) {
                id
                type
                name
                active
                required
                options {
                    id
                    value
                }
            }
        }
    }
    ${universityFragment}
`;

type BaseUniversityContextType = {
    name: string;
    id: string;
    slug: string;
    color: Maybe<string>;
    features: Map<string, boolean>;
    country: CountryField;
    customAttributes: Maybe<Array<Maybe<CustomAttributeField>>>;
    privacyPolicyUrl: string;
    squareLogo: string;
};

type UniversityContextType = BaseUniversityContextType &
    NonNullable<GetUniversityProviderQueryQuery["university"]>;

const UniversityContext = React.createContext<UniversityContextType>({
    name: "",
    id: "",
    slug: "",
    color: "",
    country: null,
    customAttributes: null,
    features: new Map(),
    privacyPolicyUrl: "",
    squareLogo: "",
});

export const UniversityProvider = ({children}) => {
    const params = useParams<{universitySlug: string}>();
    const universitySlug: string = params?.universitySlug ?? "";
    const {reportError} = useErrorReporting();
    const {data, loading, error} = useQuery<
        GetUniversityProviderQueryQuery,
        GetUniversityProviderQueryQueryVariables
    >(GetUniversityProviderQuery, {
        variables: {
            universitySlug,
        },
    });

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!data || error) {
        if (error && error.message === SLUG_ERROR_MESSAGE) {
            reportError(error, {
                tags: {universitySlug, invalidSlug: true},
            });
            return (
                <p>
                    An error occurred. Could not get the university details due to an incorrect
                    university slug. Please make sure that the widget has been embedded correctly.
                </p>
            );
        }
        if (error) {
            reportError(error);
        }
        return <p>An error occurred. Please try again later.</p>;
    }

    const university = data?.university;
    const universityConfigFeatures = university?.config?.features ?? [];
    const countryField = {
        id: university?.country?.id ?? "",
        name: university?.country?.name ?? "",
        code: university?.country?.code ?? "",
    };

    // TODO: Should we memoise this?
    const value: UniversityContextType = {
        ...university,
        slug: universitySlug ?? "not-found-university-slug",
        id: university?.id ?? "not-found-university-id",
        name: university?.name ?? "not-found-university-name",
        color: university?.colour,
        features: universityConfigFeatures.reduce(
            (acc: Map<string, boolean>, cur: UniversityFeatureField) => {
                acc.set(cur.feature.name, cur.enabled);
                return acc;
            },
            new Map<string, boolean>(),
        ),
        country: countryField,
        customAttributes: university?.customAttributes ?? [],
        privacyPolicyUrl: university?.privacyPolicyUrl ?? "",
        squareLogo: university?.squareLogo ?? "",
        whiteLogo: universityCreateVisitorLogoUrl(university.whiteLogo),
    };
    return <UniversityContext.Provider value={value}>{children}</UniversityContext.Provider>;
};

export const useUniversity = () => React.useContext(UniversityContext);
