import React from "react";
import Icon from "shared/General/components/Icon/Icon";
import {HeaderTab, HeaderTabProps} from "../HeaderTab/HeaderTab";
import s from "./SvgTabs.pcss";

type SvgTabProps = Pick<HeaderTabProps, "id" | "to" | "name" | "onClick" | "data-test-id"> & {
    icon: string | ((isActive: boolean) => React.ReactElement);
};

export const SvgTab = ({id, name, onClick, to, icon}: SvgTabProps) => (
    <HeaderTab id={id} name={name} onClick={onClick} to={to}>
        {({isActive}) => {
            return typeof icon === "string" ? (
                <Icon
                    className={isActive ? s.svgIconSelected : s.svgIcon}
                    name={icon}
                    height={25}
                    width={25}
                />
            ) : (
                icon(isActive)
            );
        }}
    </HeaderTab>
);
