import gql from "graphql-tag";
import BlogPostPreviewFragment from "../../Blog/fragments/blogPostPreviewFragment";
import MentorFieldsFragment from "../../Mentor/fragments/mentorFieldsFragment";

const MentorProfileQuery = gql`
    query MentorProfileQuery(
        $id: String
        $isDegreeTypesEnabled: Boolean = false
        $isBlogsEnabled: Boolean = true
    ) {
        publicMentor(id: $id) {
            id
            active
            firstName
            lastName
            profilePhoto(raw: true)
            lastSeen
            degree @skip(if: $isDegreeTypesEnabled) {
                id
                name
            }
            degrees @include(if: $isDegreeTypesEnabled) {
                degreeType
                degree {
                    id
                    name
                }
            }
            degreeLevel
            accountRole
            placeName
            country {
                id
                code
                name
            }
            languages {
                id
                name
            }
            year
            favouriteModules {
                id
                name
            }
            pastQualification
            interests {
                id
                name
            }
            societies {
                id
                name
            }
            bio
            university {
                id
                name
                widgetUrl
            }
            shareUrl
            customAttributes(active: true) {
                value
                attribute {
                    id
                    name
                    type
                }
                ... on SingleChoiceValueField {
                    option {
                        id
                        value
                    }
                }
            }
            mentorFields {
                ...MentorFieldsFull
            }
        }

        mentorPosts(mentorId: $id) @include(if: $isBlogsEnabled) {
            ...BlogPostPreviewFragment
        }
    }

    ${BlogPostPreviewFragment}
    ${MentorFieldsFragment}
`;

export default MentorProfileQuery;
