import * as React from "react";
import {SVGProps} from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", ...rest} = props;
    return (
        <svg
            width={17}
            height={17}
            fill="none"
            viewBox="0 0 17 17"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M10 2.333A1.667 1.667 0 0 1 11.667 4v5.92L7.92 13.667H6A1.667 1.667 0 0 1 4.333 12V4A1.667 1.667 0 0 1 6 2.333h4Zm0-1H6A2.667 2.667 0 0 0 3.333 4v8A2.667 2.667 0 0 0 6 14.667h2.059c.176 0 .346-.07.47-.195l3.942-3.942a.668.668 0 0 0 .196-.472V4A2.667 2.667 0 0 0 10 1.333Z"
                fill={color}
            />
            <path
                d="M10 4.668H6a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1ZM7.673 6.614H6a.5.5 0 0 1 0-1h1.673a.5.5 0 0 1 0 1ZM8.835 14.137h-1v-3.182a1.122 1.122 0 0 1 1.121-1.12h3.206v1H8.956a.12.12 0 0 0-.086.035.12.12 0 0 0-.035.085v3.182Z"
                fill={color}
            />
        </svg>
    );
};

export default SvgComponent;
