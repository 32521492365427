import React from "react";
import get from "lodash/get";
import Auth from "shared/Auth/auth";
import ProgressBar from "shared/General/components/ProgressBar/ProgressBar";
import {isFeatureEnabled} from "shared/Utils/FeatureUtils";
import {Minor} from "./Minor";
import {VerifyAgeComponent} from "./VerifyAge";

/**
 * This HOC does the age verification check of user and
 * returns WrappedComponent if the user can access wrapped component
 * else returns VerifyAge component
 * @param WrappedComponent
 * @returns function(*=): *
 */
function withAgeVerification(WrappedComponent) {
    const AgeVerificationHoc = (props) => {
        // eslint-disable-next-line no-unused-vars
        const [reload, setReload] = React.useState(false);
        const me = get(props, "authState.me", {});
        const isApplicantUniversitySetup = Auth.isApplicantUniversitySetup(
            me,
            props.universitySlug,
        );
        const university = get(props, "university", {});
        const ageVerificationInfo = get(me, "applicant.ageVerificationInfo", false);

        if (!isApplicantUniversitySetup && isFeatureEnabled("ageVerificationR1", university)) {
            if (!ageVerificationInfo) {
                return <VerifyAgeComponent {...props} setReload={setReload} />;
            } else if (ageVerificationInfo && ageVerificationInfo.isMinor) {
                return <Minor {...props} />;
            }
        }
        return university ? <WrappedComponent {...props} /> : <ProgressBar start />;
    };

    return AgeVerificationHoc;
}

export default withAgeVerification;
