import {Box} from "@unibuddy/patron";
import React from "react";

export const ContentContainer = ({children}) => {
    const isOnIntroScreen = window.location.pathname.includes("/buddies/intro");

    return (
        <Box
            minH={isOnIntroScreen ? "0px" : "550px"}
            paddingX={["xsmall", "small"]}
            display="flex"
            flexDirection={"column"}
        >
            {children}
        </Box>
    );
};
