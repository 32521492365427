import React, {useEffect, useState} from "react";
import {Box, Heading, TextBlock} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";
import {toTitleCase} from "../../../../../shared/Utils/StringUtils";
import {useGetProspectStageQuery} from "../../../Chat/components/PopupQuestions/prospectStages";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {HandWave} from "../../images/HandWave";
import {IntlNamespaces} from "../../../../../translations/types";
import {
    INTL_RECOMMENDATION_PAGE_GENERIC_TEXT,
    INTL_RECOMMENDATION_PAGE_PERSONALISED_TEXT,
} from "../../../../../translations/recommendations";

type RecommendationGreetingProps = {
    name: string;
};

const RecommendationGreeting = ({name}: RecommendationGreetingProps) => {
    const {id} = useUniversity();
    const {data: decisionStageData} = useGetProspectStageQuery(id);

    const {ready, t} = useTranslation(IntlNamespaces.RECOMMENDATIONS);
    const [greetingText, setGreetingText] = useState(
        t(
            INTL_RECOMMENDATION_PAGE_GENERIC_TEXT.key,
            INTL_RECOMMENDATION_PAGE_GENERIC_TEXT.defaultValue,
        ),
    );

    useEffect(() => {
        if (decisionStageData) {
            if (decisionStageData.currentUserDecisionStage.updated) {
                setGreetingText(
                    t(
                        INTL_RECOMMENDATION_PAGE_PERSONALISED_TEXT.key,
                        INTL_RECOMMENDATION_PAGE_PERSONALISED_TEXT.defaultValue,
                    ),
                );
            }
        }
    }, [decisionStageData, t]);

    if (!ready) return <p>Loading...</p>;

    return (
        <Box display="flex" flexDirection="column" marginBottom="large">
            <Box display="flex">
                <Box display="flex" paddingRight="xsmall">
                    <HandWave />
                </Box>
                {name !== "" && (
                    <Box display="flex" alignItems="center">
                        <Heading level="3">{toTitleCase(name)}!</Heading>
                    </Box>
                )}
            </Box>
            <Box display="flex" marginTop="small">
                <TextBlock>{greetingText}</TextBlock>
            </Box>
        </Box>
    );
};

export default RecommendationGreeting;
