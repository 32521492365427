import {getUniversitySlug} from "shared/Utils/CookieConsentUtils";
import {findProductByPath} from "shared/Utils/WhereAmI";
import {SSO_CTA_URL, SSO_ERROR_URL, SSO_NEXT_PATH} from "./constants";

export function setSSOErrorUrl() {
    const slug = getUniversitySlug(window.location.href);
    const CTA_URL = getSSOCtaUrl();
    sessionStorage.setItem(`${SSO_ERROR_URL}_${slug}`, CTA_URL);
}

export function setSSOCtaUrl(path: string) {
    const slug = getUniversitySlug(window.location.href);
    sessionStorage.setItem(`${SSO_CTA_URL}_${slug}`, path ?? "");
}

export function getSSOErrorUrl() {
    const slug = getUniversitySlug(window.location.href);
    return sessionStorage.getItem(`${SSO_ERROR_URL}_${slug}`);
}

export function getSSOCtaUrl() {
    const slug = getUniversitySlug(window.location.href);
    return sessionStorage.getItem(`${SSO_CTA_URL}_${slug}`);
}

export function getSSONextPath() {
    const slug = getUniversitySlug(window.location.href);
    const nextPathKey = `${SSO_NEXT_PATH}_${slug}`;
    const nextPath = sessionStorage.getItem(nextPathKey);

    if (!nextPath) {
        return null;
    }

    const currentPath = window.location.pathname;
    const withinProduct = findProductByPath(currentPath) === findProductByPath(nextPath);

    if (nextPath && !withinProduct) {
        sessionStorage.removeItem(nextPathKey);
        return null;
    }

    return nextPath;
}

export function removeSSOErrorKeys() {
    const slug = getUniversitySlug(window.location.href);
    sessionStorage.removeItem(`${SSO_CTA_URL}_${slug}`);
    sessionStorage.removeItem(`${SSO_ERROR_URL}_${slug}`);
}

export function removeSSONextPath() {
    const slug = getUniversitySlug(window.location.href);
    sessionStorage.removeItem(`${SSO_NEXT_PATH}_${slug}`);
}
