import gql from "graphql-tag";

const AppLocalesQuery = gql`
    query AppLocalesQuery($appName: String!) {
        appLocales(appName: $appName) {
            name
            locales {
                id
                name
                locale
            }
            universityLocalePreferenceApplies
        }
    }
`;

export const AppLocalesQueryWithUniPreference = gql`
    query AppLocalesQuery($appName: String!, $universitySlug: String) {
        appLocales(appName: $appName) {
            name
            locales {
                id
                name
                locale
            }
            universityLocalePreferenceApplies
        }
        university(slug: $universitySlug) {
            id
            localePreference {
                id
                name
                locale
            }
        }
    }
`;

export default AppLocalesQuery;
