import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import classNames from "classnames";
import LastSeen from "../../../Mentor/components/LastSeen/LastSeen";
import CenteredImage from "../../../General/components/CenteredImage/CenteredImage";
import MentorFlags from "../MentorFlags/MentorFlags";
import s from "./MentorCardBanner.pcss";

const MentorCardBanner = (props) => {
    let avatar = (
        <div className={s.avatarContainer}>
            <CenteredImage
                className={s.avatar}
                alt={`Mentor ${props.name}`}
                src={props.profilePhoto}
            />
            {props.isShowCountryFlagEnabled && (
                <MentorFlags
                    countryCode={props.countryCode}
                    primaryFlag={props.primaryFlag}
                    secondaryFlag={props.secondaryFlag}
                />
            )}
        </div>
    );

    if (props.imageLink) {
        avatar = props.externalImageLink ? (
            <a
                onClick={props.onImageClicked}
                href={props.imageLink}
                data-test-id="avatar-external-link"
                target="_blank"
                rel="noreferrer noopener"
            >
                {avatar}
            </a>
        ) : (
            <Link
                onClick={props.onImageClicked}
                data-test-id="avatar-internal-link"
                to={props.imageLink}
            >
                {avatar}
            </Link>
        );
    }
    return (
        <div className={classNames(props.className, s.container)}>
            <div className={classNames(s.top, s.topText)}>
                <div className={s.topLeft}>
                    <LastSeen date={props.lastSeen} />
                    {props.topLeft ? (
                        <div
                            className={classNames(s.topLeftText, s.text)}
                            data-test-id="topLeftSection"
                        >
                            {props.topLeft}
                        </div>
                    ) : null}
                </div>
                <div className={s.topRight}>
                    {props.topRight ? (
                        <div
                            data-test-id="topRightSection"
                            className={classNames(s.topRight, s.text)}
                        >
                            {props.topRight}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={s.bottom} />
            <div
                className={classNames("avatar", s.imageContainer, {
                    [s.notClickable]: !props.imageClickable,
                })}
            >
                {avatar}
            </div>
        </div>
    );
};

MentorCardBanner.propTypes = {
    name: PropTypes.string.isRequired,
    profilePhoto: PropTypes.string.isRequired,
    imageLink: PropTypes.string,
    onImageClicked: PropTypes.func,
    imageClickable: PropTypes.bool,
    topRight: PropTypes.node,
    topLeft: PropTypes.node,
    lastSeen: PropTypes.string,
    className: PropTypes.string,
    externalImageLink: PropTypes.bool,
    primaryFlag: PropTypes.string,
    secondaryFlag: PropTypes.string,
    countryCode: PropTypes.string,
    isShowCountryFlagEnabled: PropTypes.bool,
};

MentorCardBanner.defaultProps = {
    imageLink: null,
    imageClickable: true,
    onImageClicked: () => true,
    topRight: null,
    topLeft: null,
    lastSeen: null,
    className: "",
    externalImageLink: false,
    primaryFlag: "",
    secondaryFlag: "",
};

export default MentorCardBanner;
