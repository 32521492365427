import React from "react";
import {Redirect, useLocation} from "react-router-dom";
import {rootURL} from "shared/Utils/WhereAmI";
import useAuth from "shared/Auth/hooks/useAuth";
import Auth from "shared/Auth/auth";
import {useUniversity} from "../../UniversityProvider/UniversityProvider";

type RegisterPage2Wrapper = {
    children: React.ReactNode;
};

export function RegisterPage2Wrapper({children}) {
    const {slug} = useUniversity();
    const location = useLocation();
    const {authState} = useAuth();
    const rootUrl = rootURL(location.pathname);
    const currentApplicantUniversityLink = Auth.getCurrentApplicantUniversity(authState?.me, slug);
    const isInbox = location.pathname.split("/").includes("inbox");

    let toRelink = false;
    if (location?.state?.from === "delinked") {
        toRelink = true;
    }

    if (
        Auth.loggedIn(authState) &&
        (!currentApplicantUniversityLink ||
            (currentApplicantUniversityLink.delinked && (!isInbox || toRelink)))
    ) {
        return (
            <Redirect
                to={{
                    pathname: `${rootUrl}/auth/register2`,
                    state: {nextPathname: location.pathname},
                }}
            />
        );
    }
    return <>{children}</>;
}
