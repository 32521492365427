import React from "react";
import {View} from "@unibuddy/patron";

type CardHeaderInfoWrapperProps = {
    children: React.ReactNode;
};

export const CardHeaderInfoWrapper = ({children}: CardHeaderInfoWrapperProps) => {
    return (
        <View flexDirection="row" alignItems="center" gap="small-medium">
            {children}
        </View>
    );
};
