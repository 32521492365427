import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {IconTab} from "../IconTab/IconTab";
import {IconStudent} from "../../../Icons";
import {INTL_STUDENTS} from "../../../../../translations/common";
import {IntlNamespaces} from "../../../../../translations/types";

export default function BuddiesHeaderTab(props) {
    const {t} = useTranslation(IntlNamespaces.COMMON);

    return (
        <IconTab
            id="students"
            name={t(INTL_STUDENTS.key, INTL_STUDENTS.defaultValue)}
            to={props.to}
            icon={() => <IconStudent width={32} height={32} />}
        />
    );
}
