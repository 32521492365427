import React from "react";
import {Box} from "@unibuddy/patron";

import {FlagField} from "../../../../types/generated";

export type FlagProps = {
    alt: string;
    countryCode: string;
};

export type CardAvatarProps = {
    url: string;
    alt: string;
    primaryFlag?: FlagField;
    secondaryFlag?: FlagField;
};

export const DEFAULT_CDN_URL = "https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/";

const BORDER_WIDTH = 2;
const AVATAR_IMAGE_SIZE = 108;
const AVATAR_CONTAINER_SIZE = AVATAR_IMAGE_SIZE + 2 * BORDER_WIDTH;

const FLAG_HEIGHT = 24;
const FLAG_WIDTH = 32;
const FLAG_CONTAINER_HEIGHT = FLAG_HEIGHT + 2 * BORDER_WIDTH;
const FLAG_CONTAINER_WIDTH = FLAG_WIDTH + 2 * BORDER_WIDTH;

const Flag = ({alt, countryCode}: FlagProps) => {
    return (
        <Box
            w={FLAG_CONTAINER_WIDTH}
            h={FLAG_CONTAINER_HEIGHT}
            borderWidth={2}
            borderColor="white"
            borderRadius="xxsmall"
            overflow="hidden"
            justifyContent="center"
            alignItems="center"
            display="flex"
        >
            <img
                alt={alt}
                src={`${DEFAULT_CDN_URL}${countryCode.toLowerCase()}.svg`}
                style={{
                    objectFit: "fill",
                }}
            />
        </Box>
    );
};

export const CardAvatar = ({url, alt, primaryFlag, secondaryFlag}: CardAvatarProps) => {
    return (
        <Box flexShrink={0} position="relative">
            <Box
                w={AVATAR_CONTAINER_SIZE}
                h={AVATAR_CONTAINER_SIZE}
                bgColor="white"
                borderRadius="full"
                justifyContent="center"
                alignItems="center"
                display="flex"
            >
                <img
                    alt={alt}
                    src={url}
                    style={{
                        width: AVATAR_IMAGE_SIZE,
                        height: AVATAR_IMAGE_SIZE,
                        borderRadius: "50%",
                        objectFit: "cover",
                    }}
                />
            </Box>
            {(primaryFlag || secondaryFlag) && (
                <Box position="absolute" bottom={0} width="100%" height={FLAG_CONTAINER_HEIGHT}>
                    <Box display="flex" justifyContent="space-between" flexDirection="row-reverse">
                        {/* Render secondaryFlag first because we are using row-reverse */}
                        {secondaryFlag && (
                            <Flag
                                alt={`${primaryFlag ? "Secondary" : "Primary"} country flag ${
                                    secondaryFlag.name
                                }`}
                                countryCode={secondaryFlag.code}
                            />
                        )}
                        {primaryFlag && (
                            <Flag
                                alt={`Primary country flag ${primaryFlag.name}`}
                                countryCode={primaryFlag.code}
                            />
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    );
};
