import get from "lodash/get";
import PropTypes from "prop-types";
import React, {Component} from "react";

export class GlobalCssVariable extends Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        value: PropTypes.string,
    };

    static defaultProps = {
        value: "",
    };

    update() {
        const body = get(window, "document.body");
        if (body) {
            body.style.setProperty(this.props.name, this.props.value);
        }
    }

    render() {
        this.update();
        return null;
    }
}

/**
 * HOC to apply css variables to a DOM element so that they can be used in our css styling.
 * Main use case is getting the theme colour out of props so that we can do more advanced
 * styling without excessive inline styles.
 *
 * @example
 * withCSSVariables([
 *      { cssName: "--theme-primary", value: props => `#${props.match.params.colourCode}`,},
 * ])(MyComponent)
 * // in MyComponent.pcss you can use var(--theme-primary) to access the variable

 * @param themeVariables {Array<Object>}
 * @returns {function(*): ThemeVariableContainer}
 */
export default function withCSSVariables(themeVariables) {
    return (WrappedComponent) =>
        class ThemeVariableContainer extends Component {
            componentDidMount() {
                this.applyCSSVariables();
            }

            applyCSSVariables() {
                const body = get(window, "document.body");
                if (body) {
                    themeVariables.forEach((v) => {
                        body.style.setProperty(v.cssName, v.value(this.props));
                    });
                }
            }

            render() {
                this.applyCSSVariables();
                return <WrappedComponent {...this.props} />;
            }
        };
}
