import React from "react";
import PropTypes from "prop-types";
import s from "./MentorCardSection.pcss";

const MentorCardSection = (props) => (
    <div>
        <h3 className={s.header}>{props.title}</h3>
        {props.children}
    </div>
);

MentorCardSection.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
};

MentorCardSection.defaultProps = {
    iconStyle: {},
};

export default MentorCardSection;
