import * as React from "react";
import {SVGProps} from "react";

export const IconTickCircle = (props: SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", width = "1em", height = "1em", ...rest} = props;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 48 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M20.498 32.828a1.735 1.735 0 0 1-1.233-.5l-4.722-4.64a1.745 1.745 0 0 1 2.445-2.489l3.49 3.43 11.04-11.04a1.752 1.752 0 0 1 2.479 2.478l-12.265 12.26a1.742 1.742 0 0 1-1.233.5Z"
                fill={color}
            />
            <path
                d="M24 3.49A19.775 19.775 0 1 1 4.225 23.265 19.798 19.798 0 0 1 24 3.49ZM24 0a23.264 23.264 0 1 0 0 46.529A23.264 23.264 0 0 0 24 0Z"
                fill={color}
            />
        </svg>
    );
};
