import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";

import MentorCardSectionItem from "shared/Mentor/components/MentorCardSectionItem/MentorCardSectionItem";
import {BUDDYCARD_UI_V2} from "shared/constants";
import {CardBodySection} from "../../../BuddiesNew/components/CardBodySection";

type MentorFieldTypes = {
    translationKey: string;
    defaultLabel: string;
    fieldValue?: string | JSX.Element;
};

export const MentorField = (props: MentorFieldTypes) => {
    const {t} = useTranslation("mentorCard");
    const isBuddycardUiV2Enabled = useBooleanFeatureFlag(
        BUDDYCARD_UI_V2.key,
        BUDDYCARD_UI_V2.value,
    );

    if (!props.fieldValue) return null;

    if (isBuddycardUiV2Enabled) {
        return (
            <CardBodySection
                heading={props.defaultLabel ? t(props.translationKey, props.defaultLabel) : null}
                content={props.fieldValue}
            />
        );
    }

    return props.fieldValue ? (
        <MentorCardSectionItem
            label={t(props.translationKey, props.defaultLabel)}
            data-test-id={`${props.defaultLabel} Section`}
        >
            {props.fieldValue}
        </MentorCardSectionItem>
    ) : null;
};
