import * as React from "react";
import {Box, Button} from "@unibuddy/patron";
import styled from "styled-components";
import {
    components as ReactSelectComponents,
    GroupBase,
    MultiValueRemoveProps,
    OptionProps,
} from "react-select";

const DefaultSelectOption = ReactSelectComponents.Option;

const StyledRemoveButton = styled(Button)`
    background: transparent;
    border: none;
    color: #fff;
    align-items: center;
    border-radius: 2px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
    height: auto;

    &:hover,
    &:focus {
        background-color: #ffc1c1 !important;
        > svg {
            fill: #a50000;
        }
    }
`;

const StyledSVGXContainer = styled.svg`
    fill: #fff;
    margin: 0 !important;
`;

export const ReactSelectOption: React.FC<OptionProps<unknown, boolean, GroupBase<unknown>>> = (
    props,
) => (
    <DefaultSelectOption
        {...props}
        /* Adding classname for testing */
        aria-label={props.label}
        className={`react-select-option-${props.label}`}
    >
        {props.isSelected ? "\u2713 " : ""}
        {props.children}
    </DefaultSelectOption>
);

export const ReactSelectIndicator: React.FC = () => (
    <Box style={{paddingRight: "8px"}}>
        <svg
            fill="currentColor"
            preserveAspectRatio="xMidYMid meet"
            height="1em"
            width="1em"
            viewBox="0 0 40 40"
            aria-hidden="true"
            style={{verticalAlign: "middle"}}
        >
            <g>
                <path d="m17.5 23.3l13.6-13.1c0.3-0.3 0.9-0.3 1.2 0l2.5 2.4c0.3 0.3 0.3 0.9 0 1.2l-16.7 16c-0.1 0.1-0.4 0.2-0.6 0.2s-0.5-0.1-0.6-0.2l-16.7-16c-0.3-0.3-0.3-0.9 0-1.2l2.5-2.4c0.3-0.3 0.9-0.3 1.2 0z" />
            </g>
        </svg>
    </Box>
);

export const ReactSelectMultiValueRemove: React.FC<
    React.PropsWithChildren<MultiValueRemoveProps<unknown, boolean, GroupBase<unknown>>>
> = (p) => (
    <StyledRemoveButton
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            /* Prevent the click from bubbling up to the parent Value Container */
            e.stopPropagation();
            /* Invokes delete */
            p.innerProps.onClick(e);
        }}
    >
        <StyledSVGXContainer
            height="14"
            width="14"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
        >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </StyledSVGXContainer>
    </StyledRemoveButton>
);

export const ReactSelectIndicatorSeparator = null;
export const ReactSelectDownChevron = null;
