import * as Sentry from "@sentry/browser";
import {Primitive} from "@sentry/types/types/misc";
import {getenv} from "../../../../util/getEnv";

const regex = /(headless|jsdom)/gi;

/**
 * isHeadless checks for the presence of the 'headless' or 'jsdom' in the navigator.userAgent
 * so we can prevent sending errors in the test environment.
 * @returns {Boolean}
 */
const isHeadless = () =>
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.search &&
    navigator.userAgent.search(regex) !== -1;

const validateLevel = (level: string): Sentry.SeverityLevel => {
    switch (level) {
        case "info":
        case "debug":
        case "warning":
        case "fatal":
        case "error":
            return level;
        default:
            console.warn(
                "errorReportingAdaptor: Argument data.level must only be one of 'fatal', 'error', 'warning', 'info' or 'debug'. Setting level to 'error' (default).",
            );
            return "error";
    }
};

const getEnvName = () => {
    try {
        const envName = getenv("UB_ENVIRONMENT", "DEV") as string;

        return envName;
    } catch (e) {
        return "DEV";
    }
};

// An array of strings or regexps that'll be used to ignore specific errors based on their type/message
// https://github.com/getsentry/sentry-javascript/blob/ab7ba810a97a2acae3dbd2c82b07e3972147bb97/packages/browser/examples/app.js#L38
const ignoreErrors = ["password", "AbortError: The operation was aborted."];
const TRACKABLE_ENVS = new Set(["master", "dev", "staging", "main", "production"]);

export const initErrorReportingService = (customTags?: {[key: string]: Primitive}) => {
    const env = getEnvName();
    if (!TRACKABLE_ENVS.has(env.toLowerCase())) {
        return;
    }

    const SENTRY_DSN = "https://5767558458fd41f2bd61f59eb83a0e52@sentry.io/1881111";

    /**
     * The config object passed to Sentry.init for react native MUST have a dsn property.
     * For Sentry browser, it can be omitted entirely.
     * In either case, when provided the dsn MUST be a valid format (even if its not a working dsn).
     * To prevent sending errors to Sentry when running tests we don't want to use a real dsn,
     * so we set the default to be a mock one.
     */
    const VERSION = getenv("VERSION", "0.0.0") as string;
    const sentryConfig = {
        dsn: "https://mockdsn@domain/123",
        environment: getEnvName(),
        release: `WIDGETS_AND_PWA_${VERSION}`,
        ignoreErrors,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        replaysSessionSampleRate: 0.01,
        tracesSampleRate: 0.01,
        replaysOnErrorSampleRate: 0.01,
    };

    if (!isHeadless()) {
        sentryConfig.dsn = SENTRY_DSN;
    }

    Sentry.init(sentryConfig);
    if (customTags) {
        Sentry.setTags(customTags);
    }
};

export const errorReportingAdaptor = {
    captureException: (error: unknown, {uuid = "", tags = {}, level = "error"}) => {
        const _level = validateLevel(level);
        try {
            Sentry.withScope((scope) => {
                scope.setTags(tags);
                scope.setLevel(_level);
                scope.setUser({id: uuid});
                Sentry.captureException(error);
            });
        } catch (e) {
            console.error(e);
        }
    },
    captureMessage: (message: string, level: string) => {
        const _level = validateLevel(level);
        try {
            Sentry.captureMessage(message, _level);
        } catch (e) {
            console.error(e);
        }
    },
};

export const configureRequestContext = (requestId: string) => {
    Sentry.withScope((scope) => {
        scope.setTag("request_id", requestId);
    });
};
