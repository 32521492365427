import React from "react";
import PropTypes from "prop-types";
import Label from "shared/General/components/Label/Label";
import s from "./MentorFilterLabels.pcss";

const MentorFilterLabels = (props) => (
    <div className={s.container}>
        {props.filters.map((filter) => (
            <Label
                text={filter.label}
                onRemove={() => props.onRemove(filter.field, filter.label, filter.value)}
                key={filter.value}
            />
        ))}
    </div>
);

MentorFilterLabels.propTypes = {
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            // Value can be either a single string or an array of strings (eg: degrees having same name)
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.String)])
                .isRequired,
        }).isRequired,
    ).isRequired,
};

MentorFilterLabels.defaultProps = {
    filters: [],
};

export default MentorFilterLabels;
