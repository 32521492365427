import React, {ReactElement} from "react";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";

import {TRAFFIC_DRIVERS_PRODUCT_OPENED_EVENT_ENABLED} from "../../../shared/constants";

interface ProductOpenedEventWrapperProps {
    children: ReactElement;
}

export const ProductOpenedEventWrapper = ({children}: ProductOpenedEventWrapperProps) => {
    const isProductOpenedEventEnabled = useBooleanFeatureFlag(
        TRAFFIC_DRIVERS_PRODUCT_OPENED_EVENT_ENABLED.key,
        TRAFFIC_DRIVERS_PRODUCT_OPENED_EVENT_ENABLED.value,
    );

    return React.cloneElement(children, {
        isProductOpenedEventEnabled,
    });
};
