/* eslint-disable */
const makeGetEnv = <T>() => {
    const windowV = global ? (<any>global).window : window;
    const cache: Map<string, T> = new Map();

    return (key: string, fallback?: T): T => {
        const fastVal = cache.get(key);

        if (fastVal) {
            return fastVal;
        }

        const value = (<any>windowV).env[key] ?? fallback;
        if (process.env.NODE_ENV === "development") {
            if (!value) {
                throw new Error(
                    `You must add '${key}' environment variable to the ./src/config.js file and the configuration manager`,
                );
            }
        }
        cache.set(key, value);
        return value;
    };
};

export const getenv = makeGetEnv();
