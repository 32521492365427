import React from "react";
import {Box} from "@unibuddy/patron";
import styled from "styled-components";

const StyledBox = styled(Box)`
    margin: 0 auto;
    max-width: 768px;
    padding: 0 15px;
`;

export const WideContainer = ({children, ...rest}) => <StyledBox {...rest}>{children}</StyledBox>;
