import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import {differenceInDays, parseISO} from "date-fns";
import {BooleanFeatureFlagProps} from "../../../../shared/constants";
import {useUniversity} from "../../UniversityProvider/UniversityProvider";
import {useGetProspectStageQuery} from "../../Chat/components/PopupQuestions/prospectStages";

const SHOW_PROMPT_FREQUENCY_DAYS = 14;

export default function useShowPrompt(feature: BooleanFeatureFlagProps) {
    const isFeatureEnabled = useBooleanFeatureFlag(feature.key, feature.value);
    const {id} = useUniversity();

    const {data, loading, error} = useGetProspectStageQuery(id);
    if (loading) {
        return {
            loading,
            error,
            showPrompt: false,
        };
    }
    if (error || !data) {
        return {
            loading,
            error,
            showPrompt: isFeatureEnabled,
        };
    }

    return {
        data,
        loading,
        error,
        showPrompt:
            isFeatureEnabled &&
            (!data.currentUserDecisionStage.updated ||
                differenceInDays(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    new Date(),
                    parseISO(data.currentUserDecisionStage.updated),
                ) > SHOW_PROMPT_FREQUENCY_DAYS),
    };
}
