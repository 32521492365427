import React from "react";
import get from "lodash/get";
import {useSocketData} from "shared/Experimental/Sockets/SocketProvider/SocketProvider";
import meQuery from "shared/Auth/queries/meQuery";
import {setMe} from "shared/Auth/actions/authActions";
import {delinkApplicantEvent} from "./sockets";

/**
 * This HOC is used to update authState in case of socket delinked message from Server via socket
 * @param WrappedComponent
 * @returns Component
 */
const withSocket = (WrappedComponent) => {
    const WithSocketWrapper = (props) => {
        const userId = get(props, "me.anyUser.id", "");
        const universitySlug = get(props, "match.params.universitySlug", "");
        const delinkedMessageData = useSocketData(delinkApplicantEvent, userId);

        // Observe socket data for newly received messages
        React.useEffect(
            () => {
                if (
                    delinkedMessageData &&
                    delinkedMessageData.delinked &&
                    delinkedMessageData.universitySlug === universitySlug
                ) {
                    const getResult = async () => {
                        const result = await props.client.query({
                            query: meQuery,
                            fetchPolicy: "network-only",
                        });
                        props.dispatch(setMe(result.data.me));
                    };

                    getResult();
                }
            },
            [delinkedMessageData], // eslint-disable-line react-hooks/exhaustive-deps
        );

        return <WrappedComponent {...props} />;
    };

    return WithSocketWrapper;
};

export default withSocket;
