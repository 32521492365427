import {DocumentNode} from "graphql";
import gql from "graphql-tag";

export const universityQuestionsWithAnswersQuery: DocumentNode = gql`
    query universityQuestionsWithAnswersQuery(
        $universityQuestionInput: UniversityQuestionInput!
        $universityQuestionAnswerInput: UniversityQuestionAnswerInput!
    ) {
        universityQuestions(universityQuestionInput: $universityQuestionInput) {
            id
            answers(universityQuestionAnswerInput: $universityQuestionAnswerInput) {
                text
            }
        }
    }
`;
