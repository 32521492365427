import React, {useEffect, useState} from "react";
import {useTranslation} from "@unibuddy/intl";
import {Box, Stack} from "@unibuddy/patron";
import {useHistory, useLocation} from "react-router-dom";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";

import ChatNowButton from "shared/Blog/components/ChatNowButton/ChatNowButton";
import ThemedLink from "shared/Navigation/components/ThemedLink/ThemedLink";
import MentorCardBanner from "shared/Mentor/components/MentorCardBanner/MentorCardBanner";
import MentorCardDegree from "shared/Mentor/components/MentorCardDegree/MentorCardDegree";
import MentorCardName from "shared/Mentor/components/MentorCardName/MentorCardName";
import MentorCardSeparator from "shared/Mentor/components/MentorCardSeparator/MentorCardSeparator";
import RecordAnalytics from "shared/Analytics/RecordAnalytics";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {itemNames, sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import {rootURL} from "shared/Utils/WhereAmI";
import {getShortName, getTimeSince, UNITS} from "shared/Utils/DateUtils";
import {BUDDYCARD_UI_V2} from "shared/constants";

import MentorComponent from "./MentorComponent";
import {MentorField, UniversityField} from "../../../../../types/generated";
import s from "./MentorPreviewCard.pcss";

import {getenv} from "../../../../../util/getEnv";
import {Card} from "../../../BuddiesNew/components/Card";
import {CardHeader} from "../../../BuddiesNew/components/CardHeader";
import {CardHeaderInfoWrapper} from "../../../BuddiesNew/components/CardHeaderInfoWrapper";
import {CardAvatar} from "../../../BuddiesNew/components/CardAvatar";
import {CardActivityStatus} from "../../../BuddiesNew/components/CardActivityStatus";
import {CardHeaderInfo} from "../../../BuddiesNew/components/CardHeaderInfo";
import {CardButtonLink} from "../../../BuddiesNew/components/CardButtonLink";
import {CardBody} from "../../../BuddiesNew/components/CardBody";
import {CardBodyActions} from "../../../BuddiesNew/components/CardBodyActions";
import {CardBodySectionWrapper} from "../../../BuddiesNew/components/CardBodySectionWrapper";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {CardAvatarClickHandler} from "../../../BuddiesNew/components/CardAvatarClickHandler";

type PreviewCardTypes = {
    mentor: MentorField;
    university: UniversityField;
    rootUrl: string;
    buddyPosition: number;
    universityName: string;
    isMatched: boolean;
    onAvatarClicked: (mentorId: string) => null;
    onChatNow: (mentorId: string, position: string) => null;
    onSeeMoreClicked: (mentorId: string) => null;
};

const TopRightComp = ({isMatched = false, degreeLevel = ""}): JSX.Element => {
    if (isMatched && degreeLevel === "Alumni") {
        return (
            <div data-test-id="both">
                <i className="fa fa-star fa-lg" aria-hidden="true" />
                &nbsp; Alumni
            </div>
        );
    } else if (isMatched) {
        return <i data-test-id="star" className="fa fa-star fa-lg" aria-hidden="true" />;
    } else if (degreeLevel === "Alumni") {
        return <>Alumni</>;
    }
    return null;
};

const MentorPreviewCard = ({
    mentor,
    university,
    onAvatarClicked,
    rootUrl,
    buddyPosition,
    isMatched,
    universityName,
    onChatNow,
    onSeeMoreClicked,
}: PreviewCardTypes) => {
    const [orderedMentorField, setOrderedMentorField] = useState<string[]>([]);
    const {t} = useTranslation("mentorCard");
    const {features} = useUniversity();
    const isDegreeTypesEnabled = features.get("enableDegreeTypes");
    const isShowCountryFlagEnabled = features.get("showCountryFlag");

    const isBuddycardUiV2Enabled = useBooleanFeatureFlag(
        BUDDYCARD_UI_V2.key,
        BUDDYCARD_UI_V2.value,
    );
    useEffect(() => {
        const mentorFieldOrder = university?.mentorFieldOrder ?? [];
        mentorFieldOrder.sort((field1, field2) => field1.order - field2.order);
        setOrderedMentorField(mentorFieldOrder.map((mentorField) => mentorField.field));
    }, [university]);

    const mentorName = mentor.firstName ?? "";
    const mentorId = mentor.id;

    const params = new URLSearchParams(window.location.search);
    params.set("buddyPosition", buddyPosition.toString());

    const {trackEvent} = useAnalytics();
    const history = useHistory();
    const location = useLocation();
    const ASSETS_URL: string = getenv("ASSETS_URL", "") as string;
    const chatLink = `${rootURL(
        location.pathname,
    )}/inbox/chatwith/${mentorId}?${params.toString()}`;
    const profileLink = `${rootUrl}/buddies/students/${mentorId}?${params.toString()}`;

    function handleButtonLink(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        // Prevent navigation so we can intercept and fire analytics
        e.preventDefault();

        RecordAnalytics.event(
            {primaryApp: "", universitySlug: university.slug},
            "Buddy Card Chat Now Clicked",
            {mentorId},
        ); // Legacy
        trackEvent(sharedEvents.itemClicked.name, {
            prefix: itemNames.buddyCardChatNow,
            id: mentorId,
        });

        history.push(chatLink);
    }

    function isValidDate(dateString: string) {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    }

    function lastSeenDate(date: string) {
        if (!isValidDate(date)) return null;

        const {value, units} = getTimeSince(date, new Date());
        if (units === UNITS.DAYS) {
            return null;
        }
        if (value > 1) {
            return t(`${units.toLowerCase()}Active`, `Active ${value}${getShortName(units)} ago`, {
                time: value,
            });
        }
        return t(
            `${units.toLowerCase()}ActiveSingular`,
            `Active ${value}${getShortName(units)} ago`,
            {time: value},
        );
    }

    const degreeInfo = mentor.degree?.name
        ? {
              text: mentor.degree.name,
          }
        : undefined;

    const universityInfo = universityName
        ? {
              text: universityName,
              color: isDegreeTypesEnabled && mentor.degree?.name ? "grey600" : undefined,
          }
        : undefined;

    const infoArray = [];
    if (degreeInfo) {
        infoArray.push(degreeInfo);
    }
    if (universityInfo) {
        infoArray.push(universityInfo);
    }

    if (isBuddycardUiV2Enabled) {
        return (
            <Card
                activityStatus={
                    lastSeenDate(mentor.lastSeen) && (
                        <CardActivityStatus activityStatus={lastSeenDate(mentor.lastSeen)} />
                    )
                }
            >
                <CardHeader>
                    <CardHeaderInfoWrapper>
                        <CardAvatarClickHandler
                            link={profileLink}
                            handleClick={() => onAvatarClicked(mentorId)}
                            ariaLabel={`Mentor ${mentorName}`}
                        >
                            <CardAvatar
                                url={`${ASSETS_URL}/${mentor.profilePhoto}`}
                                alt={`Mentor ${mentorName}`}
                                primaryFlag={
                                    isShowCountryFlagEnabled
                                        ? mentor?.mentorFields?.primaryFlag
                                        : null
                                }
                                secondaryFlag={
                                    isShowCountryFlagEnabled
                                        ? mentor?.mentorFields?.secondaryFlag
                                        : null
                                }
                            />
                        </CardAvatarClickHandler>
                        <CardHeaderInfo buddyName={mentorName} info={infoArray} />
                    </CardHeaderInfoWrapper>
                    <CardButtonLink
                        link={chatLink}
                        color="university"
                        handleClick={handleButtonLink}
                    >
                        {t("chatWith", `Chat with ${mentorName}`, {name: mentorName})}
                    </CardButtonLink>
                </CardHeader>
                <CardBody>
                    <CardBodySectionWrapper>
                        {orderedMentorField.map((field) => {
                            return (
                                <React.Fragment key={field}>
                                    {MentorComponent(field, {
                                        university,
                                        mentor,
                                        t,
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </CardBodySectionWrapper>
                    <CardBodyActions
                        handleClick={() => onSeeMoreClicked(mentorId)}
                        link={`${rootUrl}/buddies/students/${mentorId}?buddyPosition=${buddyPosition}`}
                        external={false}
                    >
                        {t("readMore", `Read more about ${mentorName}...`, {
                            name: mentorName,
                        })}
                    </CardBodyActions>
                </CardBody>
            </Card>
        );
    }

    return (
        <Box
            maxW="400px"
            minH="455px"
            borderColor="#e4e8eb"
            borderWidth={1}
            borderRadius={"xsmall"}
            backgroundColor="white"
            display={"flex"}
            flexDirection={"column"}
        >
            <MentorCardBanner
                name={mentorName}
                className={s.banner}
                lastSeen={mentor.lastSeen ?? ""}
                profilePhoto={mentor.profilePhoto}
                imageLink={`${rootUrl}/buddies/students/${mentorId}?${params.toString()}`}
                onImageClicked={() => onAvatarClicked(mentorId)}
                topRight={<TopRightComp isMatched={isMatched} degreeLevel={mentor.degreeLevel} />}
            />

            <MentorCardName name={mentorName} />

            {!isDegreeTypesEnabled ? <MentorCardDegree degree={mentor.degree?.name ?? ""} /> : null}

            {universityName ? (
                <p className={s.university} data-test-id="university">
                    {universityName}
                </p>
            ) : null}

            <div className={s.chatNow}>
                <ChatNowButton
                    onChatNow={onChatNow}
                    name={mentorName}
                    className={s.chatNowButton}
                    id={mentorId}
                    large={false}
                />
            </div>

            <MentorCardSeparator />
            <Box maxH={"300px"} overflow={"hidden"} paddingBottom={"medium"}>
                {orderedMentorField.map((field) => {
                    return (
                        <Stack key={field} space={"small"}>
                            {MentorComponent(field, {
                                university,
                                mentor,
                                t,
                            })}
                        </Stack>
                    );
                })}
            </Box>

            <Box marginTop={"auto"} paddingTop={"small"}>
                <div className={s.seeMore}>
                    <ThemedLink
                        id={`read-more-${mentorId}`}
                        className={s.seeMoreLink}
                        onClick={() => onSeeMoreClicked(mentorId)}
                        to={`${rootUrl}/buddies/students/${mentorId}?buddyPosition=${buddyPosition}`}
                        external={false}
                    >
                        {t("readMore", `Read more about ${mentorName} ...`, {
                            name: mentorName,
                        })}
                    </ThemedLink>
                </div>
            </Box>
        </Box>
    );
};

export default MentorPreviewCard;
