function getAppName(primaryApp) {
    switch (primaryApp) {
        case "university-jigglypuff":
        case "charmander":
            return "Widget";
        case "external-diglett":
            return "External Mini Widget";
        case "university-diglett":
            return "University Mini Widget";
        case "university-pidgey":
            return "Chatbox";
        case "horsea":
            return "Live Events";
        default:
            return "Ambassador Dashboard";
    }
}

export default getAppName;
