import gql from "graphql-tag";

const blogPostPreviewFragment = gql`
    fragment BlogPostPreviewFragment on BlogPostField {
        id
        title
        status
        body
        coverImages(raw: true)
        youtubeVideo
        numberOfViews
        readingTime
        topic {
            id
            name
        }
        flaggedByUser
        author {
            id
            firstName
            lastName
            profilePhoto
            university {
                id
                name
            }
            degree {
                id
                name
            }
            active
            archived
            accountRole
        }
    }
`;

export default blogPostPreviewFragment;
