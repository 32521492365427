import React from "react";
import {Box} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";

import BuddyCardBanner from "shared/Mentor/components/MentorCardBanner/MentorCardBanner";
import BuddyCardName from "shared/Mentor/components/MentorCardName/MentorCardName";
import BuddyCardSubtitle from "shared/Mentor/components/MentorCardDegree/MentorCardDegree";
import ChatNowButton from "shared/Blog/components/ChatNowButton/ChatNowButton";
import BuddyCardSeparator from "shared/Mentor/components/MentorCardSeparator/MentorCardSeparator";
import BuddyCardSectionItem from "shared/Mentor/components/MentorCardSectionItem/MentorCardSectionItem";
import ThemedLink from "shared/Navigation/components/ThemedLink/ThemedLink";
import {BUDDYCARD_UI_V2} from "shared/constants";

import s from "./BuddyListCard.pcss";
import {StaffBuddycard} from "../../../BuddiesNew/containers/StaffBuddycard";

function BuddyListCard({
    buddy,
    rootUrl,
    isInternal,
    externalImageLink,
    onChatNow,
    onSeeMore,
    onAvatarClicked,
}) {
    const isBuddycardUiV2Enabled = useBooleanFeatureFlag(
        BUDDYCARD_UI_V2.key,
        BUDDYCARD_UI_V2.value,
    );
    const {t} = useTranslation("mentorCard");

    if (isBuddycardUiV2Enabled) {
        return (
            <StaffBuddycard
                buddy={buddy}
                onChatNow={onChatNow}
                onSeeMore={onSeeMore}
                isInternal={isInternal}
                avatarClickLink={rootUrl ?? null}
                avatarLinkIsExternal={externalImageLink}
                onAvatarClicked={onAvatarClicked}
            />
        );
    }

    return (
        <Box
            maxW="400px"
            minH="455px"
            borderColor="#e4e8eb"
            borderWidth="1px"
            borderRadius={"xsmall"}
            backgroundColor="white"
        >
            <BuddyCardBanner
                name={buddy.firstName}
                className={s.banner}
                profilePhoto={buddy.profilePhoto}
                imageLink={rootUrl}
                onImageClicked={onAvatarClicked}
                externalImageLink={externalImageLink}
            />

            <BuddyCardName name={buddy.staffBuddyName ? buddy.staffBuddyName : buddy.firstName} />

            <BuddyCardSubtitle degree={buddy?.staffFields?.role ?? ""} />
            <BuddyCardSubtitle degree={buddy?.staffFields?.department ?? ""} />

            <div className={s.chatNow}>
                <ChatNowButton
                    onChatNow={onChatNow}
                    name={buddy.firstName}
                    className={s.chatNowButton}
                    data-test-id="chat-now-button"
                />
            </div>

            <BuddyCardSeparator />

            <BuddyCardSectionItem label={t("roleDescription", "Role Description")}>
                {`${buddy?.staffFields?.bio.slice(0, 70) ?? ""}...`}
            </BuddyCardSectionItem>

            {isInternal && (
                <div className={s.seeMore}>
                    <ThemedLink
                        id={`read-more-${buddy.id}`}
                        className={s.seeMoreLink}
                        onClick={onSeeMore}
                        to={rootUrl}
                        external={externalImageLink}
                        data-test-id="see-more-button"
                    >
                        {t("readMore", `Read more about ${buddy.firstName} ...`, {
                            name: buddy.firstName,
                        })}
                    </ThemedLink>
                </div>
            )}
        </Box>
    );
}

export default React.memo(BuddyListCard);
