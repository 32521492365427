import React from "react";
import {Box, View} from "@unibuddy/patron";
import styled from "styled-components";

export const Avatar = styled(Box)`
    background-color: ${({theme}) => theme.colors.grey150};
    overflow: hidden;
    width: 86px;
    height: 86px;
    border: 1px solid white;
    border-radius: 86px;
    object-fit: cover;
    &:not(:first-child) {
        margin-left: -40px;
    }
`;

export function AvatarStack({profilePhotos}: {profilePhotos: string[]}) {
    return (
        <View flexDirection="row">
            {profilePhotos.map((photo, index) => (
                <Avatar as="img" src={photo} alt={`Profile photo ${index}`} key={photo} />
            ))}
        </View>
    );
}
