/* eslint-disable jsx-a11y/aria-props*/

/* aria-current is part of WCAG standard, but jsx-a11y throws an error saying it's invalid.Using aria-current in navigation fixes the serious a11y issue reported by deque in 2023.*/
import {isFeatureEnabled} from "shared/Utils/FeatureUtils";
import {Box, Hidden, useLocalStorage} from "@unibuddy/patron";
import RecordAnalytics from "shared/Analytics/RecordAnalytics";
import Auth from "shared/Auth/auth";
import withAuth from "shared/Auth/withAuth/withAuth";
import InboxQuery from "shared/Inbox/queries/InboxQuery";
import {withUniversityTheme} from "shared/Styling/hocs/withTheme";
import PropTypes from "prop-types";
import React, {useState} from "react";
import compose from "lodash/flowRight";
import {graphql} from "react-apollo";
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import {useTranslation} from "@unibuddy/intl";
import focusHeader from "shared/Experimental/A11y/components/SkipNav/FocusHeader";
import {useBooleanFeatureFlag} from "ub-feature-flagging-react";
import {NEXT_BEST_ACTIONS_FLAG, SHOW_FOR_YOU_TAB} from "shared/constants";
import checkAllowDelinkedForApplicant from "shared/Chat/utils/checkAllowDelinkedForApplicant";
import ExitTab from "../../components/ExitTab/ExitTab";
import s from "./HeaderTabs.pcss";
import {IconHeart} from "../../../Icons/IconHeart";
import {IconInbox} from "../../../Icons/IconInbox";
import {IconContent} from "../../../Icons/IconContent";
import {IconSettings} from "../../../Icons/IconSettings";
import {IconSignUp} from "../../../Icons/IconSignUp";
import {IconTab} from "../../components/IconTab/IconTab";
import {SvgTab} from "../../components/SvgTab/SvgTab";
import {IconWithIndicator} from "../../../Icons/IconWithIndicator/IconWithIndicator";
import BuddiesGroupHeaderTab from "../../components/HeaderTabs/BuddiesGroupHeaderTab";
import NextBestActions from "../../../NextBestActions/containers/NextBestActions";
import {NEXT_BEST_ACTIONS_FLAG_DEFAULT} from "../../../NextBestActions/NextBestActions";
import {usePage} from "../../../PageProvider/PageContext";
import {WIDGET_PAGE} from "../../../PageProvider/PageTitles";
import {useRecommendations} from "../../../Recommendations/RecommendationsProvider/RecommendationsProvider";
import {IntlNamespaces} from "../../../../../translations/types";
import {
    INTL_CONTENT,
    INTL_INBOX,
    INTL_SETTINGS,
    INTL_SIGNUP,
} from "../../../../../translations/common";
import {INTL_FOR_YOU} from "../../../../../translations/recommendations";

const SHOW_FOR_YOU_INDICATOR_KEY = "show-for-you-indicator";

export const getUrls = (props) => {
    const {universitySlug, colourCode, degreeId} = props?.match?.params ?? {};
    // root url to be used for routing in different widgets
    let rootUrl = `/embed/${universitySlug}/colour/${colourCode}`;
    if (props.primaryApp === "university-geodude")
        rootUrl = `/embed/${universitySlug}/course/${degreeId}`;
    else if (props.primaryApp === "charmander") rootUrl = `/pwa/${universitySlug}`;

    // we want to retain the url for buddies tab because
    // we want the filters to be hidden in course buddy platform
    let buddiesTabUrl = rootUrl;
    if (degreeId && props.primaryApp === "university-jigglypuff")
        buddiesTabUrl = `/embed/${universitySlug}/course/${degreeId}`;

    return {rootUrl, buddiesTabUrl};
};

const nextBestActionsHiddenPaths = new Set([
    WIDGET_PAGE.WIDGET_DECISION_STAGE,
    WIDGET_PAGE.WIDGET_RECOMMENDATIONS,
    WIDGET_PAGE.WIDGET_NOT_INTERESTED,
    WIDGET_PAGE.WIDGET_SIGNUP,
]);

export const HeaderTabsComponent = (props) => {
    const {page} = usePage();
    const showNextBestActions = useBooleanFeatureFlag(
        NEXT_BEST_ACTIONS_FLAG,
        NEXT_BEST_ACTIONS_FLAG_DEFAULT,
    );
    const isLoggedIn = props?.authState?.me ?? false;
    const forYouTabEnabled = useBooleanFeatureFlag(SHOW_FOR_YOU_TAB.key, SHOW_FOR_YOU_TAB.value);
    const isDesktop = window.matchMedia("(min-width: 420px)");
    const hasStaffProfilesEnabled = isFeatureEnabled("staffProfiles", props.university);
    const isBlogsEnabled = isFeatureEnabled("userGeneratedContent", props.university);

    const [showIndicatorMap, setShowIndicatorMap] = useLocalStorage(SHOW_FOR_YOU_INDICATOR_KEY, {
        [SHOW_FOR_YOU_INDICATOR_KEY]: true,
    });

    const {hasRecommendations} = useRecommendations();
    const [currentPage, setCurrentPage] = useState(null);

    const showForYouTab = forYouTabEnabled && isLoggedIn && isDesktop.matches && hasRecommendations;
    const {rootUrl, buddiesTabUrl} = getUrls(props);
    const [buddiesUrl] = React.useState(buddiesTabUrl);
    const {t} = useTranslation([IntlNamespaces.COMMON, IntlNamespaces.RECOMMENDATIONS]);
    const onSelectTab = (name) => {
        RecordAnalytics.event(
            props,
            `Clicked ${name} Header Bar`,
            {uniSlug: props.universitySlug},
            false,
        );
        focusHeader("content");
        setCurrentPage(name);
    };

    const closeFullScreen = () => {
        RecordAnalytics.event(
            props,
            "Clicked Exit in Header Bar",
            {uniSlug: props.universitySlug},
            false,
        );
        props.closeFullScreen();
    };

    const activeTabs = {
        staff: hasStaffProfilesEnabled && (props?.publicStaffCount ?? 1) > 0,
        forYou: showForYouTab,
        inbox: true,
        blogs: true,
        settings: true,
    };
    activeTabs.students = activeTabs.staff;

    return (
        <nav className={s.container}>
            <div className={s.tabContainer}>
                <BuddiesGroupHeaderTab
                    rootUrl={buddiesUrl}
                    onSelectTab={onSelectTab}
                    activeTabs={activeTabs}
                    currentPage={currentPage}
                />

                {showForYouTab ? (
                    <IconTab
                        id="forYou"
                        to={`${rootUrl}/recommendations`}
                        name={t(
                            `${IntlNamespaces.RECOMMENDATIONS}:${INTL_FOR_YOU.key}`,
                            INTL_FOR_YOU.defaultValue,
                        )}
                        onClick={() => {
                            setShowIndicatorMap((indicatorMap) => ({
                                ...indicatorMap,
                                [SHOW_FOR_YOU_INDICATOR_KEY]: false,
                            }));
                            onSelectTab("For You");
                        }}
                        icon={() => (
                            <IconWithIndicator
                                show={showIndicatorMap?.[SHOW_FOR_YOU_INDICATOR_KEY] ?? false}
                                icon={<IconHeart width={32} height={32} />}
                            />
                        )}
                        aria-current={currentPage === "For You" ? "page" : false}
                    />
                ) : null}

                {isBlogsEnabled && (
                    <IconTab
                        id="blog"
                        to={`${rootUrl}/blog`}
                        name={t(
                            `${IntlNamespaces.COMMON}:${INTL_CONTENT.key}`,
                            INTL_CONTENT.defaultValue,
                        )}
                        onClick={() => onSelectTab("Blog")}
                        icon={() => <IconContent width={32} height={32} />}
                        aria-current={currentPage === "Blog" ? "page" : false}
                    />
                )}
                <IconTab
                    id="inbox"
                    data-test-id="inbox"
                    name={t(`${IntlNamespaces.COMMON}:${INTL_INBOX.key}`, INTL_INBOX.defaultValue)}
                    onClick={() => onSelectTab("Inbox")}
                    to={`${rootUrl}/inbox`}
                    icon={() => (
                        <IconWithIndicator
                            show={props.totalUnreadMessageCount > 0}
                            icon={<IconInbox width={32} height={32} />}
                        />
                    )}
                    aria-current={currentPage === "Inbox" ? "page" : false}
                />

                {!Auth.loggedIn(props.authState) ? (
                    <IconTab
                        id="login"
                        name={t(
                            `${IntlNamespaces.COMMON}:${INTL_SIGNUP.key}`,
                            INTL_SIGNUP.defaultValue,
                        )}
                        onClick={() => onSelectTab("Sign Up")}
                        to={`${rootUrl}/auth/register`}
                        icon={() => <IconSignUp width={32} height={32} />}
                        aria-current={currentPage === "Sign Up" ? "page" : false}
                    />
                ) : (
                    <SvgTab
                        id="settings"
                        name={t(
                            `${IntlNamespaces.COMMON}:${INTL_SETTINGS.key}`,
                            INTL_SETTINGS.defaultValue,
                        )}
                        onClick={() => onSelectTab("Settings")}
                        to={`${rootUrl}/settings`}
                        icon={() => <IconSettings width={32} height={32} />}
                        aria-current={currentPage === "settings" ? "page" : false}
                    />
                )}

                {props?.appState?.isFullScreen ?? false ? (
                    <ExitTab onClick={closeFullScreen} />
                ) : null}
            </div>
            {showNextBestActions && isLoggedIn && !nextBestActionsHiddenPaths.has(page) ? (
                <Hidden below="tablet">
                    <Box
                        data-test-id="nba-recommendations"
                        display="flex"
                        marginTop="small"
                        paddingX={["xsmall", "small"]}
                    >
                        <NextBestActions />
                    </Box>
                </Hidden>
            ) : null}
            <Route path={`${buddiesUrl}/buddies`}>{() => null}</Route>
        </nav>
    );
};

HeaderTabsComponent.propTypes = {
    universitySlug: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    appState: state.appState,
});

const inboxQueryOptions = {
    name: "inbox",
    skip: (props) => !Auth.loggedIn(props.authState, "applicant"),
    options(props) {
        const universitySlug = props?.match?.params?.universitySlug ?? "";
        const meAuthState = props?.authState?.me ?? {};
        const allowDelinked = checkAllowDelinkedForApplicant(universitySlug, meAuthState);

        return {
            variables: {
                universitySlug,
                allowDelinked,
            },
            fetchPolicy: "cache-and-network",
            pollInterval: 10000,
        };
    },
    props({inbox: {loading, applicantInbox}}) {
        const chatGroups = applicantInbox?.chatGroups ?? [];
        return {
            applicantInbox,
            chatGroups,
            loading,
            totalUnreadMessageCount: chatGroups.reduce(
                (total, chatGroup) => total + chatGroup?.unreadMessageCount ?? 0,
                0,
            ),
        };
    },
    errorPolicy: "ignore",
};
export default compose(
    connect(mapStateToProps),
    graphql(InboxQuery, inboxQueryOptions),
    withAuth,
    withUniversityTheme(),
)(HeaderTabsComponent);
