import React, {useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "shared/General/components/AsyncComponent/AsyncComponent";
import {getParameterByName} from "shared/Utils/UrlUtils";
import {productSpec, sourceTracking} from "shared/AnalyticsNew/constants/productSpecification";
import withSourceTracking from "shared/SourceTracking/withSourceTracking/withSourceTracking";
import AnalyticsValue from "shared/AnalyticsNew/AnalyticsValue/AnalyticsValue";
import compose from "lodash/flowRight";
import ProspectWidget from "./embedded/ProspectWidget/ProspectWidget";
import {ProductOpenedEventWrapper} from "../shared/components/ProductOpenedEventWrapper";

// Async Imports
const AsyncMentorCardWidget = asyncComponent(() =>
    import("./embedded/MentorCardWidget/MentorCardWidget"),
);
const AsyncBlogCardWidget = asyncComponent(() =>
    import("./embedded/BlogCardWidget/BlogCardWidget"),
);
const AsyncMentorCarouselWidget = asyncComponent(() =>
    import("./embedded/MentorCarouselWidget/MentorCarouselWidget"),
);
const AsyncBrandedProspectPage = asyncComponent(() =>
    import("./pwa/BrandedProspectPage/BrandedProspectPage"),
);

const SourceTrackingRoute = compose(withSourceTracking)((props) => {
    // set's default source tracking params
    useEffect(() => {
        props.sourceTracking.saveSourceTrackingParams(
            props.getSourceTrackingParams(),
            props.sourceTrackingQueryParams,
        );
    }, []); // eslint-disable-line

    // set's source tracking params only if any of the values are passed via url
    // It will retain the values set initially even when the user navigates away to a new url
    useEffect(
        () => {
            if (
                props.sourceTrackingQueryParams &&
                (props.sourceTrackingQueryParams.includes(
                    sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
                ) ||
                    props.sourceTrackingQueryParams.includes(
                        sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
                    ) ||
                    props.sourceTrackingQueryParams.includes(
                        sourceTracking.sourceTrackingParams.properties.UB_CONTENT,
                    ) ||
                    props.sourceTrackingQueryParams.includes(
                        sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN,
                    ) ||
                    props.sourceTrackingQueryParams.includes(
                        sourceTracking.sourceTrackingParams.properties.INVITE_ID,
                    ))
            ) {
                props.sourceTracking.saveSourceTrackingParams(
                    props.getSourceTrackingParams(),
                    props.sourceTrackingQueryParams,
                );
            }
        },
        [props.sourceTrackingQueryParams], // eslint-disable-line
    );
    return props.children;
});

class WidgetsRouter extends React.Component {
    embeddedWidgetSourceTrackingParams = (props) => {
        return {
            ub_medium: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
            )
                ? getParameterByName(
                      props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
                  )
                : "product",
            ub_source: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
            )
                ? getParameterByName(
                      props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
                  )
                : productSpec.embeddedUniversityWidget.NAME,
            ub_campaign: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN,
            ),
            ub_content: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CONTENT,
            ),
            invite_id: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.INVITE_ID,
            ),
        };
    };

    mentorCarouselSourceTrackingParams = (props) => {
        return {
            ub_medium: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
            )
                ? getParameterByName(
                      props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
                  )
                : "product",
            ub_source: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
            )
                ? getParameterByName(
                      props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
                  )
                : productSpec.embeddedUniversityCarousel.NAME,
            ub_campaign: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN,
            ),
            ub_content: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CONTENT,
            ),
        };
    };

    pwaSourceTrackingParams = (props) => {
        return {
            ub_medium: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
            )
                ? getParameterByName(
                      props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
                  )
                : "product",
            ub_source: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
            )
                ? getParameterByName(
                      props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
                  )
                : productSpec.pwaUniversityWidget.NAME,
            ub_campaign: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN,
            ),
            ub_content: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CONTENT,
            ),
        };
    };

    buddyCardSourceTrackingParams = (props) => {
        return {
            ub_medium: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
            )
                ? getParameterByName(
                      props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
                  )
                : "product",
            ub_source: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
            )
                ? getParameterByName(
                      props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
                  )
                : productSpec.embeddedUniversityBuddyCards.NAME,
            ub_campaign: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN,
            ),
            ub_content: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CONTENT,
            ),
        };
    };

    blogCardSourceTrackingParams = (props) => {
        return {
            ub_medium: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
            )
                ? getParameterByName(
                      this.props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_MEDIUM,
                  )
                : "product",
            ub_source: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
            )
                ? getParameterByName(
                      props.location.search,
                      sourceTracking.sourceTrackingParams.properties.UB_SOURCE,
                  )
                : productSpec.embeddedUniversitySocialFeed.NAME,
            ub_campaign: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN,
            ),
            ub_content: getParameterByName(
                props.location.search,
                sourceTracking.sourceTrackingParams.properties.UB_CONTENT,
            ),
        };
    };

    render() {
        return (
            <Switch>
                <Route
                    path="/embed/:universitySlug/colour/:colourCode"
                    render={(props) => {
                        return (
                            <SourceTrackingRoute
                                getSourceTrackingParams={() =>
                                    this.embeddedWidgetSourceTrackingParams(props)
                                }
                                sourceTrackingQueryParams={props.location.search}
                            >
                                <div>
                                    <AnalyticsValue
                                        name={
                                            productSpec.embeddedUniversityWidget.properties
                                                .UNIVERSITY_SLUG
                                        }
                                        value={props.match.params.universitySlug}
                                    />
                                    <AnalyticsValue
                                        name={
                                            productSpec.embeddedUniversityWidget.properties.PRODUCT
                                        }
                                        value={productSpec.embeddedUniversityWidget.NAME}
                                    />
                                    <ProspectWidget {...props} />
                                </div>
                            </SourceTrackingRoute>
                        );
                    }}
                />

                <Route
                    path="/embed/:universitySlug/course/:degreeId"
                    render={(props) => {
                        return (
                            <SourceTrackingRoute
                                getSourceTrackingParams={() =>
                                    this.embeddedWidgetSourceTrackingParams(props)
                                }
                                sourceTrackingQueryParams={props.location.search}
                            >
                                <div>
                                    <AnalyticsValue
                                        name={
                                            productSpec.embeddedUniversityWidget.properties
                                                .UNIVERSITY_SLUG
                                        }
                                        value={props.match.params.universitySlug}
                                    />
                                    <AnalyticsValue
                                        name={
                                            productSpec.embeddedUniversityWidget.properties.PRODUCT
                                        }
                                        value={productSpec.embeddedUniversityWidget.NAME}
                                    />
                                    <ProspectWidget {...props} />
                                </div>
                            </SourceTrackingRoute>
                        );
                    }}
                />

                <Route
                    path="/embed/:universitySlug/carousel"
                    render={(props) => {
                        return (
                            <SourceTrackingRoute
                                getSourceTrackingParams={() =>
                                    this.mentorCarouselSourceTrackingParams(props)
                                }
                                sourceTrackingQueryParams={props.location.search}
                            >
                                <div>
                                    <AnalyticsValue
                                        name={
                                            productSpec.embeddedUniversityCarousel.properties
                                                .UNIVERSITY_SLUG
                                        }
                                        value={props.match.params.universitySlug}
                                    />
                                    <AnalyticsValue
                                        name={
                                            productSpec.embeddedUniversityCarousel.properties
                                                .PRODUCT
                                        }
                                        value={productSpec.embeddedUniversityCarousel.NAME}
                                    />
                                    <ProductOpenedEventWrapper>
                                        <AsyncMentorCarouselWidget {...props} />
                                    </ProductOpenedEventWrapper>
                                </div>
                            </SourceTrackingRoute>
                        );
                    }}
                />

                <Route
                    path="/pwa/:universitySlug"
                    render={(props) => {
                        return (
                            <SourceTrackingRoute
                                getSourceTrackingParams={() => this.pwaSourceTrackingParams(props)}
                                sourceTrackingQueryParams={props.location.search}
                            >
                                <div>
                                    <AnalyticsValue
                                        name={
                                            productSpec.pwaUniversityWidget.properties
                                                .UNIVERSITY_SLUG
                                        }
                                        value={props.match.params.universitySlug}
                                    />
                                    <AnalyticsValue
                                        name={productSpec.pwaUniversityWidget.properties.PRODUCT}
                                        value={productSpec.pwaUniversityWidget.NAME}
                                    />
                                    <ProductOpenedEventWrapper>
                                        <AsyncBrandedProspectPage {...props} />
                                    </ProductOpenedEventWrapper>
                                </div>
                            </SourceTrackingRoute>
                        );
                    }}
                />

                <Route
                    path="/:universitySlug/colour/:colourCode"
                    render={(props) => {
                        return (
                            <SourceTrackingRoute
                                getSourceTrackingParams={() =>
                                    this.buddyCardSourceTrackingParams(props)
                                }
                                sourceTrackingQueryParams={props.location.search}
                            >
                                <div>
                                    <AnalyticsValue
                                        name={
                                            productSpec.embeddedUniversityBuddyCards.properties
                                                .UNIVERSITY_SLUG
                                        }
                                        value={props.match.params.universitySlug}
                                    />
                                    <AnalyticsValue
                                        name={
                                            productSpec.embeddedUniversityBuddyCards.properties
                                                .PRODUCT
                                        }
                                        value={productSpec.embeddedUniversityBuddyCards.NAME}
                                    />
                                    <AsyncMentorCardWidget {...props} />
                                </div>
                            </SourceTrackingRoute>
                        );
                    }}
                />

                <Route
                    path="/:universitySlug/blog"
                    render={(props) => {
                        return (
                            <SourceTrackingRoute
                                getSourceTrackingParams={() =>
                                    this.blogCardSourceTrackingParams(props)
                                }
                                sourceTrackingQueryParams={props.location.search}
                            >
                                <div>
                                    <AnalyticsValue
                                        name={
                                            productSpec.pwaUniversityWidget.properties
                                                .UNIVERSITY_SLUG
                                        }
                                        value={props.match.params.universitySlug}
                                    />
                                    <AnalyticsValue
                                        name={
                                            productSpec.embeddedUniversitySocialFeed.properties
                                                .PRODUCT
                                        }
                                        value={productSpec.embeddedUniversitySocialFeed.NAME}
                                    />
                                    <ProductOpenedEventWrapper>
                                        <AsyncBlogCardWidget {...props} />
                                    </ProductOpenedEventWrapper>
                                </div>
                            </SourceTrackingRoute>
                        );
                    }}
                />
            </Switch>
        );
    }
}

/**
 * Here we are going to be a bit tricky:
 * the version of pathToRegexp React Router uses doesn't get the :universitySlug notation
 * inside a regexp. we replace every variable by its synonym in regex: [^\/]
 *
 */
export const widgetPaths = ["/[^\\/]+/colour/[^\\/]+", "/[^\\/]+/blog", "/embed", "/pwa"];

export default WidgetsRouter;
