import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {ssoRedirect, ssoInit} from "@unibuddy/auth-ui";
import {rootURL} from "shared/Utils/WhereAmI";
import {FUSION_AUTH_APPLICATION_ID} from "../../../config";
import {getenv} from "../../../util/getEnv";
import {SSOLoader} from "./SSOLoader";

const GATEWAY_URL = getenv("GATEWAY_URL") as string;

export function triggerSSOPopup(universitySlug: string): void {
    // popup and go to /auth/trigger using javascript
    window.open(
        `/auth/sso-trigger?universitySlug=${universitySlug}`,
        "sso",
        `width=500,height=550,left=${(screen.width - 500) / 2},top=${(screen.height - 550) / 2}`,
    );
}

export const SSOTrigger = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const slug = searchParams.get("universitySlug");

    useEffect(() => {
        const triggerSSOFlow = async () => {
            if (!slug) {
                console.error("No university slug found!");
                return;
            }

            await ssoInit({
                applicationId: FUSION_AUTH_APPLICATION_ID,
                ssoProviders: ["google"],
                universitySlug: slug,
                gatewayUrl: GATEWAY_URL,
                successUrl: `${rootURL(location.pathname)}/auth/sso-success`,
            });

            ssoRedirect({ssoProvider: "google"});
        };

        triggerSSOFlow();
    }, [location.pathname, slug]);

    return <SSOLoader />;
};
