import {COOKIE_CATEGORIES, HOTJAR} from "shared/Utils/Constants";
import {getenv} from "./util/getEnv";

export function enableHotjar(preferences = []) {
    /*
        preferences is empty - If the University has not yet implemented cookie consent manager, then we do not check for
        user cookie consent
    */
    if (preferences.length > 0) {
        const hasHotjar = checkForHotjar(preferences);
        if (!hasHotjar) return;
    }

    const HOTJAR_SITE_ID = getenv("HOTJAR_SITE_ID");
    // Hotjar Tracking code for our site
    ((h, o, t, j, a, r) => {
        h.hj =
            h.hj ||
            (() => {
                // eslint-disable-next-line prefer-rest-params
                (h.hj.q = h.hj.q || []).push(arguments);
            });
        h._hjSettings = {hjid: HOTJAR_SITE_ID, hjsv: 6};
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}

function checkForHotjar(preferences) {
    return preferences.some((preference) => {
        const key = preference.trim().toLowerCase();
        return COOKIE_CATEGORIES.has(key) && COOKIE_CATEGORIES.get(key).includes(HOTJAR);
    });
}
