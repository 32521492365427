import React from "react";
import {Box, Button} from "@unibuddy/patron";
import {IconRightArrow} from "../../../Icons";

const questions = [
    "🧑‍🎓  Any tips for how to prepare before arriving?",
    "📚  How many hours do you study each week?",
    "👔  Do students work while at university?",
    "🛏  What is the accommodation like?",
];

export const SearchPageQuestions = ({
    onQuestionClick,
}: {
    onQuestionClick: (index: number) => void;
}) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            gap="xsmall"
            flexWrap="wrap"
            justifyContent="center"
        >
            {questions.map((question, index) => {
                return (
                    <Button
                        round
                        color="light"
                        size="sm"
                        onClick={() => onQuestionClick(index + 1)}
                    >
                        {question}
                        <Box
                            width="27px"
                            height="14px"
                            display="flex"
                            paddingTop="xxsmall"
                            paddingLeft="xxsmall"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <IconRightArrow width={15} height={11} color="#131622" />
                        </Box>
                    </Button>
                );
            })}
        </Box>
    );
};
