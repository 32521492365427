import SingleChoiceAttributeInput from "./SingleChoiceAttribute/SingleChoiceAttributeInput";
import SingleChoiceAttributeView from "./SingleChoiceAttribute/SingleChoiceAttributeView";

const customAttributeInputs = {
    single_choice: SingleChoiceAttributeInput,
};

const customAttributeViews = {
    single_choice: SingleChoiceAttributeView,
};

export function resolveCustomAttributeInput(type) {
    return customAttributeInputs[type];
}

export function resolveCustomAttributeView(type) {
    return customAttributeViews[type];
}
