import * as React from "react";
import {SVGProps} from "react";

export const IconStudent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", width = "1em", height = "1em", ...rest} = props;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M12.018 12.094a4.968 4.968 0 0 1-4.984-4.983.75.75 0 0 1 1.5 0A3.484 3.484 0 0 0 15.5 7.11a.75.75 0 1 1 1.5 0 4.983 4.983 0 0 1-4.982 4.983v.001ZM14.078 15.49a3.5 3.5 0 0 1 3.5 3.5v1.076a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5V18.99a3.5 3.5 0 0 1 3.5-3.5h4Zm0-1.5h-4a5 5 0 0 0-5 5v1.076a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V18.99a5 5 0 0 0-5-5ZM12.018 2.5c.195 0 .39.023.58.068l3.468.827-3.453.846a2.507 2.507 0 0 1-1.193 0l-3.45-.846 3.468-.827a2.52 2.52 0 0 1 .58-.068Zm0-1.5c-.312 0-.624.036-.928.109l-7.677 1.83a.453.453 0 0 0 0 .881l7.66 1.878a4.01 4.01 0 0 0 1.906 0l7.66-1.878a.453.453 0 0 0 0-.881l-7.683-1.83A3.98 3.98 0 0 0 12.03 1h-.01Z"
                fill={color}
            />
            <path
                d="M3.75 9.486a.75.75 0 0 1-.75-.75V3.625a.75.75 0 0 1 1.5 0v5.111a.75.75 0 0 1-.75.75Z"
                fill={color}
            />
        </svg>
    );
};
