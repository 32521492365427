import * as React from "react";
import ReactSelect, {Props as ReactSelectProps} from "react-select";
import AsyncReactSelect from "react-select/async";
import styled from "styled-components";

import {
    PATRON_INPUT_BACKGROUND_COLOUR,
    PATRON_INPUT_HEIGHT,
    PATRON_INPUT_PLACEHOLDER_COLOUR,
} from "../StyleConstants";
import {
    ReactSelectIndicatorSeparator,
    ReactSelectDownChevron,
    ReactSelectIndicator,
    ReactSelectOption,
    ReactSelectMultiValueRemove,
} from "./ReactSelectComponents";
import {makeReactSelectStyles} from "./styles";

function makeStyledReactSelect(selectComponent: ReactSelect) {
    return styled(selectComponent)`
        .react-select__control {
            border-color: ${PATRON_INPUT_BACKGROUND_COLOUR};
        }

        .react-select__value-container {
            min-height: ${PATRON_INPUT_HEIGHT}px;
            background-color: ${PATRON_INPUT_BACKGROUND_COLOUR};
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
        .react-select__placeholder {
            color: ${PATRON_INPUT_PLACEHOLDER_COLOUR};
        }

        .react-select__indicators {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            background-color: ${PATRON_INPUT_BACKGROUND_COLOUR};
        }
    ` as unknown as ReactSelect;
}

const StyledReactSelect = makeStyledReactSelect(ReactSelect);
const StyledAsyncReactSelect = makeStyledReactSelect(AsyncReactSelect);

function makeReactSelectComponent(isAsync: boolean) {
    return React.memo((props: ReactSelectProps) => {
        const styles = React.useMemo(() => makeReactSelectStyles(props.styles), [props.styles]);
        const ReactSelectRenderable = isAsync ? StyledAsyncReactSelect : StyledReactSelect;

        return (
            <ReactSelectRenderable
                {...props}
                classNamePrefix="react-select"
                styles={styles}
                components={{
                    IndicatorSeparator: ReactSelectIndicatorSeparator,
                    DropdownIndicator: ReactSelectIndicator,
                    DownChevron: ReactSelectDownChevron,
                    Option: ReactSelectOption,
                    MultiValueRemove: ReactSelectMultiValueRemove,
                }}
            />
        );
    });
}

export const AccessibleReactSelect = makeReactSelectComponent(false);
export const AccessibleAsyncReactSelect = makeReactSelectComponent(true);

AccessibleReactSelect.displayName = "AccessibleReactSelect";
AccessibleAsyncReactSelect.displayName = "AccessibleAsyncReactSelect";
