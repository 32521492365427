import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./MentorCardSectionItem.pcss";

const MentorCardSectionItem = (props) => (
    <div>
        {props.label ? <h3 className={s.label}>{props.label}</h3> : null}
        <div className={classNames(s.content, {[s.last]: props.last})}>{props.children}</div>
    </div>
);

MentorCardSectionItem.propTypes = {
    children: PropTypes.node.isRequired,
    last: PropTypes.bool.isRequired,
    label: PropTypes.string,
};

MentorCardSectionItem.defaultProps = {
    label: "",
    last: false,
};

export default MentorCardSectionItem;
