import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import get from "lodash/get";
import compose from "lodash/flowRight";
import {withApollo, graphql} from "react-apollo";
import {connect} from "react-redux";
import {getUniversitySlug} from "shared/Utils/CookieConsentUtils";
import {HAS_UNIVERSITY_FEATURE_QUERY} from "../Feature/HasUniversityFeature";
import Auth from "./auth";
import {SSO_NEXT_PATH} from "../../widgets/modules/SSO/constants";

// Higher-order component (HOC) to wrap restricted containers when routing
function requireAuthBuilder(options) {
    return function wrappedRequireAuthBuilder(BaseComponent) {
        class RequireAuthComponent extends Component {
            componentWillMount() {
                this.checkAuth(this.props);
            }

            componentWillReceiveProps(nextProps) {
                if (nextProps.location !== this.props.location) {
                    this.checkAuth(nextProps);
                }
            }

            checkAuth = (props) => {
                const {history, location} = props;
                const isVerified = Auth.isVerified(props.authState);
                const loggedIn = Auth.loggedIn(props.authState, options.accountRole);
                const slug = getUniversitySlug(window.location.href);

                // if client requires email verification
                if (get(options, "reqVerification", false)) {
                    // check if feature enabled query returns a response
                    if (get(props, "isEmailVerificationLoading", false)) return;
                    // check if user logged in, is not verified and uni has feature enabled.
                    if (
                        loggedIn &&
                        !isVerified &&
                        get(props, "isEmailVerificationEnabled", false)
                    ) {
                        const nextPathName = get(location, "state.nextPathname");
                        const pathName = get(location, "pathname");
                        history.replace({
                            pathname: options.verifyRedirectPath(props),
                            state: {nextPathname: !nextPathName ? pathName : nextPathName},
                            search: get(location, "search", ""),
                        });
                        sessionStorage.setItem(
                            `${SSO_NEXT_PATH}_${slug}`,
                            history.location.state.nextPathname ?? "",
                        );
                        return isVerified;
                    }
                }
                if (!loggedIn) {
                    history.replace({
                        pathname: options.redirectPath(props),
                        state: {nextPathname: location.pathname},
                        search: location.search,
                    });
                    sessionStorage.setItem(`${SSO_NEXT_PATH}_${slug}`, location.pathname ?? "");
                }
                return loggedIn;
            };

            render() {
                // call check auth when prop change
                // has uni feature query takes some time return resp.
                if (!get(this.props, "isEmailVerificationLoading", false)) {
                    this.checkAuth(this.props);
                }
                // this auth check makes sure the children components are not mounted
                if (
                    !Auth.loggedIn(this.props.authState, options.accountRole) ||
                    get(this.props, "isEmailVerificationLoading", false) ||
                    (get(options, "reqVerification", false) &&
                        get(this.props, "isEmailVerificationEnabled", false) &&
                        !Auth.isVerified(this.props.authState))
                ) {
                    return null;
                }
                return <BaseComponent {...this.props} />;
            }
        }

        const mapStateToProps = (state) => ({
            authState: state.authState,
        });

        const HAS_UNIVERSITY_FEATURE_QUERY_OPTIONS = {
            name: "hasUniversityFeature",
            options(props) {
                return {
                    variables: {
                        universitySlug: get(props, "match.params.universitySlug", ""),
                        feature: "enableEmailVerification",
                    },
                    fetchPolicy: "network-only",
                    notifyOnNetworkStatusChange: true,
                };
            },
            props({hasUniversityFeature}) {
                return {
                    isEmailVerificationEnabled: get(
                        hasUniversityFeature,
                        "university.isFeatureEnabled",
                        false,
                    ),
                    isEmailVerificationLoading: get(hasUniversityFeature, "loading", false),
                };
            },
        };

        return compose(
            withRouter,
            connect(mapStateToProps),
            withApollo,
            graphql(HAS_UNIVERSITY_FEATURE_QUERY, HAS_UNIVERSITY_FEATURE_QUERY_OPTIONS),
        )(RequireAuthComponent);
    };
}

export default requireAuthBuilder;
