/**
 * This function calls KWS api
 * @param dob date of birth
 * @returns {{
 *      isMinor: Boolean,
 *      age: number,
 *      consentAgeForCountry: number,
 *      country: string
 * }}
 */

import {getenv} from "../../../../util/getEnv";

export const getAgeGateInfo = async (dob) => {
    const AGE_VERIFICATION_URL = getenv("AGE_VERIFICATION_URL");
    const response = await fetch(`${AGE_VERIFICATION_URL}/v1/countries/child-age?dob=${dob}`);
    return response.json();
};
