import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import find from "lodash/find";
import noop from "lodash/noop";
import {Autocomplete} from "@unibuddy/patron";

const SingleChoiceAttributeInput = ({id, name, onChange, attribute, value}) => (
    <Autocomplete
        id={`autocomplete-${id}`}
        name={name}
        inputProps={{name, id}}
        onChange={(option) => onChange(get(option, "id"))}
        options={get(attribute, "options", [])}
        labelKey="value"
        selected={find(get(attribute, "options", []), {id: value})}
    />
);

SingleChoiceAttributeInput.propTypes = {
    attribute: PropTypes.object.isRequired,
    id: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
};

SingleChoiceAttributeInput.defaultProps = {
    id: "",
    name: "",
    onChange: noop,
    value: "",
};

export default SingleChoiceAttributeInput;
