import {IntlKeyValuePair, IntlNamespaces} from "./types";

export const INTL_REGISTER: IntlKeyValuePair = {
    key: "register",
    defaultValue: "Register",
    intlValue: "Register",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_DEFAULT_HEADER: IntlKeyValuePair = {
    key: "defaultHeader",
    defaultValue: "Chat to your future",
    intlValue: "Chat to your future",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_START_TO_CHAT: IntlKeyValuePair = {
    key: "startToChat",
    defaultValue: "Start to chat",
    intlValue: "Start to chat",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_MATCH_ME_HEADER: IntlKeyValuePair = {
    key: "matchMeHeader",
    defaultValue: "Tell us a bit about you so we can find the right person to help",
    intlValue: "Tell us a bit about you so we can find the right person to help",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_MATCH_ME_NOW: IntlKeyValuePair = {
    key: "matchMeNow",
    defaultValue: "Match me now",
    intlValue: "Match me now",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SIGN_UP_HEADER: IntlKeyValuePair = {
    key: "signUpHeader",
    defaultValue: "Send your message and get notified as soon as you receive a reply",
    intlValue: "Send your message and get notified as soon as you receive a reply",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_ALREADY_HAVE_AN_ACCOUNT: IntlKeyValuePair = {
    key: "alreadyHaveAnAccount",
    defaultValue: "Already have an account?",
    intlValue: "Already have an account?",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_LOGIN: IntlKeyValuePair = {
    key: "login",
    defaultValue: "Log in",
    intlValue: "Log in",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_PASSWORD: IntlKeyValuePair = {
    key: "password",
    defaultValue: "Password",
    intlValue: "Password",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_CONFIRM_PASSWORD: IntlKeyValuePair = {
    key: "confirmPassword",
    defaultValue: "Confirm password",
    intlValue: "Confirm password",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_EMAIL_VERIFICATION_MESSAGE: IntlKeyValuePair = {
    key: "emailVerificationMessage",
    defaultValue:
        "We'll send a verification link to this email address and use this to verify your account.",
    intlValue:
        "We'll send a verification link to this email address and use this to verify your account.",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_REGISTER_DETAILS: IntlKeyValuePair = {
    key: "registerDetails",
    defaultValue: "Register Details",
    intlValue: "Register Details",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_DATE_OF_ENTRY: IntlKeyValuePair = {
    key: "dateOfEntry",
    defaultValue: "When do you hope to join us?",
    intlValue: "When do you hope to join us?",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_FEW_LAST_DETAILS: IntlKeyValuePair = {
    key: "fewLastDetails",
    defaultValue: "Finally, a few last details...",
    intlValue: "Finally, a few last details...",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_STEP_PROGRESS: IntlKeyValuePair = {
    key: "stepProgress",
    defaultValue: "(step 2/2)",
    intlValue: "(step 2/2)",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SELECT_A_DATE: IntlKeyValuePair = {
    key: "selectADate",
    defaultValue: "Select a date",
    intlValue: "Select a date",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_WHERE_DO_YOU_LIVE: IntlKeyValuePair = {
    key: "whereDoYouLive",
    defaultValue: "Where do you live?",
    intlValue: "Where do you live?",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SELECT_COUNTRY: IntlKeyValuePair = {
    key: "selectCountry",
    defaultValue: "Select country",
    intlValue: "Select country",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_REGION: IntlKeyValuePair = {
    key: "region",
    defaultValue: "Domestic Region",
    intlValue: "Domestic Region",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SELECT_REGION: IntlKeyValuePair = {
    key: "selectRegion",
    defaultValue: "Select Domestic Region",
    intlValue: "Select Domestic Region",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_DEGREE_LEVEL_INTEREST: IntlKeyValuePair = {
    key: "degreeLevelInterest",
    defaultValue: "What degree level are you interested in?",
    intlValue: "What degree level are you interested in?",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SELECT_LEVEL: IntlKeyValuePair = {
    key: "selectLevel",
    defaultValue: "Select level",
    intlValue: "Select level",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_ADD_AREA_OF_STUDY: IntlKeyValuePair = {
    key: "addAreaOfStudy",
    defaultValue: "Add an area of study",
    intlValue: "Add an area of study",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_I_DONT_KNOW_YET: IntlKeyValuePair = {
    key: "iDontKnowYet",
    defaultValue: "I don't know yet",
    intlValue: "I don't know yet",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_AREA_OF_STUDY: IntlKeyValuePair = {
    key: "areaOfStudy",
    defaultValue: "Area of study",
    intlValue: "Area of study",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_DEGREE_SELECT_PLACEHOLDER: IntlKeyValuePair = {
    key: "degreeSelectPlaceholder",
    defaultValue: "Select one or more areas of study you are interested in ...",
    intlValue: "Select one or more areas of study you are interested in ...",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_DEGREE_SELECT_PLACEHOLDER_FOR_MOB: IntlKeyValuePair = {
    key: "degreeSelectPlaceholderForMob",
    defaultValue: "Select one or more",
    intlValue: "Select one or more",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_MARKETING_CONSENT: IntlKeyValuePair = {
    key: "marketingConsent",
    defaultValue:
        "I agree to receiving marketing communications contain further information about the institution, its courses and other information considered to be of legitimate interest to you.",
    intlValue:
        "I agree to receiving marketing communications contain further information about the institution, its courses and other information considered to be of legitimate interest to you.",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_WITHDRAW_CONSENT: IntlKeyValuePair = {
    key: "withdrawConsent",
    defaultValue:
        "You can withdraw consent at any time by changing your settings in Unibuddy and by the institution’s unsubscribe process.",
    intlValue:
        "You can withdraw consent at any time by changing your settings in Unibuddy and by the institution’s unsubscribe process.",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_CONTINUE: IntlKeyValuePair = {
    key: "continue",
    defaultValue: "Continue",
    intlValue: "Continue",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_INSTITUTION_PRIVACY_POLICY: IntlKeyValuePair = {
    key: "institutionPrivacyPolicy",
    defaultValue:
        "<0>Your data may be shared with third parties which will be outlined in the <2>Institution Privacy Policy</2>.</0>",
    intlValue:
        "<0>Your data may be shared with third parties which will be outlined in the <2>Institution Privacy Policy</2>.</0>",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SEARCH_SELECT: IntlKeyValuePair = {
    key: "searchSelect",
    defaultValue: "Please enter a search string at least 2 characters long to get options",
    intlValue: "Please enter a search string at least 2 characters long to get options",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_EMPTY_DEGREE: IntlKeyValuePair = {
    key: "emptyDegree",
    defaultValue: "No degrees found under the degree level you’ve chosen",
    intlValue: "No degrees found under the degree level you’ve chosen",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_LOADING: IntlKeyValuePair = {
    key: "loading",
    defaultValue: "Loading...",
    intlValue: "Loading...",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_NO_OPTIONS_MESSAGE: IntlKeyValuePair = {
    key: "noOptionsMessage",
    defaultValue: "No options",
    intlValue: "No options",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_LOCALE_PREFERENCE: IntlKeyValuePair = {
    key: "localePreference",
    defaultValue: "Preferred language for notifications?",
    intlValue: "Preferred language for notifications?",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SELECT_LANGUAGE: IntlKeyValuePair = {
    key: "selectLanguage",
    defaultValue: "Select Language",
    intlValue: "Select Language",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SIGN_UP_ERROR_MESSAGE: IntlKeyValuePair = {
    key: "signUpErrorMessage",
    defaultValue: "Sorry, something went wrong. Try logging in if you already have an account",
    intlValue: "Sorry, something went wrong. Try logging in if you already have an account",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SIGN_UP_GOOGLE: IntlKeyValuePair = {
    key: "signUpGoogle",
    defaultValue: "Sign up with Google",
    intlValue: "Sign up with Google",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_SIGN_UP_GOOGLE_ERROR: IntlKeyValuePair = {
    key: "signUpGoogleError",
    defaultValue:
        "We couldn't create your account with Google. Please try again or sign up using your email and password.",
    intlValue:
        "We couldn't create your account with Google. Please try again or sign up using your email and password.",
    namespace: IntlNamespaces.SIGNUP,
};

export const INTL_COMPLETE_PROFILE: IntlKeyValuePair = {
    key: "completeProfile",
    defaultValue: "Complete profile",
    intlValue: "Complete profile",
    namespace: IntlNamespaces.SIGNUP,
};
