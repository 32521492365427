import React from "react";
import {Box, Button, Stack, TextBlock} from "@unibuddy/patron";
import styled from "styled-components";
import {Trans, useTranslation} from "@unibuddy/intl";

import PageHeader from "shared/Shared/components/PageHeader/PageHeader";
import ThemedLink from "shared/Navigation/components/ThemedLink/ThemedLink";
import {rootURL} from "shared/Utils/WhereAmI";

import {PageTitle} from "../../../../components/PageTitle/PageTitle";
import {WideContainer} from "../../../../components/WideContainer/WideContainer";
import {IntlNamespaces} from "../../../../translations/types";
import {
    INTL_COMEBACK_LATER,
    INTL_NOT_QUIET_OLD,
    INTL_SUPPORT_LINK,
    INTL_TERMS_OF_USE_REQUIRE_ADULT,
} from "../../../../translations/verifyAge";

const Container = styled(WideContainer)`
    box-shadow: 0 3px 11px ${({theme}) => theme.colors.grey300};
`;

export const Minor = (props) => {
    const {t} = useTranslation(IntlNamespaces.VERIFY_AGE);

    return (
        <Container>
            <PageTitle title={t("minor", "Minor")} />
            <Box padding={["small", "xxlarge"]}>
                <Stack space={"large"}>
                    <PageHeader
                        title={t(INTL_NOT_QUIET_OLD.key, INTL_NOT_QUIET_OLD.defaultValue)}
                    />
                    <TextBlock align={"center"}>
                        {t(
                            INTL_TERMS_OF_USE_REQUIRE_ADULT.key,
                            INTL_TERMS_OF_USE_REQUIRE_ADULT.defaultValue,
                        )}
                    </TextBlock>
                    <TextBlock align={"center"}>
                        <Trans t={t} i18nKey={INTL_SUPPORT_LINK.key}>
                            {"For any further assistance, please contact our "}
                            <ThemedLink external to="https://help.unibuddy.co/" target="_blank">
                                {"support team"}
                            </ThemedLink>
                        </Trans>
                    </TextBlock>
                    <Box>
                        <Button
                            color={"primary"}
                            block
                            onClick={() => {
                                return props.history.replace(
                                    `${rootURL(props.location.pathname)}/buddies`,
                                );
                            }}
                        >
                            {t(INTL_COMEBACK_LATER.key, INTL_COMEBACK_LATER.defaultValue)}
                        </Button>
                    </Box>
                </Stack>
            </Box>
        </Container>
    );
};
