import {IntlKeyValuePair, IntlNamespaces} from "./types";

export const INTL_UNI_TERMS: IntlKeyValuePair = {
    key: "uniTerms",
    defaultValue:
        "We’ve noticed you already have a Unibuddy account. Please tell us about your interests and accept our Privacy Policy to continue.",
    intlValue:
        "We’ve noticed you already have a Unibuddy account. Please tell us about your interests and accept our Privacy Policy to continue.",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_INBOX: IntlKeyValuePair = {
    key: "inbox",
    defaultValue: "Inbox",
    intlValue: "Inbox",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_WATCH_THIS_SPACE_MESSAGE: IntlKeyValuePair = {
    key: "watchThisSpaceMessage",
    defaultValue: "Your messages with the ambassadors will show here",
    intlValue: "Your messages with the ambassadors will show here",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_NO_LAST_MESSAGE: IntlKeyValuePair = {
    key: "noLastMessage",
    defaultValue: "No last message",
    intlValue: "No last message",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_OPEN_CHAT: IntlKeyValuePair = {
    key: "openChat",
    defaultValue: (name: string) => `Open chat with ${name}`,
    intlValue: "Open chat with {{name}}",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_TO_PROTECT_DELINKED_PRIVACY: IntlKeyValuePair = {
    key: "toProtectDelinkedPrivacy",
    defaultValue: (universityName: string) =>
        `You are currently unable to chat with ambassadors at ${universityName} as they have removed your data from their system in order to protect your privacy. But not to worry, you can restore your account and continue your conversations...`,
    intlValue:
        "You are currently unable to chat with ambassadors at {{universityName}} as they have removed your data from their system in order to protect your privacy. But not to worry, you can restore your account and continue your conversations...",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_START_CHATTING_AGAIN: IntlKeyValuePair = {
    key: "startChattingAgain",
    defaultValue: "Get chatting again!",
    intlValue: "Get chatting again!",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_MAJOR: IntlKeyValuePair = {
    key: "major",
    defaultValue: "Major",
    intlValue: "Major",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_MINOR: IntlKeyValuePair = {
    key: "minor",
    defaultValue: "Minor",
    intlValue: "Minor",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_CONCENTRATION: IntlKeyValuePair = {
    key: "concentration",
    defaultValue: "Concentration",
    intlValue: "Concentration",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_JOINT_HONORS: IntlKeyValuePair = {
    key: "joint_honors",
    defaultValue: "Joint Honors",
    intlValue: "Joint Honors",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_HONORS: IntlKeyValuePair = {
    key: "honors",
    defaultValue: "Honors",
    intlValue: "Honors",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_SPECIAL_PROGRAMME: IntlKeyValuePair = {
    key: "special_programme",
    defaultValue: "Special Programme",
    intlValue: "Special Programme",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_SPECIALISATION: IntlKeyValuePair = {
    key: "specialisation",
    defaultValue: "Specialisation",
    intlValue: "Specialisation",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_FACULTY: IntlKeyValuePair = {
    key: "faculty",
    defaultValue: "Faculty",
    intlValue: "Faculty",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_CERTIFICATION: IntlKeyValuePair = {
    key: "certification",
    defaultValue: "Certification",
    intlValue: "Certification",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_USER_AVATAR: IntlKeyValuePair = {
    key: "userAvatar",
    defaultValue: (name: string) => `Profile of ${name}`,
    intlValue: "Profile of {{name}}",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_AMBASSADOR_NAME: IntlKeyValuePair = {
    key: "ambassadorName",
    defaultValue: (name: string) => `You are chatting with ${name}`,
    intlValue: "You are chatting with {{name}}",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_AMBASSADOR_TYPE: IntlKeyValuePair = {
    key: "ambassadorType",
    defaultValue: (role: string) => `and they are a ${role}`,
    intlValue: "and they are a {{role}}",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_AMBASSADOR_DEGREE_TITLE: IntlKeyValuePair = {
    key: "ambassadorDegreeTitle",
    defaultValue: (role: string, degreeTitle: string) =>
        `Degree title of ${role} is ${degreeTitle}`,
    intlValue: "Degree title of {{role}} is {{degreeTitle}}",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_CHAT_CREATION_SOURCE: IntlKeyValuePair = {
    key: "chatCreationSource",
    defaultValue: (creationSource: string) => `Chat has been initiated from ${creationSource}`,
    intlValue: "Chat has been initiated from {{creationSource}}",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_UNREAD_MESSAGES: IntlKeyValuePair = {
    key: "unreadMessages",
    defaultValue: (unreadMessageCount: string) => `You have ${unreadMessageCount} unread messages`,
    intlValue: "You have {{unreadMessageCount}} unread messages",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_LAST_MESSAGE: IntlKeyValuePair = {
    key: "lastMessage",
    defaultValue: (lastMessage: string) => `Last message in the conversation is: ${lastMessage}`,
    intlValue: "Last message in the conversation is: {{lastMessage}}",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_LAST_MESSAGE_SENT_DATE_TIME: IntlKeyValuePair = {
    key: "lastMessageSentDateTime",
    defaultValue: (date: string, time: string) => `Last message was sent on ${date} at ${time}`,
    intlValue: "Last message was sent on {{date}} at {{time}}",
    namespace: IntlNamespaces.INBOX,
};

export const INTL_SHARE_PERSONAL_DETAILS_WARNING: IntlKeyValuePair = {
    key: "sharePersonalDetailsWarning",
    defaultValue:
        "Sharing personal details like phone numbers and social media accounts are not allowed on this platform.",
    intlValue:
        "Sharing personal details like phone numbers and social media accounts are not allowed on this platform.",
    namespace: IntlNamespaces.INBOX,
};
