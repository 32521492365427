import _ from "lodash";
import {getenv} from "../../../util/getEnv";

export default function getTraits(me) {
    const DEFAULT_PROFILE_PHOTO = getenv("DEFAULT_PROFILE_PHOTO");
    const MEDIA_URL = getenv("MEDIA_URL");

    const accountRole = _.get(me, "anyUser.accountRole", "");
    const profilePhoto = _.get(me, "mentor.profilePhoto", null);
    const traits = {
        id: _.get(me, "anyUser.id", `anon${Date.now()}`),
        $email: _.get(me, "anyUser.email", "anonymous@unibuddy.co"),
        $first_name: _.get(me, "anyUser.firstName", "Anonymous"),
        $last_name: _.get(me, "anyUser.lastName", "Visitor"),
        $created: _.get(me, "anyUser.created", Date.now()),
        accountRole,
        profilePhoto: profilePhoto ? `${MEDIA_URL}${profilePhoto}` : DEFAULT_PROFILE_PHOTO,
        $phone: _.get(me, "mentor.phoneNumber", ""),
        active: _.get(me, "anyUser.active", ""),
        countryId: _.get(me, "mentor.country.id", ""),
        degreeLevel: _.get(me, "mentor.degreeLevel", ""),
    };
    if (accountRole === "applicant") {
        const unisInterested = _.get(me, "applicant.universitiesInterested", [""]);
        unisInterested.forEach((uni, idx) => {
            traits[`uniInterested${idx}`] = uni.$oid;
        });
        const degreesInterested = _.get(me, "applicant.degreesInterested", [""]);
        degreesInterested.forEach((uni, idx) => {
            traits[`degreeInterested${idx}`] = uni.$oid;
        });
    } else if (accountRole === "mentor") {
        traits.year = _.get(me, "mentor.year", "");
        traits.degree = _.get(me, "mentor.degree.id", "");
        traits.university = _.get(me, "mentor.university.id", "");
    }
    return traits;
}
