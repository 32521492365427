import React from "react";
import PropTypes from "prop-types";
import s from "./Label.pcss";

const Label = (props) => (
    <button
        className={s.label}
        onClick={props.onRemove}
        id="remove-label"
        data-test-id="remove-label"
    >
        {props.text}
        <svg
            className={s.icon}
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 24 24"
        >
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <path d="M.5.5l23 23M23.5.5l-23 23" />
            </g>
        </svg>
    </button>
);

Label.propTypes = {
    text: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default Label;
