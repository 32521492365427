import React from "react";
import {ButtonLink} from "@unibuddy/patron";

type CardButtonLinkProps = {
    link: string;
    color?: string;
    handleClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    children: React.ReactNode;
};

export const CardButtonLink = ({
    link,
    color = "default",
    handleClick,
    children,
}: CardButtonLinkProps) => {
    return (
        <ButtonLink
            block
            round
            ghost
            color={color}
            to={link}
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e)}
        >
            {children}
        </ButtonLink>
    );
};
