import React from "react";
import {Box, Text} from "@unibuddy/patron";

import {Avatar} from "./Avatar";

export function AvatarWithOverlay({src}: {src: string}) {
    return (
        <Box h="86px" w="100%" position="relative" flexShrink={0}>
            <Box h="86px" w="86px" marginY="none" marginX="auto" flexShrink={0}>
                <Avatar as="img" alt="Profile picture" src={src} />
            </Box>
            <Box
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                marginX="auto"
                bgColor="green100"
                width="fit-content"
                borderRadius="full"
                padding="xsmall"
            >
                <Text align="center" size="small" color="black">
                    Available now
                </Text>
            </Box>
        </Box>
    );
}
