import get from "lodash/get";
import find from "lodash/find";
import negate from "lodash/negate";
import gql from "graphql-tag";
import {useQuery} from "react-apollo";

export const GetUniversityFeaturesQuery = gql`
    query GetUniversityFeaturesQuery($universitySlug: String) {
        university(slug: $universitySlug) {
            id
            config {
                features {
                    enabledByUnibuddy
                    enabledByUniversity
                    universityCanToggleFeature
                    feature {
                        name
                    }
                }
            }
        }
    }
`;

function getFeatureConfig(name, university) {
    const features = get(university, "config.features", []);
    return find(features, {feature: {name}});
}

export function isFeatureEnabled(name, university) {
    const featureConfig = getFeatureConfig(name, university);
    return featureConfig ? featureConfig.enabled : false;
}

export function isFeatureReadOnly(name, university) {
    const featureConfig = getFeatureConfig(name, university);
    return featureConfig ? featureConfig.isReadOnly : false;
}

export const isFeatureDisabled = negate(isFeatureEnabled);

export function isFeatureEnabledByUnibuddyOrByUnibuddyAndUniversity(features, name) {
    const feature = find(features, {feature: {name}});
    const enabledByUnibuddy = get(feature, "enabledByUnibuddy", false);
    const enabledByUniversity = get(feature, "enabledByUniversity", false);
    const universityCanToggleFeature = get(feature, "universityCanToggleFeature", false);

    if (!enabledByUnibuddy) return false;
    if (enabledByUnibuddy && universityCanToggleFeature && enabledByUniversity) return true;
    if (enabledByUnibuddy && !universityCanToggleFeature) return true;
    if (enabledByUnibuddy && universityCanToggleFeature && !enabledByUniversity) return false;
}

export function useFeatureFlags(universitySlug) {
    const {data, loading, error} = useQuery(GetUniversityFeaturesQuery, {
        variables: {
            universitySlug,
        },
    });

    return {
        loading,
        error,
        data,
    };
}
