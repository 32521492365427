import * as React from "react";
import {SubmitButton} from "@unibuddy/patron";
import styled from "styled-components";
import useUniversityColor from "../../widgets/modules/UniversityProvider/hooks/useUniversityColor";

const StyledSubmitButtonBase = styled(SubmitButton)`
    color: white;
    &:hover:not([disabled]) {
        cursor: pointer;
        opacity: 0.85;
    }
`;

type UniversitySubmitButtonProps = {
    disabled?: boolean;
    ariaLabel: string;
    children: React.ReactNode;
};

export const UniversitySubmitButton: React.FC<UniversitySubmitButtonProps> = ({
    disabled,
    ariaLabel,
    children,
}) => {
    const universityColor = useUniversityColor();

    return (
        <StyledSubmitButtonBase
            block
            disabled={disabled}
            aria-label={ariaLabel}
            style={{backgroundColor: universityColor}}
        >
            {children}
        </StyledSubmitButtonBase>
    );
};
