const ERRORS = {
    SORRY: "We're sorry, there was a problem loading this page. ",
    NETWORK: "We had a problem communicating with the server, please try again. ",
    AMBASSADOR_REMOVED: "Ambassador is either archived or removed.",
    DOMAIN_EMAIL_SIGNING_UP_AS_PROSPECT:
        "This sign up form is not for staff, faculty members or student ambassadors - Wait to receive an email with further instructions.",
    INVITED_AMBASSADOR_SIGNING_UP_AS_PROSPECT:
        "This sign up form is not for staff, faculty members or student ambassadors - Please follow the instructions in the invitation email.",
    REGISTERED_AMBASSADOR_SIGNING_UP_AS_PROSPECT:
        "This sign up form is not for staff, faculty members or student ambassadors - You are a signed up ambassador. Please sign in from the ambassador dashboard.",
};

// These codes replicate the error codes defined in the backend
export const SERVER = 1;
export const FORBIDDEN = 2;
export const AUTHENTICATION = 3;
export const AUTHENTICATION_LOGIN = 5;
// User input errors
export const USER_INPUT = 4;
export const ALREADY_EXISTS = 41;
export const DOES_NOT_EXIST = 42;

export default ERRORS;
