import * as React from "react";
import {Box} from "@unibuddy/patron";

export type IconWithIndicatorProps = {
    show: boolean;
    icon: React.ReactNode;
};

export const IconWithIndicator: React.FC<IconWithIndicatorProps> = ({show, icon}) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Box position="relative" width={34}>
                {icon}
                {show ? (
                    <svg
                        data-test-id="icon-indicator"
                        style={{position: "absolute", top: "3px", right: "3px"}}
                        width={10}
                        height={10}
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="50" cy="50" r="50" fill="#CD3D44" />
                    </svg>
                ) : null}
            </Box>
        </Box>
    );
};
