// eslint-disable-next-line import/prefer-default-export

// Image Upload
export const IMAGE_UPLOAD_SIZE_MAX = 10485760; // 10MB
export const IMAGE_UPLOAD_SIZE_EXCEEDED_ERROR =
    "The image file you are trying to upload exceeds the maximum allowed size of 10MB.";
export const ALLOWED_IMAGE_FORMATS = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
export const IMAGE_FORMAT_ERROR =
    "Image file type not supported. File type we support are png, jpeg, jpg and gif. Re-upload with correct format";
export const PROFILE_REQUIRED = "Profile Photo is required";
//Policy and T&C links
export const TERMS_OF_USE_LINK = "https://unibuddy.com/terms-of-use/";
export const PRIVACY_POLICY_LINK = "https://unibuddy.com/privacy-policy/";
export const ACCEPTABLE_USE_POLICY_LINK = "https://unibuddy.com/acceptable-use-policy/";
export const COOKIE_POLICY_LINK = "https://unibuddy.com/cookie-policy/";
export const ACCESSIBILITY_POLICY_LINK = "https://unibuddy.com/accessibility-policy/";
export const SUBPROCESSORS_LINK = "https://unibuddy.com/sub-processors";

// Sign up
export const SIGN_UP_ERROR_MESSAGE =
    "Sorry, something went wrong. Try logging in if you already have an account";

export const UNIVERSITY_MULTI_CHOICE_CHAT = "0x7FFFFFFF";

// Launch Darkly Feature flags
export const NEXT_BEST_ACTIONS_FLAG = "ub-widget-next-best-actions";

type StringFeatureFlagProps = {
    key: string;
    value: string;
};

export type BooleanFeatureFlagProps = {
    key: string;
    value: boolean;
};

export const USE_REBRAND: BooleanFeatureFlagProps = {
    key: "ub-widget-rebrand",
    value: false,
};

export const SHOW_INBOX_PROMPT: BooleanFeatureFlagProps = {
    key: "ub-widget-inbox-prompt",
    value: false,
};

export const SHOW_FOR_YOU_TAB: BooleanFeatureFlagProps = {
    key: "ub-widget-for-you-tab",
    value: false,
};

export const SHOW_RECOMMENDATION_PROMPT: BooleanFeatureFlagProps = {
    key: "ub-widget-recommendation-prompt",
    value: false,
};

export const SHOW_UNIVERSITY_INBOX_MESSAGE: BooleanFeatureFlagProps = {
    key: "ub-widget-university-inbox-message",
    value: false,
};

export const UB_EDM_COHORTS_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-university-dashboard-edm-cohorts",
    value: false,
};

export const CUSTOMISE_PREVIEW_CARD: BooleanFeatureFlagProps = {
    key: "ub-widget-customise-ambassador-preview-card",
    value: false,
};

export const DEANONYMISE_PROSPECT_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-university-dashboard-deanonymise-prospect",
    value: false,
};

export const SHOW_ASSISTANT_INBOX_MESSAGE: BooleanFeatureFlagProps = {
    key: "ub-widget-assistant-inbox-message",
    value: false,
};

export const SHOW_INTRO_PAGE: BooleanFeatureFlagProps = {
    key: "ub-widget-intro-page",
    value: false,
};

export const INTRO_PAGE_CONTENT_TYPE: StringFeatureFlagProps = {
    key: "ub-widget-intro-page-content-type",
    value: "none",
};

export const TRAFFIC_DRIVERS_PRODUCT_OPENED_EVENT_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-traffic-drivers-product-opened-event",
    value: false,
};

export const EMBEDDED_WIDGET_PRODUCT_OPENED_EVENT_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-widget-embedded-product-opened-event",
    value: false,
};

export const SSO_ENABLED: BooleanFeatureFlagProps = {
    key: "ub-widget-sso-enabled",
    value: false,
};

export const AUTH_UI_V2: BooleanFeatureFlagProps = {
    key: "ub-widget-auth-ui-v2",
    value: false,
};

export const AUTH_UI_LOGIN_V2: BooleanFeatureFlagProps = {
    key: "ub-widget-auth-ui-login-v2",
    value: false,
};

export const CREATE_ACCOUNT_POPUP_V2: BooleanFeatureFlagProps = {
    key: "ub-widget-create-account-popup-v2",
    value: false,
};

export const BUDDYCARD_UI_V2: BooleanFeatureFlagProps = {
    key: "ub-widget-buddycard-ui-v2",
    value: false,
};
