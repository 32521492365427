import * as React from "react";
import {SVGProps} from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", ...rest} = props;
    return (
        <svg
            width={17}
            height={17}
            fill="none"
            viewBox="0 0 17 17"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M11.686 9.329a2.312 2.312 0 0 0-1.845.926l-3.904-1.79a2.253 2.253 0 0 0 0-.982L9.84 5.695a2.294 2.294 0 1 0-.423-.906L5.514 6.577a2.323 2.323 0 1 0 0 2.793l3.904 1.791a2.291 2.291 0 0 0 .337 1.782 2.323 2.323 0 1 0 1.931-3.614Zm0-6.355a1.322 1.322 0 1 1 0 2.645 1.322 1.322 0 0 1 0-2.645Zm-8.018 6.32a1.323 1.323 0 1 1 0-2.645 1.323 1.323 0 0 1 0 2.646Zm8.018 3.678a1.323 1.323 0 1 1 0-2.65 1.323 1.323 0 0 1 0 2.652v-.002Z"
                fill={color}
            />
        </svg>
    );
};

export default SvgComponent;
