import {getenv} from "../../../../../src/util/getEnv";

const concatenateOptions = (options, separator = "/") => options.filter(Boolean).join(separator);

export default function getDynamicImageUrl(src, options = {}) {
    const ASSETS_URL = getenv("ASSETS_URL");
    const defaults = {
        crop: false,
        width: 0,
        smart: false,
        height: 0,
        grayscale: false,
    };
    const {crop, width, smart, height, grayscale} = {...defaults, ...options};

    /**
     * EXAMPLE URL:  https://assets.unibuddy.co/100x100/smart/image.jpg
     * BASE ORDER: CDN_NAME(*)/RESIZE_TYPE(?)/DIMENSIONS(?)/SMART(?)/FILTERS:FILTER-1(?)/ASSET(*)
     * ----> (*) : required
     * ----> (?) : optional
     * Thumbor doc: https://thumbor.readthedocs.io/en/latest/
     * aws : https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/appendix-a.html
     */
    const hasDimensions = !!(width || height);
    /* Only resize if the image has a dimension, and is not cropping or smart cropping. */
    const shouldResize = hasDimensions && !crop && !smart;
    const resizeMode = height && width ? "fit-in" : "full-fit-in";

    const resize = shouldResize && resizeMode;
    const dimensions = hasDimensions && `${width}x${height}`;
    const isSmart = smart && "smart";
    const filters = concatenateOptions([grayscale && "grayscale()"], "");
    const appliedFilters = filters.length && `filters:${filters}`;

    /**
     * To be optimal to the pixel ratio of the screen, we need to scale the values from units
     * to pixels
     * */
    const path = concatenateOptions([resize, dimensions, isSmart, appliedFilters, src]);

    const uri = `${ASSETS_URL}/${path}`;

    return uri;
}
