import Auth from "../../Auth/auth";

const checkAllowDelinkedForApplicant = (universitySlug, meAuthState) => {
    if (universitySlug && meAuthState) {
        const currentApplicantUniversityLink = Auth.getCurrentApplicantUniversity(
            meAuthState,
            universitySlug,
        );

        if (currentApplicantUniversityLink) {
            return !!(currentApplicantUniversityLink && currentApplicantUniversityLink.delinked);
        }
    }
    return false;
};

export default checkAllowDelinkedForApplicant;
