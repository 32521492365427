import {Box} from "@unibuddy/patron";
import React from "react";
import styled from "styled-components";
import {useQuery} from "react-apollo";
import {useHistory} from "react-router";

import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import {IntroPageCard} from "./IntroPageCard";
import {RandomAvatarSvg} from "./RandomAvatarSvg";
import {getRandomAmbassadors} from "../../Intro";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {IntroPageCardLoading} from "./IntroPageCardLoading";
import {AvatarStack} from "./Avatar";
import {AvatarWithOverlay} from "./AvatarWithOverlay";

const StyledBox = styled(Box)`
    flex-direction: column;

    @media (min-width: 600px) {
        flex-direction: row;
    }
`;

function shuffleArray<T>(array: T[]): T[] {
    // eslint-disable-next-line no-plusplus
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function IntroPageCardsContainer({
    setUserHasSeenIntroPage,
}: {
    setUserHasSeenIntroPage: (b: boolean) => void;
}) {
    const {trackEvent} = useAnalytics();
    const {slug} = useUniversity();
    const history = useHistory();
    const {data, loading, error} = useQuery(getRandomAmbassadors, {
        variables: {
            universitySlug: slug,
            limit: 3,
        },
        skip: !slug,
    });

    if (!slug || loading) {
        return (
            <StyledBox display="flex" gap="small-medium">
                {Array.from({length: 3}, (_, index) => (
                    <IntroPageCardLoading key={index} />
                ))}
            </StyledBox>
        );
    }

    if (!data || error || data.sequenceFilterMentorList.mentors.length < 3) {
        setUserHasSeenIntroPage(true);
        history.replace(window.location.pathname.replace("/intro", "/students"));
        return null;
    }

    const unshuffledData = [
        {
            hero: <AvatarWithOverlay src={data.sequenceFilterMentorList.mentors[0].profilePhoto} />,
            text: "Start a chat with the next available student",
            buttonText: "Chat now",
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            relativeLink: `inbox/pre/${data.sequenceFilterMentorList.mentors[0].id}`,
        },
        {
            hero: <RandomAvatarSvg />,
            text: "Get matched with a student like you",
            buttonText: "Match me",
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            relativeLink: `inbox/pre/${data.sequenceFilterMentorList.mentors[1].id}`,
        },
        {
            hero: (
                <AvatarStack
                    profilePhotos={[
                        data.sequenceFilterMentorList.mentors[0].profilePhoto,
                        data.sequenceFilterMentorList.mentors[1].profilePhoto,
                        data.sequenceFilterMentorList.mentors[2].profilePhoto,
                    ]}
                />
            ),
            text: "Browse all student and staff ambassadors",
            buttonText: "Explore profiles",
            relativeLink: "buddies/students",
        },
    ];

    const cardData = shuffleArray(unshuffledData);

    const onClick = (buttonText: string, index: number, relativeLink: string) => {
        trackEvent(sharedEvents.introPageCardClicked.name, {
            universitySlug: slug,
            page: "Student intent intro screen",
            cta: buttonText,
            ctaOrder: index + 1,
        });
        setUserHasSeenIntroPage(true);
        const updatedUrl = window.location.pathname.replace("/buddies/intro", "");
        history.replace(`${updatedUrl}/${relativeLink}`);
    };

    return (
        <StyledBox display="flex" gap="small-medium">
            {cardData.map((card, index) => (
                <IntroPageCard
                    key={card.buttonText}
                    hero={card.hero}
                    text={card.text}
                    buttonText={card.buttonText}
                    relativeLink={card.relativeLink}
                    index={index}
                    onClick={onClick}
                />
            ))}
        </StyledBox>
    );
}
