import React from "react";
import {Box, Text} from "@unibuddy/patron";
import ReactSlick from "react-slick";
import styled from "styled-components";

import {RecommendationResourceTypeEnum} from "../NextBestActions";
import ContentTypeChip from "./NextBestActionsChip";

export const StyledReactSlick = styled(ReactSlick)`
    .slick-slide {
        display: block !important;
    }
`;

const StyledTickerTitle = styled(Box)`
    display: flex !important;
`;

type TickerHeaderProps = {
    data: {
        generateRecommendations: {
            id: string;
            type: RecommendationResourceTypeEnum;
            title: string;
        }[];
    };
};

export const TickerHeader: React.FC<TickerHeaderProps> = ({data}) => (
    <StyledReactSlick
        dots={false}
        arrows={false}
        infinite
        slidesToShow={1}
        autoplay
        speed={500}
        autoplaySpeed={7000}
        cssEase="linear"
        draggable={false}
    >
        {data.generateRecommendations.map((content) => (
            <StyledTickerTitle
                display="flex"
                flexDirection="row"
                alignItems="center"
                vertical-align="center"
                justifyContent="flex-start"
                data-test-id="nba-header-ticker-wrapper"
                key={content.id}
            >
                <ContentTypeChip contentType={content.type} />
                <Box paddingLeft="xsmall">
                    <Text
                        size="xsmall"
                        key={content.id}
                        lineHeight="20px"
                        weight="600"
                        color="white"
                        truncate
                    >
                        {content.title}
                    </Text>
                </Box>
            </StyledTickerTitle>
        ))}
    </StyledReactSlick>
);
