import * as actions from "../actions/firstMessageActions";

function firstMessageReducer(
    state = {
        // key, value pairs of opponentId and text
        // this saves the message an applicant has typed before finishing the signup
        firstMessages: {},
    },
    action,
) {
    switch (action.type) {
        case actions.SET_FIRST_MESSAGE:
            return {
                ...state,
                firstMessages: {
                    ...state.firstMessages,
                    [action.opponentId]: action.text,
                },
            };
        default:
            return state;
    }
}

export default firstMessageReducer;
