import React from "react";

export const IconSend = (props: React.SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", width = "1em", height = "1em", ...rest} = props;
    return (
        <svg
            aria-hidden
            width={width}
            height={height}
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            focusable="false"
            {...rest}
        >
            <path
                d="M10.99 9.063H5.937a1.2 1.2 0 00-1 .534L1 15.512a1.2 1.2 0 001.534 1.735l14.136-7.112a1.201 1.201 0 000-2.141L2.484.882A1.2 1.2 0 00.952 2.62l2.726 3.947"
                stroke={color}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
