import React from "react";
import {useTranslation} from "@unibuddy/intl";
import {useLocation} from "react-router-dom";

import {rootURL} from "shared/Utils/WhereAmI";

import {getenv} from "../../../../util/getEnv";
import {Card} from "../components/Card";
import {CardHeader} from "../components/CardHeader";
import {CardHeaderInfoWrapper} from "../components/CardHeaderInfoWrapper";
import {CardAvatarClickHandler} from "../components/CardAvatarClickHandler";
import {CardAvatar} from "../components/CardAvatar";
import {CardHeaderInfo} from "../components/CardHeaderInfo";
import {CardButtonLink} from "../components/CardButtonLink";
import {CardBody} from "../components/CardBody";
import {CardBodyActions} from "../components/CardBodyActions";
import {CardBodySectionWrapper} from "../components/CardBodySectionWrapper";
import {BuddyField} from "../../../../types/generated";
import {CardBodySection} from "../components/CardBodySection";
import {IntlNamespaces} from "../../../../translations/types";
import {
    INTL_CHAT_WITH,
    INTL_ROLE_DESCRIPTION,
    INTL_READ_MORE,
} from "../../../../translations/mentorCard";
import {isFunction} from "../../../../util/isFunction";

type ExtendedBuddyField = BuddyField & {
    staffBuddyName?: string;
};

export type StaffBuddycardProps = {
    buddy: ExtendedBuddyField;
    onChatNow: (buddyId: string) => void;
    onSeeMore: (buddyId: string) => void;
    isInternal?: boolean;
    avatarClickLink?: string;
    avatarLinkIsExternal?: boolean;
    onAvatarClicked?: () => void;
};

export const StaffBuddycard = ({
    buddy,
    onChatNow,
    onSeeMore,
    isInternal,
    avatarClickLink,
    avatarLinkIsExternal,
    onAvatarClicked,
}: StaffBuddycardProps) => {
    const {t} = useTranslation("mentorCard");
    const location = useLocation();
    const chatLink = `${rootURL(location.pathname)}/inbox/chatwith/${buddy.id}`;
    const moreLink = `${rootURL(location.pathname)}/buddies/staff/${buddy.id}`;
    const ASSETS_URL: string = getenv("ASSETS_URL", "") as string;

    const buddyName: string = buddy?.staffBuddyName ?? buddy?.firstName ?? "";

    function handleButtonLink(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        onChatNow(buddy.id);
    }

    function handleSeeMore(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        e.preventDefault();
        onSeeMore(buddy.id);
    }

    const roleInfo = buddy?.staffFields?.role
        ? {
              text: buddy.staffFields.role,
          }
        : undefined;

    const departmentInfo = buddy?.staffFields?.department
        ? {
              text: buddy.staffFields.department,
              color: buddy?.staffFields?.role ? "grey600" : undefined,
          }
        : undefined;

    const infoArray = [];

    if (roleInfo) {
        infoArray.push(roleInfo);
    }
    if (departmentInfo) {
        infoArray.push(departmentInfo);
    }

    return (
        <Card>
            <CardHeader>
                <CardHeaderInfoWrapper>
                    <CardAvatarClickHandler
                        link={avatarClickLink}
                        handleClick={onAvatarClicked}
                        ariaLabel={`Mentor ${buddyName}`}
                        external={avatarLinkIsExternal}
                    >
                        <CardAvatar
                            url={`${ASSETS_URL}/${buddy.profilePhoto}`}
                            alt={`Mentor ${buddyName}`}
                        />
                    </CardAvatarClickHandler>
                    <CardHeaderInfo buddyName={buddyName} info={infoArray} />
                </CardHeaderInfoWrapper>
                <CardButtonLink link={chatLink} color="university" handleClick={handleButtonLink}>
                    {t(
                        `${IntlNamespaces.MENTOR_CARD}:${INTL_CHAT_WITH.key}`,
                        isFunction(INTL_CHAT_WITH.defaultValue)
                            ? INTL_CHAT_WITH.defaultValue(buddyName)
                            : INTL_CHAT_WITH.defaultValue,

                        {name: buddyName},
                    )}
                </CardButtonLink>
            </CardHeader>
            <CardBody>
                <CardBodySectionWrapper>
                    <CardBodySection
                        heading={t(
                            `${IntlNamespaces.MENTOR_CARD}:${INTL_ROLE_DESCRIPTION.key}`,
                            `${INTL_ROLE_DESCRIPTION.defaultValue as string}`,
                        )}
                        content={`${buddy?.staffFields?.bio.slice(0, 70) ?? ""}...`}
                    />
                </CardBodySectionWrapper>
                {isInternal && (
                    <CardBodyActions handleClick={handleSeeMore} link={moreLink} external={false}>
                        {t(
                            `${IntlNamespaces.MENTOR_CARD}:${INTL_READ_MORE.key}`,
                            isFunction(INTL_READ_MORE.defaultValue)
                                ? INTL_READ_MORE.defaultValue(buddyName)
                                : INTL_READ_MORE.defaultValue,

                            {name: buddyName},
                        )}
                    </CardBodyActions>
                )}
            </CardBody>
        </Card>
    );
};
