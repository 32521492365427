import {GroupBase, OptionProps, StylesConfig} from "react-select";
import {
    ACCESSIBLE_BLACK,
    ACCESSIBLE_BLUE,
    ACCESSIBLE_DARK_BLUE,
    ACCESSIBLE_TRANSPARENT,
    ACCESSIBLE_WHITE,
    PATRON_INPUT_HEIGHT,
} from "../StyleConstants";

export type OptionState = OptionProps<unknown, boolean, GroupBase<unknown>>;
export type ReactSelectStyles = StylesConfig<unknown, boolean, GroupBase<unknown>>;

function setOptionBackgroundColor(state: OptionState): string {
    if (state.isFocused) {
        return ACCESSIBLE_BLUE;
    } else if (state.isFocused && state.isSelected) {
        return ACCESSIBLE_BLUE;
    }
    return ACCESSIBLE_TRANSPARENT;
}

function setOptionTextColor(state: OptionState): string {
    if (state.isFocused) {
        return ACCESSIBLE_WHITE;
    }
    return ACCESSIBLE_BLACK;
}

export function makeReactSelectStyles(propsStyles: ReactSelectStyles): ReactSelectStyles {
    return {
        container: (styles) => ({
            ...styles,
            minHeight: `${PATRON_INPUT_HEIGHT}px`,
        }),
        control: (styles) => ({
            ...styles,
            minHeight: `${PATRON_INPUT_HEIGHT}px`,
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "var(--stone-grey)",
            ":hover": {
                color: "var(--stone-grey)",
            },
        }),
        loadingMessage: (styles) => ({
            ...styles,
            color: ACCESSIBLE_BLACK,
        }),
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: ACCESSIBLE_DARK_BLUE,
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: ACCESSIBLE_WHITE,
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            color: ACCESSIBLE_WHITE,
        }),
        noOptionsMessage: (styles) => ({
            ...styles,
            color: ACCESSIBLE_BLACK,
        }),
        option: (styles, state) => ({
            ...styles,
            backgroundColor: setOptionBackgroundColor(state),
            color: setOptionTextColor(state),
        }),
        placeholder: (styles) => ({
            ...styles,
            color: ACCESSIBLE_BLACK,
        }),
        ...propsStyles,
    };
}
