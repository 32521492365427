import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Query, useQuery} from "react-apollo";
import get from "lodash/get";
import {Box, VisuallyHidden} from "@unibuddy/patron";
import pickBy from "lodash/pickBy";
import classNames from "classnames";
import {useTranslation} from "@unibuddy/intl";

import PageHeader from "shared/Shared/components/PageHeader/PageHeader";
import {rootURL} from "shared/Utils/WhereAmI";
import {useBuddiesFilters} from "shared/Buddies/components/BuddiesFiltersProvider/BuddiesFiltersProvider";
import ForwardLoader from "shared/General/components/ForwardLoader/ForwardLoader";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import buddiesFilterOptionsQuery from "shared/Buddies/queries/buddiesFilterOptionsQuery";
import ErrorDisplay from "shared/General/components/ErrorDisplay/ErrorDisplay";

import {PageTitle} from "../../../../../components/PageTitle/PageTitle";
import buddiesQuery from "../../queries/buddiesQuery";
import BuddyListCard from "../BuddyListCard/BuddyListCard";
import s from "./BuddiesList.pcss";
import BuddiesFilterSection from "../BuddiesFilterSection/BuddiesFilterSection";
import {IntlNamespaces} from "../../../../../translations/types";
import {INTL_NO_AMBASSADOR_MSG, INTL_STAFF} from "../../../../../translations/common";

const staffFilterFields = ["role", "department"];

const updateQuery = (prev, {fetchMoreResult}) => {
    if (!fetchMoreResult) return prev;

    return {
        ...prev,
        buddies: {
            ...prev.buddies,
            users: [...prev.buddies.users, ...fetchMoreResult.buddies.users],
        },
    };
};

const processFilters = (filters, buddyGroupType) => {
    if (buddyGroupType === "staff") {
        return {staffFields: JSON.stringify(pickBy(filters))};
    }
    return filters;
};

const getSearchParamsWithSourceTracking = (buddyPosition, buddyGroupType) => {
    const params = new URLSearchParams(window.location.search);
    params.set("buddyPosition", buddyPosition);
    params.set("buddyGroupType", buddyGroupType);
    return params.toString();
};

const onChatNow = ({compProps, buddyId, rootUrl, buddyPosition, buddyGroupType}) => {
    const params = getSearchParamsWithSourceTracking(buddyPosition, buddyGroupType);

    compProps.history.push(`${rootUrl}/inbox/chatwith/${buddyId}?${params}`);
};

const onSeeMore = ({compProps, buddyId, rootUrl, buddyPosition, buddyGroupType}) => {
    const params = getSearchParamsWithSourceTracking(buddyPosition, buddyGroupType);

    compProps.history.push(`${rootUrl}/buddies/${buddyGroupType}/${buddyId}?${params}`);
};

const onAvatarClicked = ({compProps, buddyId, rootUrl, buddyPosition, buddyGroupType}) => {
    const params = getSearchParamsWithSourceTracking(buddyPosition, buddyGroupType);

    compProps.history.push(`${rootUrl}/buddies/${buddyGroupType}/${buddyId}?${params}`);
};

const BuddiesListPageHeader = ({pageTitle}) => (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
        <VisuallyHidden>
            <PageHeader autofocus className={s.title} data-test-id="title" title={pageTitle} />
        </VisuallyHidden>
    </Box>
);

/* FYI: This Component is only used by the staff page */
export default function BuddiesList({limit, match, history}) {
    const {t} = useTranslation(IntlNamespaces.COMMON);
    const buddyGroupType = get(match, "params.buddyGroupType", "staff");
    const universitySlug = get(match, "params.universitySlug");
    const {data: buddiesFilterOptionsData} = useQuery(buddiesFilterOptionsQuery, {
        variables: {
            universitySlug,
            groupType: buddyGroupType,
            active: true,
        },
    });
    const {filters, setFilters, clearFilters} = useBuddiesFilters();
    const staffFiltersObject = staffFilterFields.reduce(
        (prev, curr) => (filters[curr] ? {...prev, [curr]: filters[curr]} : prev),
        {},
    );
    const customAttributesFilterOptions =
        get(buddiesFilterOptionsData, "buddiesFilterOptions.customAttributesFilters", []) || [];
    const customAttributesFiltersObject = customAttributesFilterOptions.reduce(
        (prev, curr) => (filters[curr.id] ? {...prev, [curr.id]: filters[curr.id]} : prev),
        {},
    );
    const customAttributesFilters = Object.keys(customAttributesFiltersObject).map((key) => ({
        field: key,
        value: filters[key],
    }));

    const variables = {
        universitySlug,
        role: "staff",
        offset: 0,
        limit,
        customAttributesFilters: customAttributesFilters.length
            ? customAttributesFilters
            : undefined,
        ...processFilters(staffFiltersObject, buddyGroupType),
    };

    const {
        events: {buddiesListViewed},
    } = useAnalytics();

    /* Disabling eslint as the function reference changes and will cause an
     * infinite loop if it's added to the dependency array */
    /* eslint-disable */
    useEffect(() => () => clearFilters(), []);
    /* eslint-enable */

    useEffect(() => {
        buddiesListViewed({
            buddyType: buddyGroupType,
        });
    }, [buddiesListViewed, buddyGroupType]);

    const pageTitle = `${t(buddyGroupType, INTL_STAFF.defaultValue)} `;

    return (
        <Query
            fetchPolicy="cache-and-network"
            query={buddiesQuery}
            notifyOnNetworkStatusChange
            variables={variables}
        >
            {({data, error, fetchMore, networkStatus}) => {
                let errorJsx = null;
                const buddyCount = get(data, "buddies.count", 0);
                const buddies = get(data, "buddies.users", []);
                const randomizeListSeed = get(data, "buddies.randomizeListSeed", null);
                if (networkStatus === 1) {
                    errorJsx = (
                        <div className={s.informationText} data-test-id="buddies-list-loading">
                            Loading...
                        </div>
                    );
                } else if (!buddyCount) {
                    errorJsx = (
                        <div>
                            <div className={s.informationText}>
                                {t(INTL_NO_AMBASSADOR_MSG.key, INTL_NO_AMBASSADOR_MSG.defaultValue)}
                            </div>
                        </div>
                    );
                }
                const hasMore = buddies.length < buddyCount;
                const compProps = {limit, match, history};
                return (
                    <ErrorDisplay error={networkStatus !== 1 && error ? error : null}>
                        <div id="buddies-page">
                            <PageTitle title={t(buddyGroupType, INTL_STAFF.defaultValue)} />
                            <BuddiesListPageHeader pageTitle={pageTitle} />
                            <React.Suspense fallback={"loading..."}>
                                <BuddiesFilterSection
                                    buddyGroupType={buddyGroupType}
                                    filters={{
                                        ...staffFiltersObject,
                                        ...customAttributesFiltersObject,
                                    }}
                                    setFilters={(updatedfilters) => {
                                        return setFilters({...filters, ...updatedfilters});
                                    }}
                                    buddiesFilterOptionsData={buddiesFilterOptionsData}
                                />
                            </React.Suspense>
                            {!errorJsx ? (
                                <React.Fragment>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        flexDirection="column"
                                        alignItems="center"
                                    >
                                        <div
                                            className={classNames(s.buddiesCardsContainer)}
                                            id="tiles"
                                        >
                                            {buddies.map((buddy, buddyPosition) => {
                                                return (
                                                    <BuddyListCard
                                                        data-test-id="buddy-card-container"
                                                        key={buddy.id}
                                                        buddy={buddy}
                                                        rootUrl={`${rootURL(
                                                            match.url,
                                                        )}/buddies/${buddyGroupType}/${buddy.id}`}
                                                        onChatNow={() =>
                                                            onChatNow({
                                                                compProps,
                                                                buddyGroupType,
                                                                buddyPosition,
                                                                buddyId: buddy.id,
                                                                rootUrl: rootURL(match.url),
                                                            })
                                                        }
                                                        onSeeMore={() =>
                                                            onSeeMore({
                                                                compProps,
                                                                buddyGroupType,
                                                                buddyPosition,
                                                                buddyId: buddy.id,
                                                                rootUrl: rootURL(match.url),
                                                            })
                                                        }
                                                        onAvatarClicked={() =>
                                                            onAvatarClicked({
                                                                compProps,
                                                                buddyGroupType,
                                                                buddyPosition,
                                                                buddyId: buddy.id,
                                                                rootUrl: rootURL(match.url),
                                                            })
                                                        }
                                                        isInternal
                                                    />
                                                );
                                            })}
                                        </div>
                                        {hasMore ? (
                                            <Box marginTop="small">
                                                <ForwardLoader
                                                    fetchMore={() =>
                                                        fetchMore({
                                                            variables: {
                                                                offset: buddies.length,
                                                                randomizeListSeed,
                                                            },
                                                            updateQuery,
                                                        })
                                                    }
                                                    showLoadButton={hasMore}
                                                />
                                            </Box>
                                        ) : null}
                                    </Box>
                                </React.Fragment>
                            ) : (
                                errorJsx
                            )}
                        </div>
                    </ErrorDisplay>
                );
            }}
        </Query>
    );
}

BuddiesList.propTypes = {
    limit: PropTypes.number,
};

BuddiesList.defaultProps = {
    limit: 12,
};
