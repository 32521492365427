// / action types

export const SET_FIRST_MESSAGE = "SET_FIRST_MESSAGE";

// / action creators

export function setFirstMessage(opponentId, text) {
    return {
        type: SET_FIRST_MESSAGE,
        text,
        opponentId,
    };
}
