import {createContext, useContext} from "react";
import {WIDGET_PAGE} from "./PageTitles";

const Page = createContext(undefined);

type PageContext = {
    page: WIDGET_PAGE;
    setPage: (arg0: WIDGET_PAGE) => void;
};

const usePage = () => {
    const context: PageContext = useContext(Page);

    if (context === undefined) {
        throw new Error("Page was used outside of its Provider");
    }

    return context;
};

export {Page, usePage};
