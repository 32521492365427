import * as React from "react";
import * as yup from "yup";
import isNil from "lodash/isNil";
import {useTranslation} from "@unibuddy/intl";
import {Box, Button, Dialog, Form, Heading, OptionFormGroup, Stack} from "@unibuddy/patron";

import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import {
    ProspectStageLocation,
    ProspectStageNoneOfTheAbove,
    ProspectStagesForUi,
} from "./prospectStages";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {UniversitySubmitButton} from "../../../../../components/SubmitButton/SubmitButton";
import {UniversityRadioButton} from "../../../../../components/RadioButton/RadioButton";
import {IntlNamespaces} from "../../../../../translations/types";
import {
    INTL_POPUP_SKIP_BUTTON,
    INTL_POPUP_SUBMIT_BUTTON,
    INTL_POPUP_TITLE,
} from "../../../../../translations/decisionStage";

type ProspectStageProps = {
    onDismiss: () => void;
    onSubmit: (...args: unknown[]) => void | Promise<void>;
    isOpen: boolean;
    location: ProspectStageLocation;
};

export const ProspectStage: React.FC<ProspectStageProps> = ({
    onDismiss,
    onSubmit,
    isOpen,
    location,
}) => {
    const {t} = useTranslation(IntlNamespaces.DECISION_STAGE);
    const {trackEvent} = useAnalytics();
    const university = useUniversity();

    React.useEffect(() => {
        trackEvent(sharedEvents.prospectDecisionStagesShown.name, {
            universityId: university.id,
            universityName: university.name,
            where: location,
        });
    }, [university, trackEvent, location]);

    return (
        <Dialog isOpen={isOpen} size="small" verticalAlign="center">
            <Form
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    onSubmit(values.prospectStage);
                }}
                initialValues={{prospectStage: null}}
                validationSchema={{
                    prospectStage: yup.string().required(),
                }}
            >
                {({values}) => (
                    <Box padding="medium">
                        <Stack space="small">
                            <Box paddingY="xsmall">
                                <Heading level="1" size="small" align="center">
                                    {t(INTL_POPUP_TITLE.key, INTL_POPUP_TITLE.defaultValue)}
                                </Heading>
                            </Box>
                            <OptionFormGroup name="prospectStage">
                                <Stack space={["xsmall", "small"]}>
                                    {ProspectStagesForUi.map((stage) => (
                                        <UniversityRadioButton
                                            value={stage.value}
                                            label={t(stage.translationKey, stage.name)}
                                        />
                                    ))}
                                    <UniversityRadioButton
                                        value={ProspectStageNoneOfTheAbove.value}
                                        label={t(
                                            ProspectStageNoneOfTheAbove.translationKey,
                                            ProspectStageNoneOfTheAbove.name,
                                        )}
                                    />
                                </Stack>
                            </OptionFormGroup>
                            <Box display="flex" flexDirection="row" gap="small">
                                <Button aria-label="skip form button" onClick={onDismiss} block>
                                    {t(
                                        INTL_POPUP_SKIP_BUTTON.key,
                                        INTL_POPUP_SKIP_BUTTON.defaultValue,
                                    )}
                                </Button>
                                <UniversitySubmitButton
                                    ariaLabel="submit form button"
                                    disabled={isNil(values.prospectStage)}
                                >
                                    {t(
                                        INTL_POPUP_SUBMIT_BUTTON.key,
                                        INTL_POPUP_SUBMIT_BUTTON.defaultValue,
                                    )}
                                </UniversitySubmitButton>
                            </Box>
                        </Stack>
                    </Box>
                )}
            </Form>
        </Dialog>
    );
};
