import React from "react";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/lib/OverlayTrigger";
import Tooltip from "react-bootstrap/lib/Tooltip";
import {CopyToClipboard} from "react-copy-to-clipboard";
import classNames from "classnames";
import s from "./ShareButton.pcss";
import {IconShare} from "../../../../widgets/modules/Icons/IconShare";

const linkCopiedToClipboardTooltip = (
    <Tooltip id="copy-to-clipboard-tooltip">Link copied to clipboard!</Tooltip>
);

function getColour(useLightTheme, primaryColor) {
    return useLightTheme ? "#ffffff" : primaryColor;
}

function getFontStyle(fontSize, colour) {
    return {
        fontSize: `${fontSize}px`,
        color: colour,
    };
}

const ShareButton = ({
    useLightTheme,
    primaryColor,
    fontSize,
    shareUrl,
    justifyRight,
    text,
    onCopy,
}) => {
    const colour = getColour(useLightTheme, primaryColor);
    const fontStyle = getFontStyle(fontSize, colour);

    return (
        <CopyToClipboard id="copy-to-clipboard" text={shareUrl} onCopy={onCopy}>
            <OverlayTrigger
                placement="bottom"
                trigger="click"
                rootClose
                overlay={linkCopiedToClipboardTooltip}
            >
                <button
                    aria-label="share-button"
                    id="share-button"
                    className={classNames(s.container, {[s.right]: justifyRight})}
                >
                    <IconShare width={24} height={24} aria-label="icon-share" />
                    <div className={s.text} style={fontStyle}>
                        {text}
                    </div>
                </button>
            </OverlayTrigger>
        </CopyToClipboard>
    );
};

ShareButton.propTypes = {
    text: PropTypes.string,
    primaryColor: PropTypes.string,
    useLightTheme: PropTypes.bool,
    justifyRight: PropTypes.bool,
    fontSize: PropTypes.number,
    onCopy: PropTypes.func.isRequired,
    shareUrl: PropTypes.string.isRequired,
};

ShareButton.defaultProps = {
    text: "Share",
    primaryColor: "ffffff",
    useLightTheme: false,
    justifyRight: false,
    isOnix: false,
    fontSize: 18,
    primaryApp: "university-jigglypuff",
};

export default ShareButton;
