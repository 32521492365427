import {Box} from "@unibuddy/patron";
import React from "react";

export const AppContainer = ({children, ...props}) => {
    return (
        <Box {...props} maxW="832px" margin="auto">
            {children}
        </Box>
    );
};
