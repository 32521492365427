import * as React from "react";
import {SVGProps} from "react";

export const IconInbox = (props: SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", width = "1em", height = "1em", ...rest} = props;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M12.0614 4.503C13.938 4.50157 15.748 5.19842 17.1391 6.45792C18.5302 7.71741 19.403 9.44945 19.5875 11.317C19.772 13.1845 19.2551 15.0538 18.1374 16.5612C17.0196 18.0686 15.381 19.1062 13.5404 19.472C13.0531 19.5673 12.5579 19.6155 12.0614 19.616C10.8965 19.6163 9.74758 19.3444 8.70638 18.822C8.50279 18.7199 8.27814 18.6668 8.05038 18.667C7.88846 18.6671 7.72766 18.6938 7.57438 18.746L5.01338 19.169L5.50138 16.743C5.61519 16.3639 5.57458 15.9553 5.38838 15.606C4.82496 14.5511 4.52016 13.3774 4.4991 12.1816C4.47804 10.9859 4.74131 9.8022 5.26723 8.72809C5.79316 7.65398 6.56669 6.72015 7.52415 6.0035C8.4816 5.28685 9.59559 4.80787 10.7744 4.606C11.1992 4.53608 11.6289 4.50063 12.0594 4.5L12.0614 4.503ZM12.0594 3C11.5478 3.00049 11.0372 3.04229 10.5324 3.125C9.11853 3.36577 7.78212 3.93905 6.63328 4.79759C5.48443 5.65614 4.55603 6.77538 3.92458 8.0631C3.29312 9.35082 2.97668 10.7701 3.00134 12.2041C3.02599 13.6381 3.39103 15.0458 4.06638 16.311H4.06038L3.40238 19.581C3.37277 19.7261 3.37574 19.8759 3.41107 20.0197C3.44641 20.1634 3.51322 20.2976 3.6067 20.4124C3.70017 20.5272 3.81797 20.6198 3.9516 20.6836C4.08522 20.7473 4.23133 20.7806 4.37938 20.781C4.43533 20.7807 4.49117 20.776 4.54638 20.767L8.06138 20.187L8.03838 20.165C9.28754 20.7895 10.6648 21.1151 12.0614 21.116C12.6538 21.1155 13.2449 21.0582 13.8264 20.945C16.0317 20.5064 17.995 19.2633 19.3346 17.4574C20.6741 15.6515 21.2941 13.4119 21.074 11.1742C20.8539 8.93654 19.8093 6.86072 18.1436 5.35046C16.4778 3.8402 14.3099 3.00347 12.0614 3.003L12.0594 3Z"
                fill={color}
            />
            <path
                d="M15.5043 11.292H8.60034C8.40143 11.292 8.21066 11.213 8.07001 11.0723C7.92936 10.9317 7.85034 10.7409 7.85034 10.542C7.85034 10.3431 7.92936 10.1523 8.07001 10.0117C8.21066 9.87101 8.40143 9.79199 8.60034 9.79199H15.5003C15.6993 9.79199 15.89 9.87101 16.0307 10.0117C16.1713 10.1523 16.2503 10.3431 16.2503 10.542C16.2503 10.7409 16.1713 10.9317 16.0307 11.0723C15.89 11.213 15.6993 11.292 15.5003 11.292H15.5043Z"
                fill={color}
            />
            <path
                d="M11.5313 14.3271H8.60034C8.40143 14.3271 8.21066 14.2481 8.07001 14.1075C7.92936 13.9668 7.85034 13.7761 7.85034 13.5771C7.85034 13.3782 7.92936 13.1875 8.07001 13.0468C8.21066 12.9062 8.40143 12.8271 8.60034 12.8271H11.5313C11.7303 12.8271 11.921 12.9062 12.0617 13.0468C12.2023 13.1875 12.2813 13.3782 12.2813 13.5771C12.2813 13.7761 12.2023 13.9668 12.0617 14.1075C11.921 14.2481 11.7303 14.3271 11.5313 14.3271V14.3271Z"
                fill={color}
            />
        </svg>
    );
};
