import * as React from "react";
import {SVGProps} from "react";

export const IconContent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", width = "1em", height = "1em", ...rest} = props;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M16.294 20.893H7.6C6.38 20.893 5.20998 20.4084 4.34731 19.5457C3.48464 18.683 3 17.513 3 16.293V7.6C3 6.38 3.48464 5.20998 4.34731 4.34731C5.20998 3.48464 6.38 3 7.6 3H13.356C13.5549 3 13.7457 3.07902 13.8863 3.21967C14.027 3.36032 14.106 3.55109 14.106 3.75C14.106 3.94891 14.027 4.13968 13.8863 4.28033C13.7457 4.42098 13.5549 4.5 13.356 4.5H7.6C6.77783 4.5 5.98933 4.82661 5.40797 5.40797C4.82661 5.98933 4.5 6.77783 4.5 7.6V16.293C4.5 17.1152 4.82661 17.9037 5.40797 18.485C5.98933 19.0664 6.77783 19.393 7.6 19.393H16.294C17.1162 19.393 17.9047 19.0664 18.486 18.485C19.0674 17.9037 19.394 17.1152 19.394 16.293V10.543C19.394 10.3441 19.473 10.1533 19.6137 10.0127C19.7543 9.87202 19.9451 9.793 20.144 9.793C20.3429 9.793 20.5337 9.87202 20.6743 10.0127C20.815 10.1533 20.894 10.3441 20.894 10.543V16.293C20.894 16.8971 20.775 17.4952 20.5438 18.0533C20.3127 18.6114 19.9738 19.1185 19.5467 19.5457C19.1195 19.9728 18.6124 20.3117 18.0543 20.5428C17.4962 20.774 16.8981 20.893 16.294 20.893Z"
                fill={color}
            />
            <path
                d="M16.9551 5.64874L18.2451 6.93874L11.3791 13.8077L10.0531 13.7887L10.0711 12.5337L16.9551 5.65073V5.64874ZM16.9551 3.92773C16.8286 3.92761 16.7034 3.95246 16.5865 4.00085C16.4696 4.04924 16.3635 4.12023 16.2741 4.20973L8.71811 11.7677C8.62935 11.8564 8.57875 11.9763 8.57711 12.1017L8.53911 14.7887C8.53819 14.852 8.54975 14.9148 8.57313 14.9736C8.59651 15.0324 8.63126 15.0859 8.67538 15.1313C8.71951 15.1766 8.77214 15.2128 8.83026 15.2377C8.88838 15.2627 8.95086 15.276 9.01411 15.2767L11.7891 15.3147H11.7951C11.9227 15.3147 12.045 15.264 12.1351 15.1737L19.6861 7.62274C19.7755 7.5334 19.8465 7.42731 19.8948 7.31055C19.9432 7.19378 19.9681 7.06863 19.9681 6.94223C19.9681 6.81584 19.9432 6.69068 19.8948 6.57392C19.8465 6.45716 19.7755 6.35107 19.6861 6.26173L17.6351 4.20973C17.5459 4.12026 17.4399 4.04928 17.3232 4.00089C17.2066 3.95249 17.0814 3.92763 16.9551 3.92773V3.92773Z"
                fill={color}
            />
        </svg>
    );
};
