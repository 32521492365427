import {PatronThemeProvider} from "@unibuddy/patron";
import React from "react";

import useUniversityColor from "../../widgets/modules/UniversityProvider/hooks/useUniversityColor";

export function ThemeProviderWrapper({children}) {
    const universityColor = useUniversityColor();
    const universityHoverColor = `${universityColor}D9`; // E6 is 90%, D9 is 85%, CC is 80%, BF is 75%
    const universityClearHoverColor = `${universityColor}33`; // 1A is 10%, 26 is 15%, 33 is 20%, 40 is 25%

    const theme = {
        colors: {
            universityColor,
            universityHoverColor,
            universityClearHoverColor,
        },
        Button: {
            colors: {
                university: {
                    color: "white",
                    bgColor: universityColor,
                    borderColor: universityColor,
                    hoverBgColor: universityHoverColor,
                    outlineColor: universityHoverColor,
                    clearColor: universityColor,
                    clearHoverBgColor: universityClearHoverColor,
                },
            },
        },
    };

    return <PatronThemeProvider theme={theme}>{children}</PatronThemeProvider>;
}
