import {IntlKeyValuePair, IntlNamespaces} from "./types";

export const INTL_GATHERING_INFORMATION: IntlKeyValuePair = {
    key: "gatheringInformation",
    defaultValue: "Browsing all institutions",
    intlValue: "Browsing all institutions",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_COMPARING_OPTIONS: IntlKeyValuePair = {
    key: "comparingOptions",
    defaultValue: "Comparing institutions",
    intlValue: "Comparing institutions",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NARROWED_DOWN: IntlKeyValuePair = {
    key: "narrowedDown",
    defaultValue: "Narrowing down options",
    intlValue: "Narrowing down options",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_PREPARING: IntlKeyValuePair = {
    key: "preparing",
    defaultValue: "Preparing applications",
    intlValue: "Preparing applications",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_SUBMITTED: IntlKeyValuePair = {
    key: "submitted",
    defaultValue: "Waiting for offers",
    intlValue: "Waiting for offers",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_ADMITTED: IntlKeyValuePair = {
    key: "admitted",
    defaultValue: "Choosing between offers",
    intlValue: "Choosing between offers",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_POPUP_TITLE: IntlKeyValuePair = {
    key: "popupTitle",
    defaultValue: "I'm currently...",
    intlValue: "I'm currently...",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_POPUP_SKIP_BUTTON: IntlKeyValuePair = {
    key: "popupSkipButton",
    defaultValue: "Skip",
    intlValue: "Skip",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_POPUP_SUBMIT_BUTTON: IntlKeyValuePair = {
    key: "popupSubmitButton",
    defaultValue: "Submit",
    intlValue: "Submit",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_VIEW_RECOMMENDATIONS: IntlKeyValuePair = {
    key: "viewRecommendations",
    defaultValue: "View recommendations",
    intlValue: "View recommendations",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_DECISION_STAGE_PAGE_TITLE: IntlKeyValuePair = {
    key: "decisionStagePageTitle",
    defaultValue: "Decision Stage",
    intlValue: "Decision Stage",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_DECISION_STAGE_PAGE_SUBTITLE: IntlKeyValuePair = {
    key: "decisionStagePageSubtitle",
    defaultValue: "Update your decision stage for personalised recommendations",
    intlValue: "Update your decision stage for personalised recommendations",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NONE_OF_THE_ABOVE: IntlKeyValuePair = {
    key: "noneOfTheAbove",
    defaultValue: "None of the above",
    intlValue: "None of the above",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_TITLE: IntlKeyValuePair = {
    key: "notInterestedTitle",
    defaultValue: "Not Interested",
    intlValue: "Not Interested",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_SUBTITLE: IntlKeyValuePair = {
    key: "notInterestedSubtitle",
    defaultValue: "Please let us know why you're not interested so we can improve your experience!",
    intlValue: "Please let us know why you're not interested so we can improve your experience!",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_DROPDOWN_LABEL: IntlKeyValuePair = {
    key: "notInterestedDropdownLabel",
    defaultValue: "Reason",
    intlValue: "Reason",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_IN_APPLYING: IntlKeyValuePair = {
    key: "notInterestedInApplying",
    defaultValue: "Not interested in applying to this institution",
    intlValue: "Not interested in applying to this institution",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_IN_UPDATES: IntlKeyValuePair = {
    key: "notInterestedInUpdates",
    defaultValue: "Not interested in updates from this institution",
    intlValue: "Not interested in updates from this institution",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_OTHER: IntlKeyValuePair = {
    key: "notInterestedOther",
    defaultValue: "Other",
    intlValue: "Other",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_SUBMIT: IntlKeyValuePair = {
    key: "notInterestedSubmit",
    defaultValue: "Submit response",
    intlValue: "Submit response",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_FEEDBACK_MESSAGE: IntlKeyValuePair = {
    key: "notInterestedFeedbackMessage",
    defaultValue: "Thank you for your feedback!",
    intlValue: "Thank you for your feedback!",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_REASON_VALIDATION: IntlKeyValuePair = {
    key: "notInterestedReasonValidation",
    defaultValue: "Reason is a required field",
    intlValue: "Reason is a required field",
    namespace: IntlNamespaces.DECISION_STAGE,
};

export const INTL_NOT_INTERESTED_SUBMIT_FAILED: IntlKeyValuePair = {
    key: "notInterestedSubmitFailed",
    defaultValue: "Something went wrong, please try again later",
    intlValue: "Something went wrong, please try again later",
    namespace: IntlNamespaces.DECISION_STAGE,
};
