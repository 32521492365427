import React, {useEffect, useRef, useState} from "react";
import {PropTypes} from "prop-types";
import getDynamicImageUrl from "./getDynamicImageUrl";

const DynamicImage = ({options, alt, src, ...props}) => {
    const ref = useRef();
    const onScreen = useOnScreen(ref);
    return (
        <img
            {...props}
            alt={alt}
            src={onScreen ? getDynamicImageUrl(src, options) : null}
            ref={ref}
            aria-label={alt}
        />
    );
};

export function useOnScreen(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) => {
        if (!isIntersecting && entry.isIntersecting) {
            setIntersecting(entry.isIntersecting);
        }
    });

    useEffect(() => {
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [observer, ref]);

    return isIntersecting;
}

DynamicImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    options: PropTypes.shape({
        crop: PropTypes.bool,
        width: PropTypes.number,
        smart: PropTypes.bool,
        height: PropTypes.number,
        grayscale: PropTypes.bool,
    }).isRequired,
};

export default DynamicImage;
