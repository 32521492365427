import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import {Helmet} from "react-helmet";
import {getenv} from "../../../../../util/getEnv";

const MentorProfileMeta = ({mentor}) => {
    const DEFAULT_PROFILE_PHOTO = getenv("DEFAULT_PROFILE_PHOTO");
    const firstName = get(mentor, "firstName", "");
    const year = get(mentor, "year", "");
    const degree = get(mentor, "degree.name", "");
    const metaDescription = `${firstName} is a ${year} ${degree} student.`;
    return (
        <Helmet>
            <meta content={get(mentor, "shareUrl", "")} property="og:url" />
            <meta property="og:type" content="article" />
            <meta
                property="og:title"
                content={`${get(mentor, "firstName", "Buddy")}'s Profile - Chat Now`}
            />
            <meta property="og:description" content={metaDescription} />
            <meta
                property="og:image"
                content={get(mentor, "profilePhoto", DEFAULT_PROFILE_PHOTO)}
            />
        </Helmet>
    );
};

MentorProfileMeta.propTypes = {
    mentor: PropTypes.object,
};

MentorProfileMeta.defaultProps = {
    mentor: {},
};

export default MentorProfileMeta;
