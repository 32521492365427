import React from "react";
import PropTypes from "prop-types";
import {Trans, useTranslation} from "@unibuddy/intl";
import {Box, Button, Inline, RequiredFormIndicator, Stack, TextBlock} from "@unibuddy/patron";
import s from "./AlreadyHaveAccount.pcss";
import ThemedLink from "../../../Navigation/components/ThemedLink/ThemedLink";
import {IntlNamespaces} from "../../../../translations/types";
import {INTL_REQUIRED_FIELD_INFO} from "../../../../translations/common";
import {INTL_LOGIN} from "../../../../translations/signup";

const AlreadyHaveAccount = (props) => {
    const {t} = useTranslation([IntlNamespaces.SIGNUP, IntlNamespaces.COMMON]);

    return (
        <p className={s.text}>
            {props.onClick ? (
                <Box paddingX={["small", "xxlarge"]}>
                    <Stack space={"small"}>
                        {t("alreadyHaveAnAccount", "Already have an account?")}
                        <Button color={"primary"} onClick={props.onClick} block>
                            {t(INTL_LOGIN.key, INTL_LOGIN.defaultValue)}
                        </Button>
                    </Stack>
                </Box>
            ) : (
                <Stack space={"xxsmall"}>
                    <Inline align={"center"}>
                        {t("alreadyHaveAnAccount", "Already have an account?")}&nbsp;
                        <ThemedLink
                            to={{
                                pathname: props.pathname,
                                state: {nextPathname: props.nextPathname},
                                search: props.search,
                            }}
                        >
                            {t(INTL_LOGIN.key, INTL_LOGIN.defaultValue)}
                        </ThemedLink>
                    </Inline>
                    <TextBlock size={"xsmall"} align={"center"}>
                        <Trans
                            t={t}
                            i18nKey={`${IntlNamespaces.COMMON}:${INTL_REQUIRED_FIELD_INFO.key}`}
                        >
                            {"All fields marked with a red asterisk"}
                            <RequiredFormIndicator />
                            {"need to be filled in"}
                        </Trans>
                    </TextBlock>
                </Stack>
            )}
        </p>
    );
};

AlreadyHaveAccount.propTypes = {
    pathname: PropTypes.string,
    nextPathname: PropTypes.string,
    search: PropTypes.string,
    onClick: PropTypes.func,
};

export default AlreadyHaveAccount;
