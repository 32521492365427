import moment from "moment";
import PropTypes from "prop-types";
import {format} from "date-fns";

export const SECONDS = 1000;
export const MINUTES = 60 * SECONDS;
export const HOURS = 60 * MINUTES;
export const DAYS = 24 * HOURS;

export const UNITS = {
    SECONDS: "SECONDS",
    MINUTES: "MINUTES",
    HOURS: "HOURS",
    DAYS: "DAYS",
};

export const datePropTypes = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
]);

/**
 * Converts a given UTC time to the local time.
 *
 * We need this to convert the time we get from the server (UTC time) to the correct format on
 * all clients.
 *
 * For tips on how to test this behaviour in a real browser check out:
 * https://stackoverflow.com/a/28398154/8362805
 *
 * @param utcTime {string} The UTC time to convert as a string(i.e. server time)
 * @returns {import("moment").Moment}
 */
export function getLocalTime(utcTime) {
    // moment.utc(...) tells moment that the given string is UTC time - just doing moment(...)
    // gives the input time *as* the local time, without adjusting for the timezone
    return moment.utc(utcTime).local();
}

/***
 * Check if two times are from same day
 * @param time1
 * @param time2
 * @return {boolean|*}
 */
export function areSameDayTimes(time1, time2) {
    if (!time1 || !time2) {
        return false;
    }

    const time1Locale = getLocalTime(time1);
    const time2Locale = getLocalTime(time2);

    if (!time1Locale.isValid() || !time2Locale.isValid()) {
        return false;
    }

    return time1Locale.isSame(time2Locale, "day");
}

/**
 * Converts a given local time to UTC time.
 *
 * We need this to convert the local time we get from the client (local time) to the UTC
 * time that we use on server.
 *
 * For tips on how to test this behaviour in a real browser check out:
 * https://stackoverflow.com/a/28398154/8362805
 *
 * @param localTime {string} The UTC time to convert as a string(i.e. server time)
 * @returns {Moment}
 */
export function getServerTime(localTime) {
    // moment(...) tells moment that the given string is local time
    return moment(localTime).utc().format("DD/MM/YYYY HH:mm");
}

/**
 * Calculates the time between the later time and earlier time, rounded down to the nearest unit
 * @param laterTime {string|Moment}
 * @param earlierTime {string|Date|Moment}
 * @returns {{value: number, units: string}}
 */
export function getTimeSince(laterTime, earlierTime) {
    const diff = moment.duration(getLocalTime(earlierTime).diff(getLocalTime(laterTime)));
    let value;
    let units;

    if (diff.asMinutes() < 1) {
        value = diff.asSeconds();
        units = UNITS.SECONDS;
    } else if (diff.asHours() < 1) {
        value = diff.asMinutes();
        units = UNITS.MINUTES;
    } else if (diff.asDays() < 1) {
        value = diff.asHours();
        units = UNITS.HOURS;
    } else {
        value = diff.asDays();
        units = UNITS.DAYS;
    }

    return {value: Math.floor(value), units};
}

/**
 * Converts a UNIT into a short name for displaying
 * @param unit
 * @returns {string}
 */
export function getShortName(unit) {
    switch (unit) {
        case UNITS.SECONDS:
            return "s";
        case UNITS.MINUTES:
            return "m";
        case UNITS.HOURS:
            return "h";
        default:
            return "d";
    }
}

export function isInPast(date, now = moment()) {
    return now.isSameOrAfter(date);
}

export function isInFuture(date, now = moment()) {
    return now.isSameOrBefore(date);
}

export function isCurrentlyBetween(start, end, now = moment()) {
    return isInPast(start, now) && isInFuture(end, now);
}

export function getTimestamp(date) {
    return Number(date.format("x"));
}

export function getFormattedDate(timestamp) {
    return format(new Date(timestamp), "do MMMM yyyy");
}
