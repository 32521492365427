import React, {Component, useEffect} from "react";
import get from "lodash/get";
import compose from "lodash/flowRight";
import {graphql} from "react-apollo";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "@unibuddy/intl";

import {getParameterByName} from "shared/Utils/UrlUtils";
import MentorPlaceholder from "shared/Mentor/components/MentorPlaceholder/MentorPlaceholder";
import ProgressBar from "shared/General/components/ProgressBar/ProgressBar";
import RecordAnalytics from "shared/Analytics/RecordAnalytics";
import withAuth from "shared/Auth/withAuth/withAuth";
import BackArrow from "shared/General/components/BackButton/BackButton";
import {isFeatureEnabled} from "shared/Utils/FeatureUtils";
import {isCharmander, isJiggly, rootURL} from "shared/Utils/WhereAmI";
import {withUniversityTheme} from "shared/Styling/hocs/withTheme";
import PageHeader from "shared/Shared/components/PageHeader/PageHeader";
import withAnalytics from "shared/AnalyticsNew/withAnalytics/withAnalytics";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import MentorCard from "shared/Mentor/components/MentorCard/MentorCard";
import MentorProfileQuery from "shared/Mentor/queries/MentorProfileQuery";

import {PageTitle} from "../../../../../components/PageTitle/PageTitle";
import MentorProfileMeta from "../../components/MentorProfileMeta/MentorProfileMeta";
import {AmbassadorRemovedCard} from "../../../../shared/components/AmbassadorRemovedCard/AmbassadorRemovedCard";
import {hasAmbassadorRemovedError} from "../../../../shared/components/AmbassadorRemovedCard/helpers";
import {getenv} from "../../../../../util/getEnv";
import s from "./MentorProfile.pcss";

const MentorCardWrapper = ({buddyType, buddyPosition, buddyId, children, universitySlug}) => {
    const {
        events: {buddyViewed},
    } = useAnalytics();
    useEffect(() => {
        buddyViewed({
            id: buddyId,
            buddyType,
            buddyPosition,
            universitySlug,
        });
    }, [buddyViewed, buddyId, buddyType, buddyPosition, universitySlug]);

    return <React.Fragment>{children}</React.Fragment>;
};

export class MentorProfilePage extends Component {
    componentDidMount() {
        RecordAnalytics.page(this.props, "Mentor Profile Page"); // Legacy Event
    }

    onSelectPost(postId) {
        RecordAnalytics.event(this.props, "Buddy Profile Blog Post Clicked", {postId});
    }

    onChatNow = () => {
        const mentorId = this.props.match.params.buddyId;
        RecordAnalytics.event(this.props, "Buddy Profile Chat Now Clicked", {mentorId});
        this.props.analytics.events.itemClicked({
            prefix: this.props.analytics.itemNames.buddyProfileChatNow,
            id: mentorId,
        });

        const buddyPosition = this.props.location.search;
        const existingParams = new URLSearchParams(window.location.search);
        const buddyParams = new URLSearchParams(buddyPosition.replace("?", ""));
        existingParams.set("buddyPosition", buddyParams.get("buddyPosition") ?? "");

        this.props.history.push(
            `${rootURL(this.props.location.pathname)}/inbox/chatwith/${mentorId}?${existingParams}`,
        );
    };

    onShare = () => {
        this.props.analytics.events.buddyShared({id: this.props.match.params.buddyId});
    };

    onBack = () => {
        this.props.history.push(".");
    };

    getShareUrl = () => {
        let commonShareUrl = get(this.props.mentor, "shareUrl", "");
        const buddyId = this?.props?.mentor?.id;
        const buddyGroupType = get(this.props.match, "params.buddyGroupType", "students");

        /**
         * FIXME:
         * While creative, this is not a robust solution.
         */
        if (
            this?.props?.i18n?.language?.toLowerCase() === "cy-gb" &&
            this?.props?.universitySlug === "swansea-university" &&
            buddyId
        ) {
            const url = "https://www.swansea.ac.uk/cy/astudio/bywyd-myfyriwr/sgwrsio-llysgennad/";
            return `${url}?unibuddy=buddies/${buddyGroupType}/${buddyId}`;
        }

        if (commonShareUrl.includes("buddies")) {
            commonShareUrl = commonShareUrl.replace("buddies", `buddies/${buddyGroupType}`);
        }
        return `${commonShareUrl}?buddyPosition=share`;
    };

    render() {
        const id = `mentor-${get(this.props, "match.params.buddyId", "")}-container`;
        const finishedLoading = !get(this.props, "loading", false);
        const showChatNow =
            get(this.props.mentor, "active", false) &&
            (isJiggly(this.props.location.pathname) || isCharmander(this.props.location.pathname));
        const DEFAULT_PROFILE_PHOTO = getenv("DEFAULT_PROFILE_PHOTO");

        if (finishedLoading && !this.props.mentor && hasAmbassadorRemovedError(this.props.error)) {
            return (
                <div id={id}>
                    <AmbassadorRemovedCard
                        university={this.props.university}
                        onBack={this.onBack}
                    />
                </div>
            );
        }

        return (
            <div id="mentor-profile-page">
                <PageTitle title={`${get(this.props.mentor, "firstName", "Buddy")}'s Profile`} />
                <ProgressBar start={this.props.loading} />
                <div id={id}>
                    {finishedLoading && (
                        <PageHeader
                            title={this.props.t("aboutName", {
                                name: get(this.props.mentor, "firstName"),
                            })}
                            leftContent={
                                <BackArrow
                                    text={this.props.t("allBuddies", "All Buddies")}
                                    onBack={this.onBack}
                                    label={this.props.t("allBuddies", "All Buddies")}
                                />
                            }
                        />
                    )}
                    <div className={s.content}>
                        {finishedLoading ? (
                            <MentorCardWrapper
                                buddyType={
                                    get(this.props, "mentor.degreeLevel") === "Alumni"
                                        ? "alumni"
                                        : get(this.props, "mentor.accountRole")
                                }
                                buddyPosition={getParameterByName(
                                    this.props.location.search,
                                    "buddyPosition",
                                )}
                                buddyId={this.props.match.params.buddyId}
                                universitySlug={get(this.props.match, "params.universitySlug")}
                            >
                                <MentorCard
                                    universitySlug={get(this.props.match, "params.universitySlug")}
                                    className={s.mentorCard}
                                    mentorId={get(this.props.mentor, "id")}
                                    profilePhoto={get(
                                        this.props.mentor,
                                        "profilePhoto",
                                        DEFAULT_PROFILE_PHOTO,
                                    )}
                                    firstName={get(this.props.mentor, "firstName", "")}
                                    degreeName={get(this.props.mentor, "degree.name", "")}
                                    degrees={get(this.props.mentor, "degrees", [])}
                                    degreeLevel={get(this.props.mentor, "degreeLevel", "") || ""}
                                    placeLabel={get(this.props.mentor, "placeName", "")}
                                    countryCode={get(this.props.mentor, "country.code", "")}
                                    primaryFlag={get(
                                        this.props.mentor,
                                        "mentorFields.primaryFlag.code",
                                        "",
                                    )}
                                    secondaryFlag={get(
                                        this.props.mentor,
                                        "mentorFields.secondaryFlag.code",
                                        "",
                                    )}
                                    languages={get(this.props.mentor, "languages", [])}
                                    year={get(this.props.mentor, "year", "")}
                                    favouriteModules={get(
                                        this.props.mentor,
                                        "favouriteModules",
                                        [],
                                    )}
                                    pastQualification={get(
                                        this.props.mentor,
                                        "pastQualification",
                                        "",
                                    )}
                                    interests={get(this.props.mentor, "interests", [])}
                                    societies={get(this.props.mentor, "societies", [])}
                                    customAttributes={get(
                                        this.props.mentor,
                                        "customAttributes",
                                        [],
                                    )}
                                    bio={get(this.props.mentor, "bio", "")}
                                    blogPosts={get(this.props, "posts", [])}
                                    baseUrl={rootURL(this.props.location.pathname)}
                                    onChatNow={this.onChatNow.bind(this)}
                                    onSelectPost={this.onSelectPost.bind(this)}
                                    showChatNow={showChatNow}
                                    shareUrl={this.getShareUrl()}
                                    onShare={this.onShare.bind(this)}
                                    showShareButton
                                    university={this.props.university}
                                />
                            </MentorCardWrapper>
                        ) : (
                            <MentorPlaceholder className={s.mentorCard} wide />
                        )}
                    </div>
                </div>
                {finishedLoading && <MentorProfileMeta mentor={this.props.mentor} />}
            </div>
        );
    }
}

// Set query options for all countries
const MentorProfileQueryOptions = {
    name: "mentorProfile",
    // options for the initial query
    options(props) {
        const isDegreeTypesEnabled = isFeatureEnabled("enableDegreeTypes", props.university);
        const isBlogsEnabled = isFeatureEnabled("userGeneratedContent", props.university);
        return {
            variables: {
                id: props.match.params.buddyId,
                isDegreeTypesEnabled,
                isBlogsEnabled,
            },
            fetchPolicy: "cache-and-network",
        };
    },
    // convert the ugly apollo results into custom props
    props({mentorProfile: {loading, publicMentor, mentorPosts, refetch, error}}) {
        return {
            loading,
            mentor: publicMentor,
            posts: mentorPosts,
            refetch,
            error,
        };
    },
};

export default compose(
    withRouter,
    connect(),
    withAuth,
    withAnalytics,
    withTranslation("mentorCard"),
    graphql(MentorProfileQuery, MentorProfileQueryOptions),
    withUniversityTheme(),
)(MentorProfilePage);
