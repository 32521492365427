import mixpanel from "mixpanel-browser";
import get from "lodash/get";
import SourceTrackingProvider from "../SourceTracking/SourceTrackingProvider/SourceTrackingProvider";
import {sourceTracking} from "../AnalyticsNew/constants/productSpecification";
import getAppName from "../Analytics/getAppName";
import getTraits from "../Analytics/getTraits/getTraits";
import {getenv} from "../../util/getEnv";

/**
 Checks if mixpanel is initialised or not in the application.
 @return True if mixpanel is initialised else False
 */
const isMixpanelInitialised = () => get(mixpanel, "config.api_host") !== undefined;
/**
 * This is a deprecated analytics class, use AnalyticsNew in machop
 * by injecting the AnalyticsProvider context into an app
 */
export default class RecordAnalytics {
    static init() {
        const MIXPANEL_ID = getenv("MIXPANEL_ID");
        mixpanel.init(MIXPANEL_ID);
    }

    static page(props, name, includeSlug = true) {
        /** Before calling mixpanel.track check if mixpanel is initialised or not.
         * Initialisation of mixpanel depends upon the user cookie consent.
         */
        if (!isMixpanelInitialised()) return;
        const appName = getAppName(get(props, "primaryApp", null));
        const pageName = `Viewed ${name} - ${appName} Page`;
        let properties =
            appName !== "Ambassador Dashboard" && includeSlug ? {search: props.universitySlug} : {};

        const sourceTrackingParams = SourceTrackingProvider.getSourceTrackingParams();

        properties = {
            ...properties,
            ub_medium:
                sourceTrackingParams[sourceTracking.sourceTrackingParams.properties.UB_MEDIUM],
            ub_source:
                sourceTrackingParams[sourceTracking.sourceTrackingParams.properties.UB_SOURCE],
            ub_campaign:
                sourceTrackingParams[sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN],
            ub_content:
                sourceTrackingParams[sourceTracking.sourceTrackingParams.properties.UB_CONTENT],
        };

        try {
            mixpanel.track(pageName, properties);
        } catch (e) {
            console.error(e);
        }
    }

    static event(props, name, moreProps = {}, includeSlug = true) {
        /** Before calling mixpanel.track check if mixpanel is initialised or not.
         * Initialisation of mixpanel depends upon the user cookie consent.
         */
        if (!isMixpanelInitialised()) return;
        const appName = getAppName(get(props, "primaryApp", null));
        let properties =
            appName !== "Ambassador Dashboard" && includeSlug
                ? {...moreProps, uniSlug: props.universitySlug}
                : {...moreProps};

        const sourceTrackingParams = SourceTrackingProvider.getSourceTrackingParams();

        properties = {
            ...properties,
            ub_medium:
                sourceTrackingParams[sourceTracking.sourceTrackingParams.properties.UB_MEDIUM],
            ub_source:
                sourceTrackingParams[sourceTracking.sourceTrackingParams.properties.UB_SOURCE],
            ub_campaign:
                sourceTrackingParams[sourceTracking.sourceTrackingParams.properties.UB_CAMPAIGN],
            ub_content:
                sourceTrackingParams[sourceTracking.sourceTrackingParams.properties.UB_CONTENT],
        };

        try {
            mixpanel.track(name, properties);
        } catch (e) {
            console.error(e);
        }
    }

    static identify(me, withAlias = false) {
        /** Before calling mixpanel.people.set and mixpanel.identify check if mixpanel is initialised or not.
         * Initialisation of mixpanel depends upon the user cookie consent.
         */
        if (!isMixpanelInitialised()) return;
        if (me) {
            if (withAlias) mixpanel.alias(me.anyUser.id);
            try {
                mixpanel.people.set(getTraits(me));
                mixpanel.identify(me.anyUser.id);
            } catch (e) {
                console.error(e);
            }
        }
    }

    static clear() {
        /** Before calling mixpanel.reset check if mixpanel is initialised or not.
         * Initialisation of mixpanel depends upon the user cookie consent.
         */
        if (!isMixpanelInitialised()) return;
        try {
            mixpanel.reset();
        } catch (e) {
            console.error(e);
        }
    }

    static trackEvent(name, props) {
        if (!isMixpanelInitialised()) return;

        try {
            mixpanel.track(name, props);
        } catch (e) {
            console.error(e);
        }
    }
}
