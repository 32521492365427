import PropTypes from "prop-types";
import React from "react";
import s from "./ErrorMessage.pcss";

const ErrorMessage = (props) => (
    <div className={s.container} aria-live={"polite"}>
        {props.children}
    </div>
);

ErrorMessage.propTypes = {
    children: PropTypes.node.isRequired,
};

export const ServerErrorMessage = () => (
    <ErrorMessage>
        Woops! Something went wrong - please get in touch with us and we will get on it.
    </ErrorMessage>
);

export default ErrorMessage;
