import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./EmptyState.pcss";

const EmptyState = (props) => (
    <div className={s.container}>
        {props.image
            ? React.cloneElement(props.image, {
                  className: classNames(props.image.props.className, s.image),
              })
            : null}
        <div className={s.body}>
            <h2>{props.title}</h2>
            {props.children ? props.children : <p>{props.text}</p>}
        </div>

        <div className={s.actionBar}>{props.actionButton}</div>
    </div>
);

EmptyState.propTypes = {
    image: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    actionButton: PropTypes.node,
};

EmptyState.defaultProps = {
    image: "",
    title: "",
    text: "",
    actionButton: null,
};

export default EmptyState;
