import * as React from "react";
import {SVGProps} from "react";

const IconChevronRight = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", ...rest} = props;
    return (
        <svg
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M1.731 9.38A.751.751 0 0 1 1.2 8.099L4.21 5.07l-3-2.98a.75.75 0 1 1 1.057-1.063l3.536 3.507a.748.748 0 0 1 0 1.06L2.266 9.159a.751.751 0 0 1-.535.222Z"
                fill={color}
            />
        </svg>
    );
};

export default IconChevronRight;
