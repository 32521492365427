import React from "react";
import {View} from "@unibuddy/patron";

type CardProps = {
    activityStatus?: React.ReactNode;
    children: React.ReactNode;
};

export const Card = ({children, activityStatus}: CardProps) => {
    return (
        <View
            position="relative"
            borderRadius="small-medium"
            overflow="hidden"
            bgColor="white"
            borderWidth={1}
            borderColor="grey150"
            w="100%"
        >
            {activityStatus}
            {children}
        </View>
    );
};
