import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./CenteredImage.pcss";
import DynamicImage from "../DynamicImage/DynamicImage";

const CenteredImage = (props) => (
    <div className={classNames(props.className, s.imageContainer)} data-test-id={"pop-card-img"}>
        <DynamicImage
            src={props.src}
            alt={props.alt}
            options={{
                width: 100,
                height: 100,
                smart: true,
            }}
            className={s.image}
        />
    </div>
);

CenteredImage.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

CenteredImage.defaultProps = {
    className: "",
};

export default CenteredImage;
