import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

const SingleChoiceAttributeView = ({attributeValue}) => (
    <span>{get(attributeValue, "option.value")}</span>
);

SingleChoiceAttributeView.propTypes = {
    attributeValue: PropTypes.object,
};

SingleChoiceAttributeView.defaultProps = {
    attributeValue: {},
};

export default SingleChoiceAttributeView;
