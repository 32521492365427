export const enum WIDGET_PAGE {
    UNKNOWN = "UNKNOWN",
    WIDGET_SIGNUP = "WIDGET_SIGNUP",
    WIDGET_STUDENTS = "WIDGET_STUDENTS",
    WIDGET_STAFF = "WIDGET_STAFF",
    WIDGET_CONTENT = "WIDGET_CONTENT",
    WIDGET_INBOX = "WIDGET_INBOX",
    WIDGET_SETTINGS = "WIDGET_SETTINGS",
    WIDGET_DECISION_STAGE = "WIDGET_DECISION_STAGE",
    WIDGET_RECOMMENDATIONS = "WIDGET_RECOMMENDATIONS",
    WIDGET_NOT_INTERESTED = "WIDGET_NOT_INTERESTED",
    WIDGET_LOG_IN = "WIDGET_LOG_IN",
    WIDGET_LOG_OUT = "WIDGET_LOG_OUT",
    WIDGET_RESET_PASSWORD = "WIDGET_RESET_PASSWORD",
    WIDGET_INTRO = "WIDGET_INTRO",
}
