import gql from "graphql-tag";

export default gql`
    fragment MentorFieldsFull on MentorFields {
        primaryFlag {
            name
            code
        }
        secondaryFlag {
            name
            code
        }
    }
`;
