import React from "react";
import PropTypes from "prop-types";
// polyfill for IE11 where xlink:href does not work
import "svgxuse";

const Icon = ({width, height, fillColour, strokeColour, className, name, style, ...rest}) => {
    const baseStyle = {
        width: `${width}px`,
        height: `${height}px`,
    };

    if (fillColour) {
        baseStyle.fill = fillColour;
    }

    if (strokeColour) {
        baseStyle.stroke = strokeColour;
    }

    return (
        <svg style={{...baseStyle, ...style}} className={className} {...rest}>
            <use xlinkHref={`/icons.svg#${name}`} />
        </svg>
    );
};

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    fillColour: PropTypes.string,
    strokeColour: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
};

Icon.defaultProps = {
    className: "",
    fillColour: undefined,
    strokeColour: undefined,
    width: 24,
    height: 24,
    style: {},
};

export default Icon;
