import * as React from "react";
import {SVGProps} from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", ...rest} = props;
    return (
        <svg
            width={17}
            height={17}
            fill="none"
            viewBox="0 0 17 17"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M8.012 8.062A3.313 3.313 0 0 1 4.689 4.74a.5.5 0 0 1 1 0 2.323 2.323 0 0 0 4.644 0 .5.5 0 0 1 1 0 3.322 3.322 0 0 1-3.321 3.322ZM9.385 10.327a2.333 2.333 0 0 1 2.334 2.333v.717a.333.333 0 0 1-.334.334H4.72a.333.333 0 0 1-.334-.334v-.717a2.333 2.333 0 0 1 2.334-2.333h2.666Zm0-1H6.72a3.333 3.333 0 0 0-3.334 3.333v.717a1.333 1.333 0 0 0 1.334 1.334h6.666a1.333 1.333 0 0 0 1.334-1.334v-.717a3.333 3.333 0 0 0-3.334-3.333ZM8.012 1.667c.13 0 .26.015.386.045l2.313.551-2.302.564a1.671 1.671 0 0 1-.796 0l-2.3-.564 2.312-.551a1.68 1.68 0 0 1 .387-.045Zm0-1c-.208 0-.416.024-.619.072L2.275 1.96a.302.302 0 0 0 0 .587L7.381 3.8c.418.102.854.102 1.272 0l5.106-1.252a.302.302 0 0 0 0-.588L8.637.74A2.654 2.654 0 0 0 8.02.667h-.007Z"
                fill={color}
            />
            <path
                d="M2.5 6.324a.5.5 0 0 1-.5-.5V2.417a.5.5 0 0 1 1 0v3.407a.5.5 0 0 1-.5.5Z"
                fill={color}
            />
        </svg>
    );
};

export default SvgComponent;
