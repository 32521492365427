import React from "react";
import {Heading, Stack, Text} from "@unibuddy/patron";

export function IntroPageHeader() {
    return (
        <Stack space="gutter">
            <Heading size="large" level="2">
                Find the right student to chat to
            </Heading>
            <Text size="large">
                Chat with our existing students to get authentic perspectives, firsthand insights,
                tailored advice, and answers to your specific questions.
            </Text>
        </Stack>
    );
}
