import React from "react";
import Flag from "shared/Buddies/components/Flag/Flag";
import {isFeatureEnabled} from "shared/Utils/FeatureUtils";
import {CountryField, MentorFields, UniversityField} from "../../../../../types/generated";

type CountryTypes = {
    university: UniversityField;
    country: CountryField;
    mentorFields: MentorFields;
    placeName: string;
};

export const Country = ({university, country, mentorFields, placeName}: CountryTypes) => {
    const isShowCountryFlagEnabled = isFeatureEnabled("showCountryFlag", university);
    const primaryFlag = mentorFields?.primaryFlag?.code ?? "";
    const secondaryFlag = mentorFields?.secondaryFlag?.code ?? "";

    return (
        <>
            {placeName}
            &nbsp;
            {isShowCountryFlagEnabled && (
                <Flag
                    countryCode={country?.code ?? ""}
                    primaryFlag={primaryFlag}
                    secondaryFlag={secondaryFlag}
                    className={undefined}
                    position={undefined}
                />
            )}
        </>
    );
};
