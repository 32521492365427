import React from "react";
import styled from "styled-components";
import {
    Box,
    Form,
    FormGroup,
    Select,
    SubmitButton,
    Text,
    Stack,
    ErrorMessage,
} from "@unibuddy/patron";
import {useTranslation} from "@unibuddy/intl";
import {string} from "yup";
import {FormikHelpers} from "formik";

import {NotInterestedReasonOptions} from "../NotInterested";
import useUniversityColor from "../../UniversityProvider/hooks/useUniversityColor";
import {IntlNamespaces} from "../../../../translations/types";
import {
    INTL_NOT_INTERESTED_DROPDOWN_LABEL,
    INTL_NOT_INTERESTED_REASON_VALIDATION,
    INTL_NOT_INTERESTED_SUBMIT,
    INTL_NOT_INTERESTED_SUBMIT_FAILED,
} from "../../../../translations/decisionStage";

export type FormValues = {
    reason: string;
    serverError: string;
};
type NotInterestedFormProps = {
    handleSubmit: (values: FormValues) => Promise<void>;
};

const StyledSubmitButton = styled(SubmitButton)<{buttonColor: string}>`
    color: white;
    border-color: ${(props) => props.buttonColor ?? "#0171E0"} !important;
    background-color: ${(props) => props.buttonColor ?? "#0171E0"};
    &:hover:not([disabled]) {
        color: white;
        background-color: ${(props) => props.buttonColor ?? "#0171E0"};
        border-color: ${(props) => props.buttonColor ?? "#0171E0"} !important;
        opacity: 0.85;
    }
`;

export const NotInterestedForm = ({handleSubmit}: NotInterestedFormProps) => {
    const universityColor = useUniversityColor();
    const {t} = useTranslation(IntlNamespaces.DECISION_STAGE);

    return (
        <Form
            onSubmit={async (values: FormValues, formActions: FormikHelpers<FormValues>) => {
                formActions.setSubmitting(true);
                formActions.setFieldError("serverError", "");
                try {
                    await handleSubmit(values);
                } catch (e) {
                    formActions.setFieldError(
                        "serverError",
                        t(
                            INTL_NOT_INTERESTED_SUBMIT_FAILED.key,
                            INTL_NOT_INTERESTED_SUBMIT_FAILED.defaultValue,
                        ),
                    );
                }
                formActions.setSubmitting(false);
            }}
            initialValues={{
                reason: NotInterestedReasonOptions[0].value,
                serverError: "",
            }}
            validationSchema={{
                reason: string().required(
                    t(
                        INTL_NOT_INTERESTED_REASON_VALIDATION.key,
                        INTL_NOT_INTERESTED_REASON_VALIDATION.defaultValue,
                    ),
                ),
            }}
        >
            {(props: {values: FormValues; isSubmitting: boolean}) => {
                const {values, isSubmitting} = props;
                return (
                    <Stack space="gutter">
                        <FormGroup
                            id="reason"
                            label={t(
                                INTL_NOT_INTERESTED_DROPDOWN_LABEL.key,
                                INTL_NOT_INTERESTED_DROPDOWN_LABEL.defaultValue,
                            )}
                        >
                            <Select
                                name="reason"
                                aria-label="reason"
                                required
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                                formatValue={(e) => e}
                            >
                                {NotInterestedReasonOptions.map((reason) => (
                                    <option value={reason.value} key={reason.translationKey}>
                                        {t(reason.translationKey, reason.name)}
                                    </option>
                                ))}
                            </Select>
                        </FormGroup>
                        <Box>
                            <StyledSubmitButton
                                id="submit"
                                block
                                disabled={isSubmitting || !values.reason}
                                buttonColor={universityColor}
                                data-test-id="test-submit"
                            >
                                <Text color="white">
                                    {t(
                                        INTL_NOT_INTERESTED_SUBMIT.key,
                                        INTL_NOT_INTERESTED_SUBMIT.defaultValue,
                                    )}
                                </Text>
                            </StyledSubmitButton>
                            <ErrorMessage title="Form errors" name="serverError" />
                        </Box>
                    </Stack>
                );
            }}
        </Form>
    );
};
