import React from "react";
import {Box, Spinner} from "@unibuddy/patron";

export function SSOLoader() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" minH="545px" minW="495px">
            <Spinner />
        </Box>
    );
}
