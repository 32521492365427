export type IntlKeyValuePair = {
    key: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValue: string | ((...args: any[]) => string);
    intlValue: string;
    namespace: IntlNamespaces;
};

export enum IntlNamespaces {
    ACCOUNT_LOCKED = "accountLocked",
    BLOGS = "blogs",
    CHAT = "chat",
    CODE_OF_CONDUCT = "codeOfConduct",
    COMMON = "common",
    DATA_USAGE_INFO = "dataUsageInfo",
    DECISION_STAGE = "decisionStage",
    ERROR_MESSAGE = "errorMessage",
    INBOX = "inbox",
    LEVELS = "levels",
    LOGIN = "login",
    MENTOR_CARD = "mentorCard",
    PASSWORD_RESET = "passwordReset",
    PRIVACY_TEXT = "privacy-text",
    RECOMMENDATIONS = "recommendations",
    SETTINGS = "settings",
    SIGNUP = "signup",
    UNIVERSITY_CHAT = "universityChat",
    VALIDATIONS = "validations",
    VERIFICATION = "verification",
    VERIFY_AGE = "verifyAge",
}
