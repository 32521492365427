import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import {resolveCustomAttributeView} from "../../../../CustomAttributes";
import MentorCardSectionItem from "../../MentorCardSectionItem/MentorCardSectionItem";
import MentorCardSection from "../../MentorCardSection/MentorCardSection";

const OtherSection = ({customAttributes}) => (
    <MentorCardSection
        title="Details"
        icon="line_star_icon"
        iconStyle={{position: "relative", top: -2}}
    >
        {customAttributes
            .filter((a) => !!a.value)
            .map((attributeValue, i) => {
                const last = i === customAttributes.length - 1;
                const View = resolveCustomAttributeView(get(attributeValue, "attribute.type"));

                return (
                    <MentorCardSectionItem
                        data-test-id={`attribute-value-${get(attributeValue, "attribute.id")}`}
                        key={get(attributeValue, "attribute.id")}
                        label={get(attributeValue, "attribute.name")}
                        last={last}
                    >
                        <View attributeValue={attributeValue} />
                    </MentorCardSectionItem>
                );
            })}
    </MentorCardSection>
);

OtherSection.propTypes = {
    customAttributes: PropTypes.array,
};

OtherSection.defaultProps = {
    customAttributes: [],
};

export default OtherSection;
