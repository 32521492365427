import React, {useState} from "react";
import {useTranslation} from "@unibuddy/intl";
import {Text, VisuallyHidden, Stack, Box, Heading} from "@unibuddy/patron";
import {useMutation} from "react-apollo";

import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";

import {PageTitle} from "../../../../components/PageTitle/PageTitle";
import {notInterestedStageMutation, NotInterestedReasonEnum} from "../NotInterested";
import {FormValues, NotInterestedForm} from "../components/NotInterestedForm";
import {useUniversity} from "../../UniversityProvider/UniversityProvider";
import {
    ProspectStage,
    ProspectStageLocation,
} from "../../Chat/components/PopupQuestions/prospectStages";
import {IconTickCircle} from "../../Icons/IconTickCircle";
import {
    INTL_NOT_INTERESTED_FEEDBACK_MESSAGE,
    INTL_NOT_INTERESTED_SUBTITLE,
    INTL_NOT_INTERESTED_TITLE,
} from "../../../../translations/decisionStage";

export function NotInterestedPage() {
    const [saveNotInterestedStage] = useMutation(notInterestedStageMutation);
    const university = useUniversity();
    const {trackEvent} = useAnalytics();
    const {t, ready} = useTranslation("decisionStage");
    const {reportError} = useErrorReporting();
    const [submitted, setSubmitted] = useState<boolean>(false);

    const handleSubmit = React.useCallback(
        async (values: FormValues) => {
            const [universityId, decisionStage, prospectStageLocation, notInterestedReason] = [
                university.id,
                ProspectStage.NOT_INTERESTED,
                ProspectStageLocation.WIDGET_NOT_INTERESTED_PAGE,
                values.reason,
            ];

            try {
                if (
                    notInterestedReason ===
                    NotInterestedReasonEnum.NOT_INTERESTED_IN_APPLYING_TO_UNI
                ) {
                    await saveNotInterestedStage({
                        variables: {
                            universityId,
                            decisionStage,
                            prospectStageLocation,
                            notInterestedReason,
                        },
                    });
                }

                setSubmitted(true);

                trackEvent(sharedEvents.prospectNotInterestedSubmitted.name, {
                    universityId: university.id,
                    universityName: university.name,
                    where: ProspectStageLocation.WIDGET_NOT_INTERESTED_PAGE,
                    reason: values.reason,
                });
            } catch (error) {
                reportError(error);
            }
        },
        [university, trackEvent, saveNotInterestedStage, reportError],
    );

    if (!ready) return <p>Loading...</p>;

    return (
        <>
            <VisuallyHidden>
                <PageTitle
                    title={t(INTL_NOT_INTERESTED_TITLE.key, INTL_NOT_INTERESTED_TITLE.defaultValue)}
                />
            </VisuallyHidden>
            {submitted ? (
                <Stack space="medium" align="center">
                    <Box width={32} height={32} marginTop="xxsmall">
                        <IconTickCircle color="#7AC943" width={32} height={32} />
                    </Box>
                    <Heading level="2" size="small" align="center" weight="600">
                        {t(
                            INTL_NOT_INTERESTED_FEEDBACK_MESSAGE.key,
                            INTL_NOT_INTERESTED_FEEDBACK_MESSAGE.defaultValue,
                        )}
                    </Heading>
                </Stack>
            ) : (
                <Stack space={"gutter"}>
                    <Text color="#424B5A" size="medium">
                        {t(
                            INTL_NOT_INTERESTED_SUBTITLE.key,
                            INTL_NOT_INTERESTED_SUBTITLE.defaultValue,
                        )}
                    </Text>
                    <NotInterestedForm handleSubmit={handleSubmit} />
                </Stack>
            )}
        </>
    );
}
