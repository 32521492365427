import React, {Suspense} from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Auth from "shared/Auth/auth";
import withAuth from "shared/Auth/withAuth/withAuth";
import {Stack} from "@unibuddy/patron";
import PageHeader from "shared/Shared/components/PageHeader/PageHeader";
import {useTranslation} from "@unibuddy/intl";
import compose from "lodash/flowRight";

import s from "./UniTermsRequired.pcss";
import withAgeVerification from "../../../modules/Verification/VerifyAge/AgeVerification";
import {SignupImage3} from "../../../modules/Register/components/SignupImages/SignupImage3";
import {IntlNamespaces} from "../../../../translations/types";
import {INTL_UNI_TERMS} from "../../../../translations/inbox";
import {INTL_TELL_US_A_BIT_ABOUT_YOU} from "../../../../translations/common";
import {isFunction} from "../../../../util/isFunction";

const LinkWithUniversityForm = React.lazy(() =>
    import(
        // eslint-disable-next-line comma-dangle
        /* webpackChunkName: "lwuf" */ "shared/Applicant/LinkWithUniversity/containers/LinkWithUniversityForm"
    ),
);

const UniTermsRequired = ({location, render, authState, universitySlug, isEmbedded}) => {
    const {t} = useTranslation(IntlNamespaces.INBOX);
    const path = location.pathname.split("/");
    const isInbox = !!path.find((pathStr) => pathStr === "inbox");

    const currentApplicantUniversityLink = Auth.getCurrentApplicantUniversity(
        authState.me,
        universitySlug,
    );

    let toRelink = false;
    if (get(location, "state.from") === "delinked") {
        toRelink = true;
    }

    if (
        Auth.loggedIn(authState) &&
        (!currentApplicantUniversityLink ||
            (currentApplicantUniversityLink.delinked && (!isInbox || toRelink)))
    ) {
        const firstName = get(authState, "me.anyUser.firstName", "");
        const content = (
            <React.Fragment>
                <Stack align="center" space="medium">
                    <SignupImage3 />
                    <PageHeader
                        className={s.title}
                        data-test-id="uni-terms-required-title"
                        title={t(
                            `${IntlNamespaces.COMMON}:${INTL_TELL_US_A_BIT_ABOUT_YOU.key}`,
                            isFunction(INTL_TELL_US_A_BIT_ABOUT_YOU.defaultValue)
                                ? INTL_TELL_US_A_BIT_ABOUT_YOU.defaultValue(firstName)
                                : INTL_TELL_US_A_BIT_ABOUT_YOU.defaultValue,
                            {
                                name: firstName,
                            },
                        )}
                    />
                    <p className={s.subtitleText}>
                        {t(INTL_UNI_TERMS.key, INTL_UNI_TERMS.defaultValue)}
                    </p>
                </Stack>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <Suspense fallback={null}>
                            <LinkWithUniversityForm
                                data-testid="link-with-university-form"
                                universitySlug={universitySlug}
                                signUpHeaderRequired={false}
                            />
                        </Suspense>
                    </div>
                </div>
            </React.Fragment>
        );

        if (isEmbedded === true) return content;
        return <div>{content}</div>;
    }
    return render();
};

UniTermsRequired.propTypes = {
    render: PropTypes.func.isRequired,
    universitySlug: PropTypes.string.isRequired,
    isEmbedded: PropTypes.bool,
};

UniTermsRequired.defaultProps = {
    isEmbedded: false,
};

export const UnwrappedUniTermsRequired = UniTermsRequired;

export default compose(withAuth, withAgeVerification)(UniTermsRequired);
