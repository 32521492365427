import PropTypes from "prop-types";
import React from "react";
import Icon from "shared/General/components/Icon/Icon";
import s from "./ExitTab.pcss";

const ExitTab = (props) => {
    const isSelected = props.selectedTab === props.id;
    const className = isSelected ? s.selectedTab : s.normalTab;
    return (
        <button
            className={s.button}
            id="exit-tab"
            data-test-id="exit-tab"
            key="exit"
            onClick={props.onClick}
        >
            <div id={`${props.id}-tab-content`} className={className}>
                <div className={s.tabsIcon}>
                    <Icon
                        className={isSelected === props.id ? s.svgIconSelected : s.svgIcon}
                        name="line_shrink_icon"
                        height={25}
                        width={25}
                    />
                </div>
                <div className={s.tabsText}>Exit</div>
            </div>
        </button>
    );
};

ExitTab.propTypes = {
    selectedTab: PropTypes.string.isRequired,
    onClick: PropTypes.bool.isRequired,
};

export default ExitTab;
