import * as React from "react";
import {SVGProps} from "react";

export const IconRightArrow = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", width = "1em", height = "1em", ...rest} = props;
    return (
        <svg
            role="img"
            aria-label="Right Arrow"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 15 11"
            fill="none"
            {...rest}
        >
            <path
                d="M1.2832 5.28516H11.9499"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.94922 1.28516L13.2826 5.28516L7.94922 9.28516"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
