import React, {useEffect, useState} from "react";
import {useTranslation} from "@unibuddy/intl";
import MentorCardSectionItem from "shared/Mentor/components/MentorCardSectionItem/MentorCardSectionItem";
import {MentorDegreeField, UniversityField} from "../../../../../types/generated";
import {titleCase} from "../../../../../util/titleCase";

type DegreesProps = {
    degrees: MentorDegreeField[];
    university: UniversityField;
};
export const Degrees = ({degrees = [], university}: DegreesProps) => {
    const [orderedDegrees, setOrderedDegrees] = useState([]);
    const {t} = useTranslation("mentorCard");

    useEffect(() => {
        const degreeTypeOrder = university.degreeTypeOrder ?? [];
        const degreeMap = {};
        degrees.forEach((obj) => {
            if (obj.degree && obj.degreeType) {
                if (degreeMap[obj.degreeType]) degreeMap[obj.degreeType].push(obj.degree.name);
                else degreeMap[obj.degreeType] = [obj.degree.name];
            }
        });
        const orderedDeg = [];
        degreeTypeOrder.forEach((degree) => {
            if (degreeMap[degree.type])
                orderedDeg.push({
                    degreeType: degree.type,
                    degree: degreeMap[degree.type],
                });
        });
        setOrderedDegrees(orderedDeg);
    }, [degrees, university]);

    return (
        <>
            {orderedDegrees.map((degree) => {
                return (
                    <MentorCardSectionItem
                        label={t(
                            degree.degreeType.toLowerCase(),
                            `${titleCase(degree?.degreeType as string)}(S)`,
                        )}
                    >
                        {degree.degree.map((degreeName) => (
                            <div data-test-id={degreeName}>{degreeName}</div>
                        ))}
                    </MentorCardSectionItem>
                );
            })}
        </>
    );
};
