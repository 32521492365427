import * as React from "react";
import {SVGProps} from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", ...rest} = props;
    return (
        <svg
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M8.035.464a.5.5 0 0 1 .951 0l1.657 5.1a.5.5 0 0 0 .476.346h5.363a.5.5 0 0 1 .294.904l-4.339 3.153a.5.5 0 0 0-.181.559l1.657 5.1a.5.5 0 0 1-.77.56l-4.339-3.153a.5.5 0 0 0-.587 0l-4.34 3.153a.5.5 0 0 1-.769-.56l1.658-5.1a.5.5 0 0 0-.182-.56L.244 6.815A.5.5 0 0 1 .54 5.91h5.363a.5.5 0 0 0 .476-.346l1.657-5.1Z"
                fill={color}
            />
        </svg>
    );
};

export default SvgComponent;
