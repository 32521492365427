import React, {useCallback, useEffect, useMemo, useRef} from "react";
import PropTypes from "prop-types";
import {getEvents} from "../../Helpers/EventHelper";
import {sharedEvents, itemNames, pageNames} from "../constants/productSpecification";
import AnalyticsContext from "./AnalyticsContext";

const adaptorPropType = PropTypes.shape({
    init: PropTypes.func,
    setUser: PropTypes.func,
    aliasVisitor: PropTypes.func,
    reset: PropTypes.func,
    trackEvent: PropTypes.func,
});

UniversalAnalyticsProvider.propTypes = {
    adaptors: PropTypes.arrayOf(adaptorPropType),
    productEvents: PropTypes.object,
    user: PropTypes.object,
};

UniversalAnalyticsProvider.defaultProps = {
    adaptors: [],
};

/**
 * This class wraps a component and exposes various Analytics related methods
 * to the context api which is then available to all nested components.
 */
export default function UniversalAnalyticsProvider({adaptors, productEvents, user, children}) {
    const globalPropertiesRef = useRef({});
    const notFirstRenderRef = useRef(false);
    /**
     * Calls the adaptor setUser method to set the user based on the UUID
     * @param {object} user - user object containing id and account role
     */
    const setUser = useCallback(
        (userObj) => {
            try {
                /**
                 * Until the alumni feature is fully fleshed out, we are using degree_level field
                 * on the user object to differentiate the alumnis from others
                 */
                let accountRole = userObj.accountRole;
                if (accountRole === "mentor" && userObj.degreeLevel === "Alumni") {
                    accountRole = "alumni";
                }
                adaptors.forEach((adaptor) => {
                    if (!adaptor.setUser) return;
                    adaptor.setUser({
                        id: userObj.id,
                        accountRole,
                        email: userObj.email,
                        firstName: userObj.firstName,
                    });
                });
            } catch (e) {
                console.error(e);
            }
        },
        [adaptors],
    );

    const unsetUser = useCallback(() => {
        adaptors.forEach((adaptor) => {
            if (!adaptor.reset) return;
            adaptor.reset();
        });
    }, [adaptors]);

    useEffect(() => {
        if (user) {
            // if a prospect has an account role but no country, they have not completed sign
            // up. We should only setUser once they complete their profile to prevent
            // a race condition between aliasing and identifying the user
            if (user.accountRole === "applicant" && !user.country) {
                // do nothing
            } else {
                setUser(user);
            }
        } else if (notFirstRenderRef.current) {
            unsetUser();
        }
        notFirstRenderRef.current = true;
    }, [setUser, unsetUser, user]);

    /**
     * Sets a key-value pair that should be sent as a property with every analytics event.
     * @param {string} key - the property key to be sent with every event.
     * @param {string} value- the property value corresponding to the key to be sent with every event.
     */
    const setAnalyticsValue = useCallback((key, value) => {
        globalPropertiesRef.current[key] = value;
    }, []);

    const unsetAnalyticsValue = useCallback((key) => {
        delete globalPropertiesRef.current[key];
    }, []);

    /**
     * Calls the adaptor alias method to alias a visitor to a user id, alias only called once in lifecycle of a user
     * @param {object} user - user object containing id and account role
     */
    const aliasVisitor = useCallback(
        (userObj) => {
            /**
             * Until the alumni feature is fully fleshed out, we are using degree_level field
             * on the user object to differentiate the alumnis from others
             */
            let accountRole = userObj.accountRole;
            if (accountRole === "mentor" && userObj.degreeLevel === "Alumni") {
                accountRole = "alumni";
            }
            adaptors.forEach((adaptor) => {
                if (!adaptor.aliasUser) return;

                adaptor.aliasUser({
                    id: userObj.id,
                    accountRole,
                });
            });
        },
        [adaptors],
    );

    /**
     * Calls the adaptor method to record an analytics event with properties.
     * This is the only mandatory adaptor method.
     * @param {string} name - name of the event
     * @param {object} properties - object of key-value pairs to send with the event
     */
    const trackEvent = useCallback(
        (name, properties) => {
            adaptors.forEach((adaptor) => {
                adaptor.trackEvent(name, {...properties, ...globalPropertiesRef.current});
            });
        },
        [adaptors],
    );

    const events = useMemo(
        () => getEvents(trackEvent, sharedEvents, productEvents),
        [productEvents, trackEvent],
    );

    const analytics = useMemo(
        () => ({
            setAnalyticsValue,
            unsetAnalyticsValue,
            trackEvent,
            setUser,
            unsetUser,
            aliasVisitor,
            events,
            itemNames,
            pageNames,
        }),
        [
            aliasVisitor,
            events,
            setAnalyticsValue,
            setUser,
            trackEvent,
            unsetAnalyticsValue,
            unsetUser,
        ],
    );

    return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}
