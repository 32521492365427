import React, {Component} from "react";
import {withTranslation} from "@unibuddy/intl";
import PropTypes from "prop-types";
import s from "./ForwardLoader.pcss";
import LoadingButton from "../../../Experimental/General/components/LoadingButton/LoadingButton";
import {IntlNamespaces} from "../../../../translations/types";
import {INTL_LOAD_MORE} from "../../../../translations/common";

export class ForwardLoaderContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            loading: false,
        };
        this.itemsElement = React.createRef();
    }

    componentDidMount() {
        this.itemsElement.current.scrollTop = this.itemsElement.current.scrollHeight;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot !== null) {
            const items = this.itemsElement.current;
            items.scrollTop = items.scrollHeight - snapshot;
        }
    }

    loadItems = () => {
        try {
            this.setState(
                {
                    loading: true,
                },
                async () => {
                    await this.props.fetchMore();
                    this.setState({
                        loading: false,
                    });
                },
            );
        } catch (err) {
            this.setState({
                error: err.message,
                loading: false,
            });
        }
    };

    render() {
        return (
            <div className={s.content}>
                <div className={s.options} ref={this.itemsElement}>
                    <div className={s.buttonWrapper}>
                        {this.props.showLoadButton ? (
                            <LoadingButton
                                loading={this.state.loading || this.props.isFetching}
                                id="forwardLoader"
                                data-test-id="forwardLoader"
                                className={s.forwardLoader}
                                onClick={this.loadItems}
                                round
                                size="sm"
                            >
                                {this.props.t(INTL_LOAD_MORE.key, INTL_LOAD_MORE.defaultValue)}
                            </LoadingButton>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

ForwardLoaderContent.propTypes = {
    fetchMore: PropTypes.func.isRequired,
    showLoadButton: PropTypes.bool,
};

ForwardLoaderContent.defaultProps = {
    showLoadButton: true,
};

const ForwardLoader = ({...rest}) => (
    <div className={s.container}>
        <ForwardLoaderContent data-test-id="forward-loader-content" {...rest} />
    </div>
);

export default withTranslation(IntlNamespaces.COMMON)(ForwardLoader);
