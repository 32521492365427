/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as React from "react";
import styled from "styled-components";
import {OptionItem, OptionLabel, useOption} from "@unibuddy/patron";

import useUniversityColor from "../../widgets/modules/UniversityProvider/hooks/useUniversityColor";

const StyledLabel = styled(OptionLabel)`
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    color: black;
    cursor: pointer;
    font-size: 16px;
    height: ${({height}) => height ?? "45px"};
    width: 100%;
    border-width: 1px;
    border-style: solid;
    font-weight: ${({isSelected}) => (isSelected ? "bold" : "normal")};
    border-color: ${({isSelected, color}) => (isSelected ? color : "#D0D9D9")};
    box-shadow: ${({isFocused}) => (isFocused ? "0 0 0 2px white, 0 0 0 5px #21409a" : "none")};
    &:hover {
        border-color: ${({color}) => color ?? "#D0D9D9"};
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
    &:focus {
        outline: 0 !important;
    }
`;

const CustomOptionLabel = ({height = "45px", children}) => {
    const {isSelected, isFocused} = useOption();
    const color = useUniversityColor();

    return (
        <StyledLabel isSelected={isSelected} isFocused={isFocused} color={color} height={height}>
            {children}
        </StyledLabel>
    );
};

export type UniversityRadioButtonProps = {
    value: string;
    label: string;
    height?: string;
};

/*
This component should be consumed only within an OptionFormGroup from patron
*/
export const UniversityRadioButton: React.FC<UniversityRadioButtonProps> = ({
    value,
    label,
    height,
}) => {
    return (
        <OptionItem value={value}>
            <CustomOptionLabel height={height}>{label}</CustomOptionLabel>
        </OptionItem>
    );
};
