import React, {useContext} from "react";
import {SourceTrackingContext} from "../SourceTrackingProvider/SourceTrackingProvider";

/**
 * This HOC attaches the analytics prop to the wrapped App component and includes
 * the relevant analytics methods provided through the context API by AnalyticsProvider.
 * @param WrappedComponent
 * @returns Component
 */
const withSourceTracking = (WrappedComponent) => {
    const AppSourceTracking = (props) => {
        const context = useContext(SourceTrackingContext);
        return (
            <WrappedComponent
                {...props}
                sourceTracking={{
                    updateSourceTrackingParamLocally: context.updateSourceTrackingParamLocally,
                    saveSourceTrackingParams: context.saveSourceTrackingParams,
                    redirectWithSourceTracking: context.redirectWithSourceTracking,
                    getSourceTrackingParams: context.getSourceTrackingParams,
                }}
            />
        );
    };

    return AppSourceTracking;
};

export default withSourceTracking;
