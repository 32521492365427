import {analyticsAdaptors, setAnalyticsAdaptors} from "./App";
import {getenv} from "./util/getEnv";

const MAX_RETRIES = 5;
const POLLING_DELAY = 400;
/* 400 * 4 == 2000ms */

export const isPwa = (): boolean => window.location.pathname.includes("pwa");

export function setOneTrustCookies(): void {
    const ONE_TRUST_DOMAIN_SCRIPT = getenv("ONE_TRUST_DOMAIN_SCRIPT");
    if (ONE_TRUST_DOMAIN_SCRIPT) {
        if (isPwa()) {
            const script = document.createElement("script");
            document.head.appendChild(script);
            script.type = "text/javascript";
            script.setAttribute(
                "src",
                "https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js",
            );
            script.setAttribute("data-domain-script", ONE_TRUST_DOMAIN_SCRIPT as string);
        }
    }
}

export function pollSettingAnalyticsAdapters(): void {
    if (isPwa()) {
        let i = MAX_RETRIES;
        const timerId = setInterval(() => {
            if (i === 0 || "OnetrustActiveGroups" in window) {
                setAnalyticsAdaptors(analyticsAdaptors);
                clearTimeout(timerId);
            }
            i -= 1;
        }, POLLING_DELAY);
    } else {
        setAnalyticsAdaptors(analyticsAdaptors);
    }
}
