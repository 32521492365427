import React from "react";
import AnalyticsContext from "../AnalyticsProvider/AnalyticsContext";

/**
 * This HOC attaches the analytics prop to the wrapped App component and includes
 * the relevant analytics methods provided through the context API by AnalyticsProvider.
 * @param WrappedComponent
 * @returns Component
 */
const withAnalytics = (WrappedComponent) => {
    const AppAnalytics = (props) => {
        return (
            <AnalyticsContext.Consumer>
                {(context) => <WrappedComponent {...props} analytics={context} />}
            </AnalyticsContext.Consumer>
        );
    };

    return AppAnalytics;
};

export default withAnalytics;
