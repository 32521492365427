import * as React from "react";
import {SVGProps} from "react";

export const IconSignUp = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={25}
        height={25}
        fill="none"
        viewBox="0 0 25 25"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.611 10.678a.749.749 0 0 1-1.276.534l-2.411-2.38-2.361 2.375A.75.75 0 1 1 8.5 10.15l2.89-2.905a.751.751 0 0 1 1.059 0l2.942 2.9a.752.752 0 0 1 .22.534Z"
            fill="currentColor"
        />
        <path
            d="M12.669 7.773v10.59a.75.75 0 0 1-1.5 0V7.772a.75.75 0 1 1 1.5 0Z"
            fill="currentColor"
        />
        <path
            d="M20.938 7.745v8.713a4.618 4.618 0 0 1-4.613 4.613h-1.62a.75.75 0 1 1 0-1.5h1.62a3.115 3.115 0 0 0 3.113-3.113V7.745a3.116 3.116 0 0 0-3.113-3.108H7.612A3.115 3.115 0 0 0 4.5 7.745v8.713a3.116 3.116 0 0 0 3.112 3.113h1.539a.75.75 0 0 1 0 1.5H7.612A4.618 4.618 0 0 1 3 16.458V7.745a4.617 4.617 0 0 1 4.612-4.608h8.713a4.618 4.618 0 0 1 4.613 4.608Z"
            fill="currentColor"
        />
    </svg>
);
