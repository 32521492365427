import useAuth from "shared/Auth/hooks/useAuth";
import {AnyUserField, Maybe} from "../../../types/generated";

/**
 * Lightweight wrapper to make getting the user have types and
 * generally be more enjoyable
 */
export function useUser(): Maybe<AnyUserField> {
    const auth = useAuth() as {user: AnyUserField};
    return auth?.user;
}
