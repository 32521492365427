import React, {useEffect, useState} from "react";
import {SSOError, ssoHandleAuthCode} from "@unibuddy/auth-ui";
import useErrorReporting from "shared/General/components/ErrorReporting/useErrorReporting";
import {SSOLoader} from "./SSOLoader";

type HandleAuthCodeParams = {
    contextType: string;
    onSuccess(bearerToken: string): Promise<void> | void;
    onError(obj: {error: string}): void;
};

const HandleAuthCode = ({contextType, onSuccess, onError}: HandleAuthCodeParams) => {
    const [loadingAuth, setLoadingAuth] = useState<boolean>(false);

    useEffect(() => {
        const handleAuth = async () => {
            setLoadingAuth(true);
            await ssoHandleAuthCode({onSuccess, onError, contextType});
        };
        if (!loadingAuth) handleAuth();
    }, [onSuccess, onError, contextType, loadingAuth]);

    return <SSOLoader />;
};

export const SSOHandleAuth = () => {
    const {reportError} = useErrorReporting();

    const contextType = "university";

    const closeWindow = () => {
        // We're in a popup when this code runs, so we need to close the window
        window.close();
    };

    const onError = (error: SSOError) => {
        console.error("Error while generating token", error);
        reportError(`Failed generating token. Error: ${error.error}`);

        closeWindow();
    };

    const onSuccess = (bearerToken: string) => {
        if (!bearerToken) {
            console.error("No bearer token received");
            reportError("No bearer token received");

            closeWindow();
            return;
        }

        const targetOrigin =
            process?.env?.NODE_ENV === "development" ? "*" : window.location.origin;

        window.opener.postMessage(`ub-sso-token=${JSON.stringify({bearerToken})}`, targetOrigin);

        closeWindow();
    };

    return <HandleAuthCode contextType={contextType} onError={onError} onSuccess={onSuccess} />;
};
