import React from "react";
import {HeaderTab, HeaderTabProps} from "../HeaderTab/HeaderTab";

type IconTabProps = Pick<
    HeaderTabProps,
    "id" | "to" | "name" | "activeClassName" | "inactiveClassName" | "onClick" | "data-test-id"
> & {
    icon: string | ((isActive: boolean) => React.ReactElement);
    children: React.ReactNode;
};
export const IconTab = ({
    id,
    to,
    name,
    activeClassName,
    inactiveClassName,
    onClick,
    "data-test-id": dataTestId,
    icon,
    children,
}: IconTabProps) => (
    <HeaderTab
        id={id}
        name={name}
        onClick={onClick}
        to={to}
        activeClassName={activeClassName}
        inactiveClassName={inactiveClassName}
        data-test-id={dataTestId}
    >
        {({isActive}) => {
            return (
                <React.Fragment>
                    {typeof icon === "string" ? <span className={icon} /> : icon(isActive)}
                    {children}
                </React.Fragment>
            );
        }}
    </HeaderTab>
);
