import React from "react";
import {useTranslation} from "@unibuddy/intl";
import styled from "styled-components";
import {Box, Text, Heading, ButtonLink} from "@unibuddy/patron";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {toTitleCase} from "shared/Utils/StringUtils";
import {UNIVERSITY_MULTI_CHOICE_CHAT} from "shared/constants";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import {IconDoubleStar} from "../../../Icons/IconDoubleStar";
import {HandWave} from "../../images/HandWave";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {ProspectStageLocation} from "../../../Chat/components/PopupQuestions/prospectStages";
import UniversityQuestionCallOutLoadingLoading from "./UniversityQuestionCallOutLoadingLoading";
import useUniversityColor from "../../../UniversityProvider/hooks/useUniversityColor";
import {IntlNamespaces} from "../../../../../translations/types";
import {
    INTL_UNIVERSITY_QUESTION_CALL_OUT_LINK_TEXT,
    INTL_UNIVERSITY_QUESTION_CALL_OUT_TEXT,
} from "../../../../../translations/recommendations";

const StyledButton = styled(ButtonLink)<{buttonColor: string}>`
    border: 0;
    background-color: ${(props) => props.buttonColor ?? "#0171E0"};
    &:hover:not([disabled]) {
        background-color: ${(props) => props.buttonColor ?? "#0171E0"};
        opacity: 0.85;
    }
`;

export type UniversityQuestionCallOutProps = {
    name: string;
};

export const UniversityQuestionCallOut: React.ExoticComponent<UniversityQuestionCallOutProps> =
    React.memo(({name}) => {
        const {id, slug, name: universityName} = useUniversity();
        const color = useUniversityColor();
        const {trackEvent} = useAnalytics();
        const {t, ready} = useTranslation([IntlNamespaces.RECOMMENDATIONS]);

        if (!ready) return <UniversityQuestionCallOutLoadingLoading />;

        const universityQuestionCallOutText = t(
            INTL_UNIVERSITY_QUESTION_CALL_OUT_TEXT.key,
            INTL_UNIVERSITY_QUESTION_CALL_OUT_TEXT.defaultValue,
        );

        const universityQuestionCallOutLinkText = t(
            INTL_UNIVERSITY_QUESTION_CALL_OUT_LINK_TEXT.key,
            INTL_UNIVERSITY_QUESTION_CALL_OUT_LINK_TEXT.defaultValue,
        );

        const handleClick = () => {
            trackEvent(sharedEvents.universityQuestionCallOutClicked.name, {
                universityId: id,
                universityName,
                universitySlug: slug,
                where: ProspectStageLocation.WIDGET_FOR_YOU,
            });
        };

        return (
            <>
                <Box display="flex">
                    <Box display="flex" paddingRight="xsmall">
                        <HandWave />
                    </Box>
                    {name !== "" && (
                        <Box display="flex" alignItems="center">
                            <Heading level="3">{toTitleCase(name)}!</Heading>
                        </Box>
                    )}
                </Box>
                <Box
                    display="flex"
                    flexDirection={["column", "row"]}
                    justifyContent="space-between"
                    alignItems="center"
                    gap="medium"
                    borderColor="#D0D9D9"
                    backgroundColor="#F3F5F5"
                    borderRadius="xxsmall"
                    borderWidth={1}
                    padding="small"
                    marginTop="medium"
                    marginBottom="medium"
                >
                    <Box display="flex" flexDirection={"row"}>
                        <IconDoubleStar width={36} height={35} />
                        <Box
                            display="flex"
                            paddingLeft="small"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Text color="#404446" weight="600" align={["center", "left"]}>
                                {universityQuestionCallOutText}
                            </Text>
                        </Box>
                    </Box>
                    <Box>
                        <StyledButton
                            buttonColor={color}
                            to={`inbox/${UNIVERSITY_MULTI_CHOICE_CHAT}`}
                            size="lg"
                            onClick={handleClick}
                        >
                            <Text color="#FFF" weight="700">
                                {universityQuestionCallOutLinkText}
                            </Text>
                        </StyledButton>
                    </Box>
                </Box>
            </>
        );
    });
