import React, {ReactNode} from "react";
import {Box, Button, Stack, Text, View} from "@unibuddy/patron";
import styled from "styled-components";

import useUniversityColor from "../../../UniversityProvider/hooks/useUniversityColor";

type IntroPageCardProps = {
    hero: ReactNode;
    text: string;
    buttonText: string;
    relativeLink: string;
    index: number;
    onClick: (buttonText: string, index: number, relativeLink: string) => void;
};

const StyledButton = styled(Button)<{$color: string}>`
    color: ${({$color}) => $color};
    border: ${({$color}) => `1px solid ${$color}`};
    &:hover {
        background-color: ${({$color}) => `${$color}e6`} !important;
        color: white;
    }
    &:focus {
        background-color: ${({$color}) => `${$color}cc`} !important;
        color: white;
    }
`;

export function IntroPageCard({
    hero,
    text,
    buttonText,
    relativeLink,
    index,
    onClick,
}: IntroPageCardProps) {
    const universityColor = useUniversityColor();

    return (
        <Box
            borderWidth={1}
            borderColor="grey150"
            flex={1}
            borderRadius="small-medium"
            padding="small-medium"
        >
            <View justifyContent="space-between" gap="large" h="100%">
                <Stack space="large">
                    <Box h="86px" display="flex" justifyContent="center">
                        {hero}
                    </Box>
                    <Text size="large" weight="bold" align="center" lineHeight="27px">
                        {text}
                    </Text>
                </Stack>
                <StyledButton
                    ghost
                    $color={universityColor}
                    onClick={() => onClick(buttonText, index, relativeLink)}
                >
                    {buttonText}
                </StyledButton>
            </View>
        </Box>
    );
}
