import {useState, useCallback} from "react";
import gql from "graphql-tag";
import {useApolloClient} from "react-apollo";

export const logoutMutationQuery = gql`
    mutation LogoutMutation {
        logout {
            success
        }
    }
`;

export const useLazyLogout = (clearLocalAuthFunction) => {
    const client = useApolloClient();
    const [loading, setLoading] = useState(false);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [error, setError] = useState(null);

    const logout = useCallback(async () => {
        setLoading(true);
        try {
            await client.mutate({mutation: logoutMutationQuery});
        } catch (e) {
            setError(e);
        } finally {
            await client.clearStore();
            if (clearLocalAuthFunction) {
                await clearLocalAuthFunction();
            }
            setIsLoggedOut(true);
            setLoading(false);
        }
    }, [client, clearLocalAuthFunction]);
    return {error, isLoggedOut, logout, loading};
};
