import {IntlKeyValuePair, IntlNamespaces} from "./types";

export const INTL_RECOMMENDATIONS: IntlKeyValuePair = {
    key: "recommendations",
    defaultValue: "Recommendations",
    intlValue: "Recommendations",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_PAGE_SUBTITLE: IntlKeyValuePair = {
    key: "recommendationPageSubtitle",
    defaultValue:
        "Here are some popular recommendations selected for you based on your decision making stage",
    intlValue:
        "Here are some popular recommendations selected for you based on your decision making stage",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_BANNER_TEXT: IntlKeyValuePair = {
    key: "recommendationBannerText",
    defaultValue: "Recommended for you",
    intlValue: "Recommended for you",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_FOR_YOU: IntlKeyValuePair = {
    key: "forYou",
    defaultValue: "For you",
    intlValue: "For you",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_C_T_A: IntlKeyValuePair = {
    key: "recommendationCTA",
    defaultValue: "Check it out",
    intlValue: "Check it out",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_LINK_TEXT_FOR_BLOG: IntlKeyValuePair = {
    key: "recommendationLinkTextForBlog",
    defaultValue: "Go to blog",
    intlValue: "Go to blog",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_LINK_TEXT_FOR_COMMUNITY: IntlKeyValuePair = {
    key: "recommendationLinkTextForCommunity",
    defaultValue: "Go to community",
    intlValue: "Go to community",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_LINK_TEXT_FOR_EVENT: IntlKeyValuePair = {
    key: "recommendationLinkTextForEvent",
    defaultValue: "Go to event",
    intlValue: "Go to event",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_LINK_TEXT_FOR_AMBASSADOR: IntlKeyValuePair = {
    key: "recommendationLinkTextForAmbassador",
    defaultValue: "Go to ambassador",
    intlValue: "Go to ambassador",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_LINK_TEXT_FOR_ARTICLE: IntlKeyValuePair = {
    key: "recommendationLinkTextForArticle",
    defaultValue: "Go to article",
    intlValue: "Go to article",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_LINK_TEXT_FOR_VIDEO: IntlKeyValuePair = {
    key: "recommendationLinkTextForVideo",
    defaultValue: "Go to video",
    intlValue: "Go to video",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_LINK_TEXT_FOR_UNIVERSITY_WEBPAGE: IntlKeyValuePair = {
    key: "recommendationLinkTextForUniversityWebpage",
    defaultValue: "Go to university webpage",
    intlValue: "Go to university webpage",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_LINK_TEXT_FOR_OTHER: IntlKeyValuePair = {
    key: "recommendationLinkTextForOther",
    defaultValue: "Go to other",
    intlValue: "Go to other",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_VIEW_MORE_TEXT: IntlKeyValuePair = {
    key: "viewMoreText",
    defaultValue: "View more",
    intlValue: "View more",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_INBOX_PROMPT_TEXT: IntlKeyValuePair = {
    key: "inboxPromptText",
    defaultValue: "Let us help you take your next step with personalised recommendations!",
    intlValue: "Let us help you take your next step with personalised recommendations!",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_INBOX_PROMPT_LINK_TEXT: IntlKeyValuePair = {
    key: "inboxPromptLinkText",
    defaultValue: "Let's go!",
    intlValue: "Let's go!",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_PAGE_GENERIC_TEXT: IntlKeyValuePair = {
    key: "recommendationPageGenericText",
    defaultValue: "Here are some popular next best steps that we have selected just for you",
    intlValue: "Here are some popular next best steps that we have selected just for you",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_PAGE_PERSONALISED_TEXT: IntlKeyValuePair = {
    key: "recommendationPagePersonalisedText",
    defaultValue:
        "Here are some popular next best steps that we have selected just for you based on where you are in your decision making journey",
    intlValue:
        "Here are some popular next best steps that we have selected just for you based on where you are in your decision making journey",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_PROMPT_TEXT: IntlKeyValuePair = {
    key: "recommendationPromptText",
    defaultValue:
        "Tell us where you are in your decision-making process for personalised recommendations",
    intlValue:
        "Tell us where you are in your decision-making process for personalised recommendations",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_RECOMMENDATION_PROMPT_LINK_TEXT: IntlKeyValuePair = {
    key: "recommendationPromptLinkText",
    defaultValue: "Update now",
    intlValue: "Update now",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_UNIVERSITY_QUESTION_CALL_OUT_TEXT: IntlKeyValuePair = {
    key: "universityQuestionCallOutText",
    defaultValue: "Tell us more about you so we can enhance your experience",
    intlValue: "Tell us more about you so we can enhance your experience",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};

export const INTL_UNIVERSITY_QUESTION_CALL_OUT_LINK_TEXT: IntlKeyValuePair = {
    key: "universityQuestionCallOutLinkText",
    defaultValue: "Tell us more",
    intlValue: "Tell us more",
    namespace: IntlNamespaces.RECOMMENDATIONS,
};
