import React from "react";
import get from "lodash/get";
import {Redirect, Route, Switch} from "react-router-dom";
import {useLocalStorage} from "@unibuddy/patron";

import MentorList from "../../../Mentor/containers/MentorList/MentorList";
import MentorProfile from "../../../Mentor/containers/MentorProfile/MentorProfile";
import BuddiesList from "../../../Buddies/components/BuddiesList/BuddiesList";
import BuddiesProfile from "../../../Buddies/components/BuddiesProfile/BuddiesProfile";

const BuddiesTabRoutes = (parentProps) => {
    const isStaff = (props) => get(props, "match.params.buddyGroupType") === "staff";
    const [userHasSeenIntroPage, setUserHasSeenIntroPage] = useLocalStorage(
        `UB_INTRO_PAGE_SEEN_${parentProps.university.slug}`,
        false,
    );
    return (
        <Switch>
            <Route
                exact
                path={`${parentProps.match.path}/intro`}
                render={({location}) => {
                    if (!userHasSeenIntroPage) setUserHasSeenIntroPage(true);
                    return (
                        <Redirect
                            to={{
                                ...location,
                                pathname: parentProps.match.url,
                            }}
                        />
                    );
                }}
            />
            <Route
                exact
                path={`${parentProps.match.path}/:buddyId`}
                render={(props) =>
                    isStaff(props) ? (
                        <BuddiesProfile {...parentProps} {...props} />
                    ) : (
                        <MentorProfile {...parentProps} {...props} />
                    )
                }
            />
            <Route
                exact
                path={parentProps.match.path}
                render={(props) =>
                    isStaff(props) ? (
                        <BuddiesList {...parentProps} {...props} />
                    ) : (
                        <MentorList {...parentProps} {...props} />
                    )
                }
            />
        </Switch>
    );
};

export default BuddiesTabRoutes;
