import gql from "graphql-tag";
import {useQuery} from "react-apollo";
import {
    INTL_ADMITTED,
    INTL_COMPARING_OPTIONS,
    INTL_DECISION_STAGE_PAGE_SUBTITLE,
    INTL_DECISION_STAGE_PAGE_TITLE,
    INTL_GATHERING_INFORMATION,
    INTL_NARROWED_DOWN,
    INTL_NONE_OF_THE_ABOVE,
    INTL_PREPARING,
    INTL_SUBMITTED,
} from "../../../../../translations/decisionStage";

export const enum ProspectStage {
    NOT_INTERESTED = "NOT_INTERESTED",
    NONE_OF_THE_ABOVE = "NONE_OF_THE_ABOVE",
    GATHERING_INFO = "GATHERING_INFO",
    COMPARING = "COMPARING",
    NARROWED = "NARROWED",
    PREPARING = "PREPARING",
    APPLIED = "APPLIED",
    ADMITTED = "ADMITTED",
}

export const enum ProspectStageLocation {
    WIDGET_INBOX = "WIDGET_INBOX",
    WIDGET_CHAT = "WIDGET_CHAT",
    WIDGET_NOT_INTERESTED_PAGE = "WIDGET_NOT_INTERESTED_PAGE",
    WIDGET_FOR_YOU = "WIDGET_FOR_YOU",
}

export type ProspectStageOption = {
    name: string;
    translationKey: string;
    value: ProspectStage;
};

const OptOutStages = new Set([ProspectStage.NOT_INTERESTED, ProspectStage.NONE_OF_THE_ABOVE]);

const AppliedStages = new Set([ProspectStage.APPLIED, ProspectStage.ADMITTED]);

export function isProspectAppliedStage(stage: ProspectStage): boolean {
    return AppliedStages.has(stage);
}

export const DecisionStagePageTitle = {
    translationKey: INTL_DECISION_STAGE_PAGE_TITLE.key,
    name: INTL_DECISION_STAGE_PAGE_TITLE.defaultValue,
};

export const DecisionStagePageSubtitle = {
    translationKey: INTL_DECISION_STAGE_PAGE_SUBTITLE.key,
    name: INTL_DECISION_STAGE_PAGE_SUBTITLE.defaultValue,
};

export const enum DecisionStageLayout {
    STACK = "STACK",
}

export const PROSPECT_STAGES: Readonly<ProspectStageOption[]> = Object.freeze([
    {
        name: INTL_NONE_OF_THE_ABOVE.defaultValue as string,
        translationKey: INTL_NONE_OF_THE_ABOVE.key,
        value: ProspectStage.NONE_OF_THE_ABOVE,
    },
    {
        name: INTL_GATHERING_INFORMATION.defaultValue as string,
        translationKey: INTL_GATHERING_INFORMATION.key,
        value: ProspectStage.GATHERING_INFO,
    },
    {
        name: INTL_COMPARING_OPTIONS.defaultValue as string,
        translationKey: INTL_COMPARING_OPTIONS.key,
        value: ProspectStage.COMPARING,
    },
    {
        name: INTL_NARROWED_DOWN.defaultValue as string,
        translationKey: INTL_NARROWED_DOWN.key,
        value: ProspectStage.NARROWED,
    },
    {
        name: INTL_PREPARING.defaultValue as string,
        translationKey: INTL_PREPARING.key,
        value: ProspectStage.PREPARING,
    },
    {
        name: INTL_SUBMITTED.defaultValue as string,
        translationKey: INTL_SUBMITTED.key,
        value: ProspectStage.APPLIED,
    },
    {
        name: INTL_ADMITTED.defaultValue as string,
        translationKey: INTL_ADMITTED.key,
        value: ProspectStage.ADMITTED,
    },
]);

export const ProspectStageNoneOfTheAbove: ProspectStageOption = PROSPECT_STAGES[0];

export const ProspectStagesForUi: Readonly<ProspectStageOption[]> = PROSPECT_STAGES.filter(
    (s) => !OptOutStages.has(s.value),
);

export const prospectStageMutation = gql`
    mutation ProspectStageMutation(
        $universityId: ObjectId!
        $decisionStage: DecisionStageEnum!
        $prospectStageLocation: DecisionStageLocationEnum!
    ) {
        saveUserDecisionStage(
            decisionStageInput: {
                universityId: $universityId
                decisionStage: $decisionStage
                decisionStageLocation: $prospectStageLocation
            }
        ) {
            userId
        }
    }
`;

export type ProspectStageQueryResponse = {
    currentUserDecisionStage: {
        updated?: string;
        decitionStage?: ProspectStage;
    };
};

export const getProspectStageQuery = gql`
    query CurrentUserDecisionStage($universityId: ObjectId!) {
        currentUserDecisionStage(
            currentUserDecisionStageQueryInput: {universityId: $universityId}
        ) {
            decisionStage
            updated
        }
    }
`;

export const useGetProspectStageQuery = (universityId: string) =>
    useQuery<ProspectStageQueryResponse>(getProspectStageQuery, {
        variables: {
            universityId,
        },
        fetchPolicy: "cache-and-network",
    });
