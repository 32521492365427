import React from "react";
import {View} from "@unibuddy/patron";

export type CardBodyProps = {
    children: React.ReactNode;
};

export const CardBody = ({children}: CardBodyProps) => {
    return (
        <View
            padding="small-medium"
            bgColor="white"
            height="100%"
            justifyContent="space-between"
            gap="small-medium"
        >
            {children}
        </View>
    );
};
