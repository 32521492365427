import gql from "graphql-tag";

const buddiesFilterOptionsQuery = gql`
    query BuddiesFilterOptions(
        $universitySlug: String
        $active: Boolean
        $groupType: String
        $all: Boolean = false
    ) {
        buddiesFilterOptions(
            universitySlug: $universitySlug
            active: $active
            groupType: $groupType
            all: $all
        ) {
            roles
            departments
            customAttributesFilters {
                id
                name
                options {
                    id
                    value
                }
            }
        }
    }
`;

export default buddiesFilterOptionsQuery;
