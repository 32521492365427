import React from "react";
import {NavLink, useRouteMatch, useLocation} from "react-router-dom";
import s from "./HeaderTab.pcss";

export type HeaderTabProps = {
    to: string;
    id: string;
    name: string;
    activeClassName?: string;
    inactiveClassName?: string;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    children: React.ReactNode | (({isActive}: {isActive: boolean}) => React.ReactNode);
    "data-test-id"?: string;
};

export const HeaderTab = ({
    to,
    children,
    id,
    name,
    activeClassName = "",
    inactiveClassName = "",
    onClick,
    "data-test-id": dataTestId,
}: HeaderTabProps): JSX.Element => {
    const location = useLocation();
    const isActive = Boolean(useRouteMatch(to));

    return (
        <NavLink
            id={`${id}-tab`}
            data-test-id={dataTestId}
            className={[inactiveClassName, s.link].filter((i) => i).join(" ")}
            activeClassName={[activeClassName, s.link, s.selected].filter((i) => i).join(" ")}
            to={{pathname: to, search: location.search}}
            onClick={onClick}
        >
            <>
                <div className={s.icon}>
                    {typeof children === "function" ? children({isActive}) : children}
                </div>
                <div className={s.text}>{name}</div>
            </>
        </NavLink>
    );
};
