import React from "react";
import {Text} from "@unibuddy/patron";

import {ThemedLink} from "../../../../components/ThemedLink/ThemedLink";

type CardBodyActionsProps = {
    link: string;
    external?: boolean;
    handleClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    children: React.ReactNode;
};

export const CardBodyActions = ({
    link,
    handleClick,
    external = false,
    children,
}: CardBodyActionsProps) => {
    return (
        <Text size="small">
            {/* TODO: Replace ThemedLink with generic components so we can extract to library*/}
            <ThemedLink
                to={link}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e)}
                external={external}
            >
                {children}
            </ThemedLink>
        </Text>
    );
};
