import React from "react";
import {View} from "@unibuddy/patron";

type CardHeaderProps = {
    children: React.ReactNode;
};

export const CardHeader = ({children}: CardHeaderProps) => {
    return (
        <View
            padding="small-medium"
            bgColor="grey50"
            gap="small-medium"
            flexShrink={0}
            overflow="hidden"
        >
            {children}
        </View>
    );
};
