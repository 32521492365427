// / action types

export const SET_TEXT = "SET_TEXT";
export const SET_LAST_SENT_MESSAGE = "SET_LAST_SENT_MESSAGE";

// / action creators

export function setText(text) {
    return {
        type: SET_TEXT,
        text,
    };
}

export function setLastSentMessage(lastSentMessage) {
    return {
        type: SET_LAST_SENT_MESSAGE,
        lastSentMessage,
    };
}
