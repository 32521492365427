import gql from "graphql-tag";
import BlogPostPreviewFragment from "shared/Blog/fragments/blogPostPreviewFragment";

const buddiesProfileQuery = gql`
    query PublicBuddy($id: String!, $isBlogsEnabled: Boolean = true) {
        buddy(id: $id) {
            id
            active
            firstName
            profilePhoto(raw: true)
            shareUrl
            university {
                id
                name
                widgetUrl
                logo
                slug
            }
            staffFields {
                bio
                department
                role
            }
            customAttributes(active: true) {
                value
                attribute {
                    id
                    name
                    type
                }
                ... on SingleChoiceValueField {
                    option {
                        id
                        value
                    }
                }
            }
        }

        mentorPosts(mentorId: $id) @include(if: $isBlogsEnabled) {
            ...BlogPostPreviewFragment
        }
    }

    ${BlogPostPreviewFragment}
`;

export default buddiesProfileQuery;
