import React from "react";
import {Box, Shimmer, View} from "@unibuddy/patron";

export function IntroPageCardLoading() {
    return (
        <Box
            shadow="large"
            borderWidth={1}
            borderColor="grey150"
            flex={1}
            borderRadius="small-medium"
            padding="small-medium"
        >
            <View justifyContent="space-between" gap="large" h="100%">
                <View alignItems="center" gap="medium-large">
                    <Box h="86px" w="86px" display="flex" justifyContent="center">
                        <Shimmer>
                            <circle cx="43" cy="43" r="43" />
                        </Shimmer>
                    </Box>
                    <View gap="small">
                        <Box h="16px" w="100%" overflow="hidden">
                            <Shimmer width="100%">
                                <rect x="0" y="0" rx="4" ry="4" width="900" height="16" />
                            </Shimmer>
                        </Box>
                        <Box h="16px" w="100%" overflow="hidden">
                            <Shimmer width="100%">
                                <rect x="0" y="0" rx="4" ry="4" width="900" height="16" />
                            </Shimmer>
                        </Box>
                    </View>
                </View>
                <Box h="38px" w="100%" overflow="hidden">
                    <Shimmer width="100%">
                        <rect x="0" y="0" rx="4" ry="4" width="900" height="38" />
                    </Shimmer>
                </Box>
            </View>
        </Box>
    );
}
