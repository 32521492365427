import React, {useEffect, useCallback} from "react";
import {usePage} from "./PageContext";
import {WIDGET_PAGE} from "./PageTitles";

type WidgetPageMaintainerProps = {
    widgetPage: WIDGET_PAGE;
};

export const WidgetPageMaintainer: React.FC<WidgetPageMaintainerProps> = React.memo(
    ({widgetPage, children}) => {
        const {setPage} = usePage();
        const setPageValue = useCallback(() => {
            setPage(widgetPage);
        }, [setPage, widgetPage]);

        useEffect(() => {
            setPageValue();
        }, [setPageValue]);

        return <>{children}</>;
    },
);
