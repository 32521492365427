import some from "lodash/some";
import * as actions from "../actions/mentorFilterActions";

function mentorFilterReducer(
    state = {
        filters: [],
    },
    action,
) {
    switch (action.type) {
        case actions.ADD_MENTOR_FILTER: {
            const newFilter = {field: action.field, label: action.label, value: action.value};
            if (some(state.filters, newFilter)) {
                return state;
            }
            return {
                ...state,
                filters: [...state.filters, newFilter],
            };
        }
        case actions.REMOVE_MENTOR_FILTER: {
            return {
                ...state,
                filters: state.filters.filter(
                    (filter) => !(filter.field === action.field && filter.value === action.value),
                ),
            };
        }
        default:
            return state;
    }
}

export default mentorFilterReducer;
