/* eslint-disable jsx-a11y/aria-props*/
import React from "react";
import BuddiesHeaderTab from "./BuddiesHeaderTab";
import BuddiesGroupsSubNavHeaderTab from "./BuddiesGroupsSubNavHeaderTab";

export default function BuddiesGroupHeaderTab(props) {
    return (
        <React.Fragment>
            {!props.activeTabs.students && !props.activeTabs.staff ? (
                <BuddiesHeaderTab
                    onClick={() => props.onSelectTab("Buddies")}
                    to={`${props.rootUrl}/buddies`}
                    aria-current={props.currentPage === "Buddies" ? "page" : false}
                />
            ) : (
                <BuddiesGroupsSubNavHeaderTab {...props} />
            )}
        </React.Fragment>
    );
}
