export const FIRST_NAME_REQUIRED = "First Name is a required field";
export const LAST_NAME_REQUIRED = "Last Name is a required field";
export const EMAIL_REQUIRED = "Email is a required field";
export const VALID_EMAIL = "Please enter a valid email address.";
export const PASSWORD_REQUIRED = "Password is a required field";
export const PASSWORD_MIN_CHAR = (PASSWORD_MINIMUM_LENGTH) =>
    `Password must be at least ${PASSWORD_MINIMUM_LENGTH} characters`;
export const CONFIRM_PASSWORD_REQUIRED = "Confirm Password is a required field";
export const CONFIRM_PASSWORD_MIN_CHAR = (PASSWORD_MINIMUM_LENGTH) =>
    `Confirm Password must be at least ${PASSWORD_MINIMUM_LENGTH} characters`;
export const CONFIRM_PASSWORD_DOESNT_MATCH = "Confirm Password doesn't match";
export const PRIVACY_CHECKED = "Privacy Policy must be checked";
export const INVALID_EMAIL_OR_PASSWORD = "You have entered an invalid email or password";
