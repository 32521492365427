import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "@unibuddy/intl";
import PillButton from "../../../General/components/PillButton/PillButton";

const ChatNowButton = ({id, className, large, onChatNow, name, ...props}) => {
    const {t} = useTranslation("mentorCard");

    return (
        <PillButton id={id} className={className} onClick={onChatNow} large={large} {...props}>
            {
                /* we render this so react text comment nodes don't mess up
               text assertions in bdd tests */
                t("chatWith", `Chat with ${name}`, {name})
            }
        </PillButton>
    );
};

ChatNowButton.defaultProps = {
    large: false,
    invert: false,
    id: "",
};

ChatNowButton.propTypes = {
    name: PropTypes.string.isRequired,
    onChatNow: PropTypes.func.isRequired,
    large: PropTypes.bool,
    id: PropTypes.string,
};

export default ChatNowButton;
