import React, {createContext} from "react";
import {Primitive} from "@sentry/types/types/misc";
import {v4 as uuidv4} from "uuid";
import isAvailable from "../../../Utils/PlatformFeatures";

type ErrorReportingProviderContextType = {
    reportError: (err: unknown, data?: {level?: string; tags?: {[key: string]: string}}) => void;
    logMessage?: (message: string, level?: string) => void;
};
const initialContext: ErrorReportingProviderContextType = {reportError: console.log};
export const ErrorReportingContext =
    createContext<ErrorReportingProviderContextType>(initialContext);

type ErrorReportingProviderProps = React.PropsWithChildren<{
    adaptor: {
        captureException: (
            error: unknown,
            {
                uuid,
                tags,
                level,
            }: {
                uuid?: string;
                tags?: {
                    [key: string]: Primitive;
                };
                level?: string;
            },
        ) => void;
        captureMessage: (message: string, level: string) => void;
    };
}>;

/*
 * Because we don't track personal information in error reports (such as ip address),
 * we create a unique ID per user otherwise it's impossible to track how many people
 * are affected by a problem.
 * To attempt to keep the number accurate we store the generated ID in localStorage
 * and then reuse it each time, however if the client blocks access to localStorage
 * we should just generate a new ID for each session.
 */
const getUniqueId = (): string => {
    const UB_UUID = "UB_UUID";

    const newId = uuidv4();

    if (isAvailable("localStorage")) {
        const existingId = localStorage.getItem(UB_UUID);
        if (existingId) return existingId;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        localStorage.setItem(UB_UUID, newId);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return newId;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return newId;
};

/**
 * Exposes a method for reporting errors to 3rd party services or internal logging.
 * Typically it should be used at the top of the application tree so it catches most React
 * rendering errors when used together with the ErrorBoundary component.
 * This should be done once per application otherwise we will get duplicate errors. That
 * is why there is a context check in cDM that checks if this component already exists up the tree.
 * Uses the context API to pass the `reportError` functionality down the component tree.
 */
export function ErrorReportingProvider({children, adaptor}: ErrorReportingProviderProps) {
    const uuid = getUniqueId();
    const existingContext = React.useContext(ErrorReportingContext);

    React.useEffect(() => {
        if (existingContext !== initialContext) {
            const errorMessage =
                "ErrorReportingProvider component should be used once per application.";
            const err = new Error(errorMessage);

            if (process.env.NODE_ENV === "production") {
                console.error(errorMessage);
            }
            adaptor.captureException(err, {uuid, level: "warning"});
        }
    }, [adaptor, existingContext, uuid]);

    const reportError = (err: unknown, data?: {level?: string; tags?: {[key: string]: string}}) => {
        if (process.env.NODE_ENV === "production") {
            console.error(err);
        }
        adaptor.captureException(err, {uuid, ...data});
    };

    const logMessage = (message: string, level = "debug") => {
        if (!message) {
            return;
        }

        adaptor.captureMessage(message, level);
    };

    return (
        <ErrorReportingContext.Provider
            value={{
                reportError,
                logMessage,
            }}
        >
            {children}
        </ErrorReportingContext.Provider>
    );
}
