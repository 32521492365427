import * as React from "react";
import {SVGProps} from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", ...rest} = props;
    return (
        <svg
            width={17}
            height={17}
            fill="none"
            viewBox="0 0 17 17"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M11.296 3.286a1.667 1.667 0 0 1 1.667 1.666v6.63a1.667 1.667 0 0 1-1.667 1.666h-6.63A1.667 1.667 0 0 1 3 11.582v-6.63a1.667 1.667 0 0 1 1.667-1.666h6.629Zm0-1h-6.63A2.667 2.667 0 0 0 2 4.952v6.63a2.667 2.667 0 0 0 2.667 2.666h6.629a2.667 2.667 0 0 0 2.667-2.666v-6.63a2.667 2.667 0 0 0-2.667-2.666Z"
                fill={color}
            />
            <path
                d="M5.651 4.288a.5.5 0 0 1-.5-.5V1.834a.5.5 0 0 1 1 0v1.954a.5.5 0 0 1-.5.5ZM10.31 4.288a.5.5 0 0 1-.5-.5V1.834a.5.5 0 0 1 1 0v1.954a.5.5 0 0 1-.5.5ZM11.18 6.462H4.765a.5.5 0 1 1 0-1h6.415a.5.5 0 1 1 0 1ZM6.553 11.091H5.43a1.168 1.168 0 0 1-1.166-1.166V8.803A1.168 1.168 0 0 1 5.43 7.637h1.122a1.168 1.168 0 0 1 1.166 1.166v1.122a1.168 1.168 0 0 1-1.166 1.166ZM5.43 8.637a.167.167 0 0 0-.166.166v1.122a.167.167 0 0 0 .166.166h1.122a.168.168 0 0 0 .166-.166V8.803a.167.167 0 0 0-.166-.166H5.43Z"
                fill={color}
            />
        </svg>
    );
};

export default SvgComponent;
