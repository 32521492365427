import {Box, Shimmer, VisuallyHidden} from "@unibuddy/patron";
import React from "react";
import styled from "styled-components";

const LoadingState = styled(Shimmer)`
    height: 68px;
    @media (max-width: 460px) {
        height: 48px;
    }
`;

export default function UniversityQuestionCallOutLoadingLoading() {
    return (
        <Box width="100%" marginBottom="xsmall" data-test-id="nba-loading-state">
            <LoadingState width="100%">
                <rect x="0" y="0" rx={4} ry={4} width="100%" height="100%" />
                <VisuallyHidden>Loading...</VisuallyHidden>
            </LoadingState>
        </Box>
    );
}
