import gql from "graphql-tag";

export const getRandomAmbassadors = gql`
    query GetRandomAmbassadors($universitySlug: String, $limit: LimitedInt) {
        sequenceFilterMentorList(universitySlug: $universitySlug, safeLimit: $limit) {
            mentors {
                id
                firstName
                profilePhoto(raw: false)
            }
        }
    }
`;
