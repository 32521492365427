import * as React from "react";
import {SVGProps} from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", ...rest} = props;
    return (
        <svg
            width={17}
            height={17}
            fill="none"
            viewBox="0 0 17 17"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M10.863 13.929H5.067A3.067 3.067 0 0 1 2 10.862V5.067A3.067 3.067 0 0 1 5.067 2h3.837a.5.5 0 1 1 0 1H5.067A2.067 2.067 0 0 0 3 5.067v5.795a2.067 2.067 0 0 0 2.067 2.067h5.796a2.067 2.067 0 0 0 2.066-2.067V7.029a.5.5 0 0 1 1 0v3.833a3.067 3.067 0 0 1-3.066 3.067Z"
                fill={color}
            />
            <path
                d="m11.303 3.766.86.86-4.577 4.58-.884-.013.012-.837 4.59-4.589v-.001Zm0-1.147a.64.64 0 0 0-.454.188L5.812 7.845a.32.32 0 0 0-.094.223l-.025 1.791a.32.32 0 0 0 .316.326l1.85.025h.004a.321.321 0 0 0 .227-.094l5.034-5.034a.64.64 0 0 0 0-.907l-1.367-1.368a.64.64 0 0 0-.454-.188Z"
                fill={color}
            />
        </svg>
    );
};

export default SvgComponent;
