import * as React from "react";
import {SVGProps} from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", ...rest} = props;
    return (
        <svg
            width={17}
            height={17}
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M9.058 3.667a1.327 1.327 0 0 1 1.276 1.368v1.776A1.327 1.327 0 0 1 9.058 8.18H6.99a1 1 0 0 0-.615.211l-1.144.894-.134-.41a1 1 0 0 0-.952-.695H3.61A1.327 1.327 0 0 1 2.334 6.81V5.035A1.327 1.327 0 0 1 3.61 3.666h5.448Zm0-1H3.61a2.324 2.324 0 0 0-2.276 2.368v1.776A2.324 2.324 0 0 0 3.61 9.18h.537l.32 1a.57.57 0 0 0 .54.405.553.553 0 0 0 .34-.119l1.645-1.283h2.066a2.324 2.324 0 0 0 2.276-2.372V5.035a2.324 2.324 0 0 0-2.276-2.369Z"
                fill={color}
            />
            <path
                d="M11.213 13.29a.854.854 0 0 1-.525-.182l-.904-.705H8.65a2.154 2.154 0 0 1-.505-.067A1.587 1.587 0 0 1 6.9 10.982a.5.5 0 1 1 1 0c0 .094.246.326.48.382.088.024.179.038.27.042h1.308c.111 0 .22.037.308.106l.874.682.141-.441a.501.501 0 0 1 .477-.347h.34a.972.972 0 0 0 .94-1V9.28a.993.993 0 0 0-.6-.933.5.5 0 1 1 .384-.923 1.992 1.992 0 0 1 1.214 1.854v1.126a1.974 1.974 0 0 1-1.916 2l-.09.284a.867.867 0 0 1-.569.566.858.858 0 0 1-.248.036Z"
                fill={color}
            />
        </svg>
    );
};

export default SvgComponent;
