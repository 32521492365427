import React from "react";
import {yearsValueToTranslationKey} from "shared/Mentor/components/MentorCard/MentorCard";
import {MentorField} from "./MentorField";
import {Degrees} from "./Degrees";
import {Country} from "./Country";
import {
    DegreeField,
    LanguageField,
    MentorInterestField,
    MentorModuleField,
    MentorSocietyField,
    UniversityField,
    MentorField as Mentor,
} from "../../../../../types/generated";

const itemSeparator = " · ";

export const MentorFields = {
    country: {
        component: MentorField,
        translationKey: "iComeFrom",
        defaultLabel: "I come From",
        fieldValue: Country,
    },
    year: {
        component: MentorField,
        translationKey: "currentlyIn",
        defaultLabel: "I'm currently in",
        fieldValue: ({
            degreeLevel,
            year,
            t,
        }: {
            degreeLevel: string;
            year: string;
            t: Translation;
        }) => {
            if (year) {
                const key = yearsValueToTranslationKey[year] as string;
                const undergraduateKey = `undergraduateYears.${key}`;
                const postgraduateKey = `postgraduateYears.${key}`;
                return degreeLevel === "Undergraduate"
                    ? t(undergraduateKey, year)
                    : t(postgraduateKey, year);
            }
            return null;
        },
    },
    degrees: {
        component: Degrees,
        fieldValue: () => "",
    },
    languages: {
        component: MentorField,
        translationKey: "iSpeak",
        defaultLabel: "I speak",
        fieldValue: ({languages = [], t}: {languages: LanguageField[]; t: Translation}) =>
            languages
                ?.map((l) => {
                    const language = l.name;
                    const languageKey = `languages:${language}`;
                    return t(languageKey, language);
                })
                .join(itemSeparator),
    },
    degree: {
        component: MentorField,
        translationKey: "areaOfStudySection",
        defaultLabel: "Area Of Study",
        fieldValue: ({degree = {}}: {degree: DegreeField}) => degree?.name,
    },
    favouriteModules: {
        component: MentorField,
        translationKey: "favoriteCourses",
        defaultLabel: "Favourite Courses",
        fieldValue: ({favouriteModules}: {favouriteModules: MentorModuleField[]}) =>
            favouriteModules?.map((l) => l.name).join(itemSeparator),
    },
    pastQualification: {
        component: MentorField,
        translationKey: "previousStudies",
        defaultLabel: "Previous studies",
        fieldValue: ({pastQualification = ""}: {pastQualification: string}) => pastQualification,
    },
    interests: {
        component: MentorField,
        translationKey: "hobbiesInterests",
        defaultLabel: "Hobbies and Interests",
        fieldValue: ({interests = []}: {interests: MentorInterestField[]}) =>
            interests?.map((l) => l.name).join(itemSeparator),
    },
    societies: {
        component: MentorField,
        translationKey: "societies",
        defaultLabel: "Clubs & Societies",
        fieldValue: ({societies = [], t}: {societies: MentorSocietyField[]; t: Translation}) =>
            societies?.length > 0
                ? societies.map((l) => l.name).join(itemSeparator)
                : t("notPartOfSocieties", "I'm not part of any societies"),
    },
    bio: {
        component: MentorField,
        translationKey: "aboutMe",
        defaultLabel: "About Me",
        fieldValue: ({bio, shortBio}: {bio: string; shortBio: string}) => {
            if (bio)
                return bio.length > 200 ? `${bio.substring(0, 200)}...` : bio.substring(0, 200);
            return `${shortBio}...`;
        },
    },
};

type Translation = (key: string, defaultLabel: string) => string;
type MentorComponentTypes = {
    t: Translation;
    university: UniversityField;
    mentor: Mentor;
};
export default (field: string, props: MentorComponentTypes) => {
    const mentorField = MentorFields[field];
    if (mentorField?.component) {
        return React.createElement(mentorField.component, {
            key: mentorField.translationKey,
            translationKey: mentorField.translationKey,
            defaultLabel: mentorField.defaultLabel,
            fieldValue: mentorField.fieldValue({t: props.t, ...props.mentor, ...props}),
            university: props.university,
            degrees: props.mentor.degrees,
            country: props.mentor.country,
            mentorFields: props.mentor.mentorFields,
            placeName: props.mentor.placeName,
        } as any);
    }
    const customField = props.mentor.customAttributes?.find(
        (customAttribute) => customAttribute?.attribute?.id === field,
    );
    return customField
        ? React.createElement(
              MentorField as any,
              {
                  key: customField?.attribute?.name ?? "",
                  translationKey: customField?.attribute?.name,
                  defaultLabel: customField?.attribute?.name,
                  fieldValue: (customField as any)?.option?.value,
              } as any,
          )
        : null;
};
