import gql from "graphql-tag";
import {
    INTL_NOT_INTERESTED_IN_APPLYING,
    INTL_NOT_INTERESTED_IN_UPDATES,
    INTL_NOT_INTERESTED_OTHER,
} from "../../../translations/decisionStage";

export const enum NotInterestedReasonEnum {
    NOT_INTERESTED_IN_APPLYING_TO_UNI = "NOT_INTERESTED_IN_APPLYING_TO_UNI",
    NOT_INTERESTED_IN_UPDATES_FROM_UNI = "NOT_INTERESTED_IN_UPDATE",
    NOT_INTERESTED_OTHER = "NOT_INTERESTED_OTHER",
}

export const NotInterestedReasonOptions = [
    {
        name: INTL_NOT_INTERESTED_IN_APPLYING.defaultValue,
        translationKey: INTL_NOT_INTERESTED_IN_APPLYING.key,
        value: NotInterestedReasonEnum.NOT_INTERESTED_IN_APPLYING_TO_UNI,
    },
    {
        name: INTL_NOT_INTERESTED_IN_UPDATES.defaultValue,
        translationKey: INTL_NOT_INTERESTED_IN_UPDATES.key,
        value: NotInterestedReasonEnum.NOT_INTERESTED_IN_UPDATES_FROM_UNI,
    },
    {
        name: INTL_NOT_INTERESTED_OTHER.defaultValue,
        translationKey: INTL_NOT_INTERESTED_OTHER.key,
        value: NotInterestedReasonEnum.NOT_INTERESTED_OTHER,
    },
];

export const notInterestedStageMutation = gql`
    mutation NotInterestedStageMutation(
        $universityId: ObjectId!
        $decisionStage: DecisionStageEnum!
        $prospectStageLocation: DecisionStageLocationEnum!
        $notInterestedReason: NotInterestedReasonEnum!
    ) {
        saveNotInterestedStage(
            notInterestedStageInput: {
                universityId: $universityId
                decisionStage: $decisionStage
                decisionStageLocation: $prospectStageLocation
                notInterestedReason: $notInterestedReason
            }
        ) {
            userId
        }
    }
`;
