import React, {Component} from "react";
import compose from "lodash/flowRight";
import withAuth from "./withAuth/withAuth";
import Auth from "./auth";
import withAnalytics from "../AnalyticsNew/withAnalytics/withAnalytics";

function withIdentify(WrappedComponent) {
    class IdentifyComponent extends Component {
        componentDidMount() {
            if (Auth.loggedIn(this.props.authState)) {
                this.props.analytics.setUser(this.props.me.anyUser);
            }
        }
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }
    return compose(withAuth, withAnalytics)(IdentifyComponent);
}

export default withIdentify;
