import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./Flag.pcss";

const getFlag = ({position = "right", flagCode, flagType, className = "", props = []}) => {
    if (flagCode === "gb-nir" || flagCode === "nir") return null;
    const ariaLabel = flagType
        ? `${flagType} Country flag ${flagCode}`
        : `Country flag ${flagCode}`;
    return (
        <span
            {...props}
            data-test-id={`${position}-test-flag-id-${flagCode}`}
            className={classNames(
                {[`flag-icon flag-icon-${flagCode}`]: flagCode !== "gb-nir" && flagCode !== "nir"},
                className,
            )}
            role={"img"}
            aria-label={ariaLabel}
        />
    );
};

export default function Flag({
    countryCode,
    primaryFlag,
    secondaryFlag,
    className,
    position,
    ...props
}) {
    if (typeof countryCode !== "string" && !primaryFlag && !secondaryFlag) return null;
    if (primaryFlag && secondaryFlag) {
        // Dual Citizenship
        return (
            <div {...props} className={classNames(s.dualFlags, className)}>
                <div className={s.primaryFlag}>
                    {getFlag({
                        position,
                        flagCode: primaryFlag.toLowerCase(),
                        flagType: "Primary",
                        props,
                    })}
                </div>
                <div className={s.secondaryFlag}>
                    {getFlag({
                        position,
                        flagCode: secondaryFlag.toLowerCase(),
                        props,
                        flagType: "Secondary",
                    })}
                </div>
            </div>
        );
    } else if (primaryFlag) {
        // Single citizenship
        return getFlag({
            position,
            flagCode: primaryFlag.toLowerCase(),
            className,
            props,
            flagType: "Primary",
        });
    } else if (secondaryFlag) {
        // Single citizenship
        return getFlag({
            position,
            flagCode: secondaryFlag.toLowerCase(),
            className,
            props,
            flagType: "Secondary",
        });
    }
    // Backwards compatibility
    return getFlag({position, flagCode: countryCode.toLowerCase(), className, props});
}

Flag.propTypes = {
    countryCode: PropTypes.string.isRequired,
};
