import gql from "graphql-tag";
import MentorFieldsFragment from "shared/Mentor/fragments/mentorFieldsFragment";

const sequenceFilterMentorListQuery = gql`
    query SequenceFilterMentorListQuery(
        $universitySlug: String
        $sequenceFilter: [MentorFilterInputField]
        $offset: Int
        $limit: LimitedInt
        $mentorSeed: Int
        $applyDegreeFilter: Boolean
        $isDegreeTypesEnabled: Boolean = false
    ) {
        sequenceFilterMentorList(
            universitySlug: $universitySlug
            sequenceFilter: $sequenceFilter
            offset: $offset
            safeLimit: $limit
            mentorSeed: $mentorSeed
            applyDegreeFilter: $applyDegreeFilter
        ) {
            mentors {
                id
                firstName
                profilePhoto(raw: true)
                lastSeen
                degree @skip(if: $isDegreeTypesEnabled) {
                    id
                    name
                }
                degrees @include(if: $isDegreeTypesEnabled) {
                    degreeType
                    degree {
                        id
                        name
                    }
                }
                languages {
                    id
                    name
                }
                year
                favouriteModules {
                    id
                    name
                }
                pastQualification
                interests {
                    id
                    name
                }
                societies {
                    id
                    name
                }
                customAttributes(active: true) {
                    value
                    attribute {
                        id
                        name
                    }
                    ... on SingleChoiceValueField {
                        option {
                            id
                            value
                        }
                    }
                }
                placeName
                country {
                    id
                    code
                    name
                }
                university {
                    id
                    name
                }
                pastQualification
                shortBio
                bio
                degreeLevel
                mentorFields {
                    ...MentorFieldsFull
                }
            }
            filters {
                id
                label
                options {
                    id
                    label
                }
            }
            mentorSeed
        }
    }
    ${MentorFieldsFragment}
`;

export default sequenceFilterMentorListQuery;
