import React from "react";
import {Box, Text, View} from "@unibuddy/patron";

type CardActivityStatusProps = {
    activityStatus: string;
};

export const CardActivityStatus = ({activityStatus}: CardActivityStatusProps) => {
    return (
        <Box
            position="absolute"
            bgColor="white"
            borderRadius="full"
            top={8}
            right={8}
            padding="xsmall"
        >
            <View justifyContent="center" alignItems="center">
                <Text size="xsmall">{activityStatus}</Text>
            </View>
        </Box>
    );
};
