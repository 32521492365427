import {ApolloLink} from "apollo-link";
import {setContext} from "apollo-link-context";
import {onError} from "apollo-link-error";
import {ServerError} from "apollo-link-http-common";
import {createUploadLink} from "apollo-upload-client";
import get from "lodash/get";
import {v4 as uuidv4} from "uuid";
import {Store} from "redux";
import {clearAuth} from "../Auth/actions/authActions";
import {configureRequestContext} from "../General/components/ErrorReporting/errorReportingAdaptor";
import {getenv} from "../../util/getEnv";

const GATEWAY_URL = getenv("GATEWAY_URL") as string;

interface IMetadata {
    app: "mobile" | "web";
}

class OfflineError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "OfflineError";
    }
}

export const createCustomFetch = (metadata?: IMetadata) => {
    const customFetch = async (uri: string, options: RequestInit) => {
        if (options.body instanceof FormData) return fetch(uri, options);
        const body = JSON.parse(options?.body.toString() || "");
        const {operationName} = body as {operationName: string};

        if (get(metadata, "app") === "mobile") {
            // next line linter disabled to handle satisfy both typescript AND eslint.
            options.headers["client"] = metadata.app; // eslint-disable-line dot-notation
        }
        const paramsArray = [`opname=${encodeURIComponent(operationName)}`];
        const filteredVariablesString = paramsArray.join("&");
        try {
            const fetchResult = await fetch(`${uri}?${filteredVariablesString}`, options);
            return fetchResult;
        } catch (e) {
            if (navigator.onLine) {
                throw e;
            }
            throw new OfflineError("Network Disconnected while request is onflight");
        }
    };
    return customFetch;
};

const defaultLink: (store: Store, uri: string) => ApolloLink = (
    store,
    uri = `${GATEWAY_URL}/graphql`,
) => {
    const httpLink: ApolloLink = createUploadLink({
        uri,
        fetch: createCustomFetch(),
        headers: {
            "keep-alive": "true",
        },
    });

    const middlewareLink = setContext(() => {
        const token: string = get(store.getState(), "authState.token", "") as string;
        const tokenScheme = get(store.getState(), "authState.tokenScheme", "") as string;
        // generate unique request id and setting it as Sentry tag and attaching it to every request
        const requestId = uuidv4();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        configureRequestContext(requestId);
        return {
            headers: {
                authorization: token ? `${tokenScheme} ${token}` : null,
                "X-Request-Id": requestId,
            },
        };
    });

    const errorLink = onError((error) => {
        const {networkError, graphQLErrors} = error;
        if (networkError && (networkError as ServerError).statusCode === 401) {
            console.log("Authorization Not valid, logging out.");
            store.dispatch(clearAuth());
            // Refresh the page
            window.location = window.location; // eslint-disable-line  no-self-assign
        }
        if (networkError) {
            console.log(`[Network error]:`, networkError);

            // breadcrumbs indicate that this block is always encounter before an exception
            // check sentry for more details
            try {
                console.log(`[Stringified network error]: ${JSON.stringify(networkError)}`);
            } catch (e) {
                console.log("An error occurred when try to stringify error object", e);
            }
        }
        if (graphQLErrors && graphQLErrors.length > 0) {
            console.log(`[Grapqhl error]:`, graphQLErrors);
            try {
                console.log(`[Stringified grapqhl error]: ${JSON.stringify(graphQLErrors)}`);
            } catch (e) {
                console.log("An error occurred when try to stringify error object", e);
            }
        }
    });

    return ApolloLink.from([errorLink, middlewareLink, httpLink]);
};

export default defaultLink;
