import {routerReducer as routing} from "react-router-redux";
import authReducer from "shared/Auth/reducers/authReducer";
import messengerReducer from "shared/Chat/reducers/messengerReducer";
import firstMessageReducer from "shared/Register/reducers/firstMessageReducer";
import chatReducer from "shared/Shared/reducers/chatReducer";
import mentorFilterReducer from "./widgets/modules/Mentor/reducers/mentorFilterReducer";

const rootReducer = {
    authState: authReducer,
    firstMessageState: firstMessageReducer,
    mentorFilterState: mentorFilterReducer,
    chatState: chatReducer,
    messengerState: messengerReducer,
    routing,
};

export default rootReducer;
