import React, {useState} from "react";
import {Page} from "./PageContext";
import {WIDGET_PAGE} from "./PageTitles";

export const PageProvider = ({children}) => {
    const [page, setPage] = useState(WIDGET_PAGE.UNKNOWN);

    return (
        <Page.Provider value={{page, setPage: (newPage: WIDGET_PAGE) => setPage(newPage)}}>
            {children}
        </Page.Provider>
    );
};
