import gql from "graphql-tag";

const chatGroupFragment = gql`
    fragment ChatGroup on ChatGroupField {
        id
        delinked
        lastMessage {
            text
            created
            sender {
                id
            }
        }
        unreadMessageCount
        opponent {
            id
            firstName
            inboxInfoOne
            countryCode
            inboxInfoTwo
            profilePhoto
            accountRole
            isOnline
            degrees {
                degreeType
                degree {
                    id
                    name
                }
            }
        }
        university {
            id
            name
            logo
            squareLogo
        }
    }
`;

export default chatGroupFragment;
