import * as actions from "../actions/chatActions";

function chatReducer(
    state = {
        text: "",
        lastSentMessage: {},
    },
    action,
) {
    switch (action.type) {
        case actions.SET_TEXT: {
            return {
                ...state,
                text: action.text,
            };
        }
        case actions.SET_LAST_SENT_MESSAGE: {
            return {
                ...state,
                lastSentMessage: action.lastSentMessage,
            };
        }
        default:
            return state;
    }
}

export default chatReducer;
