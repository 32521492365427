import React from "react";
import {View, Heading, Stack, Text} from "@unibuddy/patron";

export type CardHeaderInfoTextProps = {
    text: string;
    numberOfLines?: number;
    color?: string;
};

export type CardHeaderInfoProps = {
    buddyName: string;
    info?: CardHeaderInfoTextProps[];
};

export const CardHeaderInfo = ({buddyName, info}: CardHeaderInfoProps) => {
    return (
        // We need a surrounding View with height="100%" as
        // otherwise the stack cuts off some of the final row
        // of text.
        <View h="100%" minH="100%" justifyContent="center">
            <Stack space="small">
                <Heading size="xsmall" level={2} truncate>
                    {buddyName}
                </Heading>
                {info.map((item) => {
                    return (
                        <Text
                            size="small"
                            truncate={item.numberOfLines ?? 2}
                            color={item.color ?? "grey900"}
                        >
                            {item.text}
                        </Text>
                    );
                })}
            </Stack>
        </View>
    );
};
