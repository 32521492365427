//These universities have implemented the cookie consent manager on their site.
import * as URLParamHelpers from "./UrlUtils";
import {universitiesWithCookieConsentManager} from "./UniversitiesWithCookieConsentManager";

export const getUniversitySlug = (urlPath) => {
    const url = new URL(urlPath);

    const widgetPath = new RegExp("^(\\/embed\\/|\\/pwa\\/)([^\\/]+).+");
    const buddyCardPath = new RegExp("^(\\/)([^\\/]+)\\/colour");
    const blogFeedPath = new RegExp("^(\\/)([^\\/]+)\\/blog");

    //chat bubble
    const uniSlug = URLParamHelpers.getParameterByName(url.href, "universitySlug");
    if (uniSlug) {
        return uniSlug;
    }

    //embedded widget or pwa
    let found = url.pathname.match(widgetPath);
    if (found && found.length >= 3) {
        return found[2];
    }

    //buddy card
    found = url.pathname.match(buddyCardPath);
    if (found && found.length >= 3) {
        return found[2];
    }

    //blog feed
    found = url.pathname.match(blogFeedPath);
    if (found && found.length >= 3) {
        return found[2];
    }

    return null;
};

export const checkUserCookieConsent = (urlPath) => {
    const uniSlug = getUniversitySlug(urlPath);

    if (!uniSlug) {
        return false;
    }
    return universitiesWithCookieConsentManager.includes(uniSlug);
};
