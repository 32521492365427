import React from "react";
import {Box, Stack, VisuallyHidden} from "@unibuddy/patron";
import styled from "styled-components";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {useTranslation} from "@unibuddy/intl";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import ThemedLink from "shared/Navigation/components/ThemedLink/ThemedLink";
import {
    RecommendationResourceTypeEnum,
    SMALL_SCREEN_WIDTH,
    useSaveVisitedRecommendationMutation,
} from "../NextBestActions";
import {useUniversity} from "../../UniversityProvider/UniversityProvider";
import ContentTypeChip from "./NextBestActionsChip";
import {IconRightArrow} from "../../Icons/IconRightArrow";
import {IntlNamespaces} from "../../../../translations/types";
import {
    INTL_RECOMMENDATION_LINK_TEXT_FOR_BLOG,
    INTL_RECOMMENDATION_LINK_TEXT_FOR_COMMUNITY,
    INTL_RECOMMENDATION_LINK_TEXT_FOR_EVENT,
    INTL_RECOMMENDATION_LINK_TEXT_FOR_AMBASSADOR,
    INTL_RECOMMENDATION_LINK_TEXT_FOR_ARTICLE,
    INTL_RECOMMENDATION_LINK_TEXT_FOR_VIDEO,
    INTL_RECOMMENDATION_LINK_TEXT_FOR_UNIVERSITY_WEBPAGE,
    INTL_RECOMMENDATION_LINK_TEXT_FOR_OTHER,
} from "../../../../translations/recommendations";

const TextContainer = styled(Box)`
    height: 110px;
    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
        height: 92px;
    }
`;

const LinkContainer = styled(Box)`
    font-size: 14px;
    font-weight: 600;
`;

const CardHeading = styled.h3`
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    overflow: hidden;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-bottom: 6px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const CardDescription = styled(Box)`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    overflow: hidden;
    word-break: break-word;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
        -webkit-line-clamp: 2;
    }
`;

const StyleIcon = styled(IconRightArrow)`
    margin-left: 5px;
    position: relative;
    top: 3px;
`;

export default function NextBestActionsCard({
    contentId,
    batchId,
    title,
    description,
    contentType,
    url,
}: {
    contentId: string;
    batchId: string;
    title: string;
    description: string;
    contentType: RecommendationResourceTypeEnum;
    url: string;
}) {
    const {t} = useTranslation(IntlNamespaces.RECOMMENDATIONS);
    const {trackEvent} = useAnalytics();
    const {id: universityId, color} = useUniversity();

    const RecommendationTextLinkMap = {
        BLOG: INTL_RECOMMENDATION_LINK_TEXT_FOR_BLOG,
        COMMUNITY: INTL_RECOMMENDATION_LINK_TEXT_FOR_COMMUNITY,
        EVENT: INTL_RECOMMENDATION_LINK_TEXT_FOR_EVENT,
        AMBASSADOR: INTL_RECOMMENDATION_LINK_TEXT_FOR_AMBASSADOR,
        ARTICLE: INTL_RECOMMENDATION_LINK_TEXT_FOR_ARTICLE,
        VIDEO: INTL_RECOMMENDATION_LINK_TEXT_FOR_VIDEO,
        UNIVERSITY_WEBPAGE: INTL_RECOMMENDATION_LINK_TEXT_FOR_UNIVERSITY_WEBPAGE,
        OTHER: INTL_RECOMMENDATION_LINK_TEXT_FOR_OTHER,
    };

    const [saveVisitedRecommendation] = useSaveVisitedRecommendationMutation({
        variables: {
            contentId,
            batchId,
        },
    });

    const onClick = async () => {
        await saveVisitedRecommendation();
        trackEvent(sharedEvents.nextBestActionsContentOpened.name, {
            universityId,
            contentId,
            contentType,
        });
    };
    return (
        <Stack space="small">
            <ContentTypeChip contentType={contentType} />
            <TextContainer>
                <CardHeading>{title}</CardHeading>
                <CardDescription>{description}</CardDescription>
            </TextContainer>
            <LinkContainer>
                <ThemedLink
                    id={`go-to-${contentId}`}
                    data-test-id="go-to-content-type"
                    onClick={onClick}
                    to={url}
                    target="_blank"
                    external
                    aria-labelledby={`${contentId}-title`}
                >
                    {t(
                        RecommendationTextLinkMap[contentType]?.key,
                        RecommendationTextLinkMap[contentType]?.defaultValue,
                    )}
                    <StyleIcon color={`#${color}` ?? "#FFFFFF"} />
                </ThemedLink>
                <VisuallyHidden>
                    <p id={`${contentId}-title`}>
                        {t(
                            RecommendationTextLinkMap[contentType]?.key,
                            RecommendationTextLinkMap[contentType]?.defaultValue,
                        )}{" "}
                        {title}, {t("common:opensInANewWindow", "Opens in a new window")}
                    </p>
                </VisuallyHidden>
            </LinkContainer>
        </Stack>
    );
}
