import {Box} from "@unibuddy/patron";
import * as React from "react";
import {RouteComponentProps} from "react-router-dom";
import {StaticContext} from "react-router";
import {getenv} from "../../util/getEnv";
import {useUniversity} from "../../widgets/modules/UniversityProvider/UniversityProvider";

const LOGO = "unibuddy-logo.png";

function checkPathIsBuddyPage(path: string): boolean {
    const colourRegex = /\/colour\/\w{6}$/;

    if (path.endsWith("/buddies/students") || path.endsWith("/buddies") || colourRegex.test(path)) {
        return true;
    }

    return false;
}

export type UnibuddyImpressionsLogoProps = {
    routeProps: RouteComponentProps<
        {
            [x: string]: string;
        },
        StaticContext,
        unknown
    >;
};

export const UnibuddyImpressionsLogo = ({routeProps}: UnibuddyImpressionsLogoProps) => {
    const university = useUniversity();
    const LOGO_URL = getenv("LOGO_URL") as string;
    const UB_SERVICE = getenv("UB_SERVICE") as string;
    const path = routeProps?.history?.location.pathname;
    const isBuddyPage = checkPathIsBuddyPage(path);

    const referrer = isBuddyPage ? `${UB_SERVICE}-buddies-list` : `${UB_SERVICE}`;

    const url = `${LOGO_URL}/logos/${referrer}/${university.id}/${LOGO}`;

    return (
        <Box style={{display: "none", visibility: "hidden"}}>
            <img src={url} id="__decorative-logo" alt="Decorative Logo" />
        </Box>
    );
};
