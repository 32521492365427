import React from "react";
import {Stack} from "@unibuddy/patron";

type CardBodySectionWrapperProps = {
    children: React.ReactNode;
};

export const CardBodySectionWrapper = ({children}: CardBodySectionWrapperProps) => {
    return <Stack space="small-medium">{children}</Stack>;
};
