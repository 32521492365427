import {IntlKeyValuePair, IntlNamespaces} from "./types";

export const INTL_AGE_VERIFICATION: IntlKeyValuePair = {
    key: "ageVerification",
    defaultValue: "Age Verification",
    intlValue: "Age Verification",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_FUTURE_AWAITS: IntlKeyValuePair = {
    key: "futureAwaits",
    defaultValue: "Your future awaits!",
    intlValue: "Your future awaits!",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_VERIFY_HOW_OLD: IntlKeyValuePair = {
    key: "verifyHowOld",
    defaultValue: "Before we begin, we just need to verify how old you are...",
    intlValue: "Before we begin, we just need to verify how old you are...",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_DATE_OF_BIRTH_LABEL: IntlKeyValuePair = {
    key: "dateOfBirthLabel",
    defaultValue: "What is your date of birth?",
    intlValue: "What is your date of birth?",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_PRIVACY_TEXT: IntlKeyValuePair = {
    key: "privacyText",
    defaultValue: "I agree to Unibuddy’s <1>Privacy Policy</1>",
    intlValue: "I agree to Unibuddy’s <1>Privacy Policy</1>",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_TODAY: IntlKeyValuePair = {
    key: "today",
    defaultValue: "Today",
    intlValue: "Today",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_CONTINUE_TO_LOGIN: IntlKeyValuePair = {
    key: "continueToLogin",
    defaultValue: "Continue to login",
    intlValue: "Continue to login",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_CONTINUE_TO_SIGN_UP: IntlKeyValuePair = {
    key: "continueToSignUp",
    defaultValue: "Continue to sign up",
    intlValue: "Continue to sign up",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_MINOR: IntlKeyValuePair = {
    key: "minor",
    defaultValue: "Minor",
    intlValue: "Minor",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_NOT_QUIET_OLD: IntlKeyValuePair = {
    key: "notQuietOld",
    defaultValue: "Whoops, you’re not quite old enough yet...",
    intlValue: "Whoops, you’re not quite old enough yet...",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_TERMS_OF_USE_REQUIRE_ADULT: IntlKeyValuePair = {
    key: "termsOfUseRequireAdult",
    defaultValue:
        "Our new terms of use require you to meet the online age restrictions of your particular region. You will be unable to proceed in the sign-up process today, however, we hope to see you in the near future when you meet the minimum age limit to sign-up. We apologise for the inconvenience.",
    intlValue:
        "Our new terms of use require you to meet the online age restrictions of your particular region. You will be unable to proceed in the sign-up process today, however, we hope to see you in the near future when you meet the minimum age limit to sign-up. We apologise for the inconvenience.",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_SUPPORT_LINK: IntlKeyValuePair = {
    key: "supportLink",
    defaultValue: "For any further assistance, please contact our <1>support team</1>",
    intlValue: "For any further assistance, please contact our <1>support team</1>",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_COMEBACK_LATER: IntlKeyValuePair = {
    key: "comebackLater",
    defaultValue: "I’ll come back another time!",
    intlValue: "I’ll come back another time!",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_VERIFY_AGE: IntlKeyValuePair = {
    key: "verifyAge",
    defaultValue: "Verify your age",
    intlValue: "Verify your age",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_DATE_OF_BIRTH: IntlKeyValuePair = {
    key: "dateOfBirth",
    defaultValue: "Date of birth",
    intlValue: "Date of birth",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_NOT_QUIET_OLD_ENOUGH: IntlKeyValuePair = {
    key: "notQuietOldEnough",
    defaultValue: "Sorry, you’re not quite old enough",
    intlValue: "Sorry, you’re not quite old enough",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_AGE_REQUIREMENTS: IntlKeyValuePair = {
    key: "ageRequirements",
    defaultValue:
        "We're sorry, but you do not meet the age requirements to sign up today. Please check the <1>institution's policy</1> for more details.",
    intlValue:
        "We're sorry, but you do not meet the age requirements to sign up today. Please check the <1>institution's policy</1> for more details.",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_NEED_ASSISTANCE: IntlKeyValuePair = {
    key: "needAssistance",
    defaultValue: "If you need assistance, please contact our <1>support team</1>.",
    intlValue: "If you need assistance, please contact our <1>support team</1>.",
    namespace: IntlNamespaces.VERIFY_AGE,
};

export const INTL_GOT_IT: IntlKeyValuePair = {
    key: "gotIt",
    defaultValue: "Got it",
    intlValue: "Got it",
    namespace: IntlNamespaces.VERIFY_AGE,
};
