import gql from "graphql-tag";

const applicantFragment = gql`
    fragment ApplicantFull on ApplicantField {
        id
        phoneNumber
        universitiesInterested {
            id
            name
            slug
        }
        applicantUniversities {
            university {
                id
                slug
                name
            }
            applied
            setupComplete
            delinked
            hasAcceptedMarketing
            dateOfEntry
            degreeLevel {
                id
                name
            }
            degreesInterested {
                id
                name
            }
            customAttributes(active: true) {
                value
                attribute {
                    id
                    name
                    type
                }
                ... on SingleChoiceValueField {
                    option {
                        id
                        value
                    }
                }
                ... on OpenTextFieldValueField {
                    text
                }
                ... on DateValueField {
                    date
                }
            }
        }
        preferences {
            newMessageNotifications
        }
        marketplaces {
            id
            slug
            userData {
                yearOfEntry
                custom
                id
                degreeCategories
                marketingConsentAccepted
                personalDataCollectionAccepted
            }
        }
        ageVerificationInfo {
            countryCode
            consentAgeForCountry
            age
            isMinor
            dateOfBirth
        }
        hasReadChatRules
        localePreference {
            id
            locale
            name
        }
    }
`;

export default applicantFragment;
