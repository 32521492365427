import PropTypes from "prop-types";
import React from "react";
import ProgressBarPlus from "react-progress-bar-plus";

const ProgressBar = (props) => {
    const style = props.start === true ? {display: "block"} : {display: "none"};
    return (
        <div style={style} id="loading">
            <ProgressBarPlus
                percent={props.start ? 0 : 100}
                autoIncrement={props.start}
                intervalTime={Math.random() * 100}
                spinner={false}
            />
            {props.start ? <div id="loadingIndicator" /> : null}
        </div>
    );
};

ProgressBar.propTypes = {
    start: PropTypes.bool.isRequired,
};

export default ProgressBar;
