import gql from "graphql-tag";
import chatGroupFragment from "../../Chat/fragments/chatGroupFragment";

const InboxQuery = gql`
    query Inbox($universitySlug: String, $allowDelinked: Boolean = false) {
        applicantInbox(universitySlug: $universitySlug, allowDelinked: $allowDelinked) {
            chatGroups {
                ...ChatGroup
                creationSource {
                    id
                    type
                    slug
                    name
                }
            }
            universityClearingChatGroup {
                ...ChatGroup
            }
        }
    }
    ${chatGroupFragment}
`;

export default InboxQuery;
