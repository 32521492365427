import React from "react";
import {View} from "@unibuddy/patron";
import {useStringFeatureFlag} from "ub-feature-flagging-react";

import {useHistory} from "react-router";
import {INTRO_PAGE_CONTENT_TYPE} from "shared/constants";
import {IntroPageCardsContainer} from "../components/CardExperiment/IntroPageCardsContainer";
import {IntroPageHeader} from "../components/CardExperiment/IntroPageHeader";
import {SearchPageContainer} from "../components/CardExperiment/SearchPageContainer";

const enum IntroPageContentType {
    CARDS = "cards",
    SEARCH = "search",
    NONE = "none",
}

export function IntroPage({
    setUserHasSeenIntroPage,
}: {
    setUserHasSeenIntroPage: (b: boolean) => void;
}) {
    const introPageContentType = useStringFeatureFlag(
        INTRO_PAGE_CONTENT_TYPE.key,
        INTRO_PAGE_CONTENT_TYPE.value,
    );
    const history = useHistory();

    switch (introPageContentType) {
        case IntroPageContentType.CARDS:
            return (
                <View gap="large" paddingY={["medium", "large", "xlarge"]}>
                    <IntroPageHeader />
                    <IntroPageCardsContainer setUserHasSeenIntroPage={setUserHasSeenIntroPage} />
                </View>
            );
        case IntroPageContentType.SEARCH:
            return (
                <View gap="large" paddingY={["medium", "large", "xlarge"]}>
                    <SearchPageContainer setUserHasSeenIntroPage={setUserHasSeenIntroPage} />
                </View>
            );
        default:
            history.replace(window.location.pathname.replace("/intro", "/students"));
            return null;
    }
}
