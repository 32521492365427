import {getenv} from "../../../../util/getEnv";
import {getHexColor} from "../../../../util/getHexColor/getHexColor";
import {useUniversity} from "../UniversityProvider";

export const THEME_PRIMARY_FALLBACK = "#0171e0";

export default function useUniversityColor() {
    const {color} = useUniversity();
    const themeColor = color || (getenv("THEME_PRIMARY", THEME_PRIMARY_FALLBACK) as string);
    return getHexColor(themeColor);
}
