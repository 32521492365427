import React from "react";
import {Stack, Text} from "@unibuddy/patron";

export type CardBodySectionProps = {
    heading?: string;
    content: string | React.ReactNode;
};

export const CardBodySection = ({heading, content}: CardBodySectionProps) => {
    return (
        <Stack space="small">
            {heading && (
                <Text as="h3" size="small" color="grey600">
                    {heading}
                </Text>
            )}
            {typeof content === "string" ? <Text size="small">{content}</Text> : content}
        </Stack>
    );
};
