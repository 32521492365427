import React from "react";
import {Box} from "@unibuddy/patron";
import PropTypes from "prop-types";
import s from "./BrandedHeader.pcss";
import {AppContainer} from "../Containers/AppContainer";

const BrandedHeader = (props) => (
    <Box h="70px" bgColor={`#${props.colourCode}`} display="flex">
        <AppContainer display="flex" alignItems="center" justifyContent="space-between" flex="1">
            <img className={s.logo} src={props.logo} alt="University Logo" />
            <a id={"view-website"} className={s.link} href={props.websiteUrl}>
                View on website
            </a>
        </AppContainer>
    </Box>
);

BrandedHeader.propTypes = {
    logo: PropTypes.string.isRequired,
    websiteUrl: PropTypes.string.isRequired,
    colourCode: PropTypes.string,
};

export default BrandedHeader;
