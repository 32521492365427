import get from "lodash/get";
import isArray from "lodash/isArray";
import startCase from "lodash/startCase";
import {rootURL} from "shared/Utils/WhereAmI";
import queryString from "qs";
import React, {Component} from "react";
import compose from "lodash/flowRight";
import {graphql} from "react-apollo";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import {Box, Shimmer, VisuallyHidden} from "@unibuddy/patron";
import {withTranslation} from "@unibuddy/intl";

import RecordAnalytics from "shared/Analytics/RecordAnalytics";
import Auth from "shared/Auth/auth";
import withAuth from "shared/Auth/withAuth/withAuth";
import {withUniversityTheme} from "shared/Styling/hocs/withTheme";
import {isFeatureEnabled} from "shared/Utils/FeatureUtils";
import PageHeader from "shared/Shared/components/PageHeader/PageHeader";
import MentorPlaceholder from "shared/Mentor/components/MentorPlaceholder/MentorPlaceholder";
import ErrorDisplay from "shared/General/components/ErrorDisplay/ErrorDisplay";
import withAnalytics from "shared/AnalyticsNew/withAnalytics/withAnalytics";
import ForwardLoader from "shared/General/components/ForwardLoader/ForwardLoader";
import {ErrorReportingContext} from "shared/General/components/ErrorReporting/ErrorReportingProvider";
import {getParameterByName} from "shared/Utils/UrlUtils";

import {PageTitle} from "../../../../../components/PageTitle/PageTitle";
import sequenceFilterMentorListQuery from "../../queries/sequenceFilterMentorListQuery";
import * as actions from "../../actions/mentorFilterActions";
import {ActivityStat} from "../../components/ActivityStat/ActivityStat";
import {MatchMeButton} from "../../components/MatchMeButton/MatchMeButton";
import MentorFilterLabels from "../../components/MentorFilterLabels/MentorFilterLabels";
import MentorFilters, {MentorFiltersLoading} from "../../components/MentorFilters/MentorFilters";
import MentorPreviewCard from "../../components/MentorPreviewCard/MentorPreviewCard";
import s from "./MentorList.pcss";
import {IntlNamespaces} from "../../../../../translations/types";

const placeholders = [
    <MentorPlaceholder className={s.mentorPreviewCard} key={1} wide />,
    <MentorPlaceholder className={s.mentorPreviewCard} key={2} wide />,
    <MentorPlaceholder className={s.mentorPreviewCard} key={3} wide />,
    <MentorPlaceholder className={s.mentorPreviewCard} key={4} wide />,
];

const MENTOR_CARD_FETCH_LIMIT = 16;

export const MentorListPageHeader = ({
    finishedLoading,
    buddyGroupType,
    pageTitle,
    totalMessages,
    uniLaunchDate,
}) => {
    const displayActivityStat = totalMessages > 100;

    return (
        <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            justifyContent={displayActivityStat ? "space-between" : "flex-end"}
        >
            <VisuallyHidden>
                <PageHeader title={pageTitle} />
            </VisuallyHidden>
            {displayActivityStat && (
                <Box paddingBottom="gutter" id="thebox">
                    {finishedLoading ? (
                        <ActivityStat
                            buddyGroupType={buddyGroupType}
                            totalMessages={totalMessages}
                            uniLaunchDate={uniLaunchDate}
                        />
                    ) : (
                        <Shimmer style={{width: "100%", height: 22}} height={22} width="550px">
                            <rect x="0" y="0" rx="12" ry="12" width="100%" height="22" />
                        </Shimmer>
                    )}
                </Box>
            )}
        </Box>
    );
};

export class MentorListComponent extends Component {
    static contextType = ErrorReportingContext;

    state = {
        mentors: [],
        filters: [],
        mentorSeed: null,
        fetchSize: MENTOR_CARD_FETCH_LIMIT,
        loadMentorsFromState: false, // used in getDerivedStateFromProps to help decide from where to use mentor data (state or props)
    };

    componentDidMount() {
        const degreeId = get(this, "props.match.params.degreeId");
        if (degreeId) {
            this.props.dispatch(actions.addMentorFilter("degree", "Degree", degreeId));
            RecordAnalytics.page(this.props, "Course Widget - Buddies Page");
        } else {
            RecordAnalytics.page(this.props, "Buddies Page"); // Legacy Event
            this.props.analytics.events.pageViewed({
                prefix: this.props.analytics.pageNames.buddiesList,
            });
        }

        const template = getParameterByName(this.props.location.search, "template") || "unknown";
        const channel = getParameterByName(this.props.location.search, "channel") || "unknown";

        this.props.analytics.events.buddiesListViewed({
            buddyType: "mentor",
            template,
            channel,
        });

        const values = queryString.parse(this.props.location.search.substr(1));

        if (values.degree) {
            this.props.dispatch(actions.addMentorFilter("degree", values.label, values.degree));
        }

        if (values.level) {
            this.props.dispatch(
                actions.addMentorFilter("degree_level", values.label, values.level),
            );
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        /* Currently in this component, mentors are being fetched at two places:
         * 1. On component load or after applying filters, graphql HOC fires a query and updates props.
         * 2. On clicking "Load More", an ad-hoc query updates the mentors in component's state.
         * To manage things better, a redesign of this component is required, which strictly updates either only props
         * or only state. Till that happens, we are maintaining a bool state variable called `loadMentorsFromState`,
         * which tells us whether to load mentors from state or not (its set to true when "Load more" is clicked).
         * If false, we will use mentors from props. */

        // Mentors are updated in state by the Load More functionality, so we return data from state
        if (prevState.loadMentorsFromState === true) {
            return {
                mentors: prevState.mentors,
                filters: prevState.filters,
                loadMentorsFromState: false, // Now that we know to read from state, reset this var to default value
            };
        }
        // For graphql HOC calls, mentors will be updated in props, so we return data from props
        return {
            mentors: nextProps.mentors,
            filters: nextProps.filters,
            fetchSize: get(nextProps, "mentors", []).length,
            mentorSeed: get(nextProps, "mentorSeed", null),
        };
    }

    onRemoveFilter = (filterKey, label, value) => {
        this.props.dispatch(actions.removeMentorFilter(filterKey, label, value));
    };

    onSeeMoreClicked(mentorId) {
        RecordAnalytics.event(this.props, "Buddy Card See More Clicked", {mentorId});
    }

    onAvatarClicked(mentorId) {
        RecordAnalytics.event(this.props, "Buddy Card Avatar Clicked", {mentorId});
    }

    onMatchMeClick() {
        RecordAnalytics.event(this.props, "Match Me Button Clicked");
        this.props.history.push(`${rootURL(this.props.location.pathname)}/auth/matchme/register`);
    }

    getTotalMessages = () => {
        if (get(this.props, "match.params.buddyGroupType") === "staff") {
            // TO DO: stub for post inbox implementation
            return get(this.props, "university.staffMessagesCount", 0);
        }
        return get(this.props, "university.mentorMessagesCount", 0);
    };

    openChat(mentorId, buddyPosition) {
        RecordAnalytics.event(this.props, "Buddy Card Chat Now Clicked", {mentorId}); // Legacy
        this.props.analytics.events.itemClicked({
            prefix: this.props.analytics.itemNames.buddyCardChatNow,
            id: mentorId,
        });

        const params = new URLSearchParams(window.location.search);
        params.set("buddyPosition", (buddyPosition ?? "").toString());

        this.props.history.push(
            `${rootURL(this.props.location.pathname)}/inbox/chatwith/${mentorId}?${params}`,
        );
    }

    addFilter(filter, {label, value}) {
        RecordAnalytics.event(this.props, `Filtered Buddies by ${filter.label}`, {label}); // Legacy
        this.props.analytics.events.buddiesFiltered({
            filterKey: filter.label,
            filterValue: label,
            buddyType: "mentor",
        });
        this.props.dispatch(actions.addMentorFilter(filter.id, label, value));
    }

    hasFinishedLoading = () => !this.props.loading || this.props.mentors.length > 0;

    updateMentorsList = (mentors, mentorSeed) => {
        this.setState((state) => {
            return {
                mentors: [...state.mentors, ...mentors],
                fetchSize: mentors.length,
                mentorSeed,
                loadMentorsFromState: true,
            };
        });
    };

    fetchMoreMentors = async (sequenceFilter) => {
        const isDegreeTypesEnabled = isFeatureEnabled("enableDegreeTypes", this.props.university);

        try {
            const {data} = await this.props.client.query({
                query: sequenceFilterMentorListQuery,
                variables: {
                    universitySlug: this.props.match.params.universitySlug,
                    sequenceFilter,
                    offset: this.state.mentors.length,
                    limit: MENTOR_CARD_FETCH_LIMIT,
                    mentorSeed: this.state.mentorSeed,
                    // Apply degree filter if degreeId is present in URL
                    // eslint-disable-next-line no-unneeded-ternary
                    applyDegreeFilter: get(this.props, "match.params.degreeId") ? true : false,
                    isDegreeTypesEnabled,
                },
            });

            this.updateMentorsList(
                get(data, "sequenceFilterMentorList.mentors", []),
                get(data, "sequenceFilterMentorList.mentorSeed", null),
            );
        } catch (e) {
            this.context.reportError(e);
        }
    };

    renderBuddiesCards = () => {
        if (this.state.mentors.length > 0) {
            if (get(this.props, "match.params.buddyGroupType") === "staff") {
                return null;
            }
            return this.renderMentorCards();
        }
        return <div className={s.informationText}>Sorry, no students found.</div>;
    };

    renderMentorCards() {
        const degreeId = get(this, "props.match.params.degreeId");
        const loggedIn = Auth.loggedIn(this.props.authState);
        const cards = this.state.mentors.map((mentor, i) => (
            <MentorPreviewCard
                key={mentor.id}
                mentor={mentor}
                onChatNow={this.openChat.bind(this, mentor.id, i + 1)}
                onSeeMoreClicked={this.onSeeMoreClicked.bind(this)}
                onAvatarClicked={this.onAvatarClicked.bind(this)}
                rootUrl={rootURL(this.props.location.pathname)}
                isMatched={loggedIn && !this.state.mentorSeed && i === 0}
                showShareButton={false}
                buddyPosition={i + 1}
                university={this.props.university}
                universityName={
                    this.state.filters.find((filter) => filter.id === "institution") &&
                    get(mentor, "university.name", "")
                }
            />
        ));

        if (!loggedIn && !degreeId) {
            // insert match me button into list of nodes
            cards.splice(
                2,
                0,
                <div className={s.matchMeContainer} key="matchMeButton">
                    <MatchMeButton
                        className={s.matchMeButton}
                        onClick={this.onMatchMeClick.bind(this)}
                        id="matchMe"
                    />
                </div>,
            );
        }

        return cards;
    }

    render() {
        const degreeId = get(this, "props.match.params.degreeId");
        const anyFilters = this.props.mentorFilters && this.props.mentorFilters.length > 0;
        const finishedLoading = this.hasFinishedLoading();
        const totalMessages = this.getTotalMessages();
        const uniLaunchDate = get(this.props, "university.launchDate", null);
        // It seems people have cached/bookmarked buddy profile pages
        // Redirecting such pages to students by default
        const buddyGroupType = get(this.props, "match.params.buddyGroupType", "students");
        if (!["staff", "students", "alumni"].includes(buddyGroupType)) {
            return (
                <Redirect
                    to={`${rootURL(
                        get(this.props, "location.pathname", ""),
                    )}/buddies/students/${buddyGroupType}`}
                />
            );
        }

        const sequenceFilter = this.props.mentorFilters.map((filter) => ({
            field: filter.field,
            value: filter.value,
        }));

        const showLoadButton = this.state.fetchSize >= MENTOR_CARD_FETCH_LIMIT;
        const pageTitle = this.props.t(buddyGroupType, startCase(buddyGroupType));

        return (
            <ErrorDisplay error={this.props.mentorsQueryError}>
                <div id="mentors-container">
                    <PageTitle title={this.props.t(buddyGroupType, startCase(buddyGroupType))} />
                    <div>
                        <div>
                            <MentorListPageHeader
                                finishedLoading={finishedLoading}
                                buddyGroupType={buddyGroupType}
                                pageTitle={pageTitle}
                                totalMessages={totalMessages}
                                uniLaunchDate={uniLaunchDate}
                            />
                        </div>
                        {!degreeId && (
                            <div className={s.headerItem}>
                                {finishedLoading ? (
                                    <MentorFilters
                                        filters={this.state.filters}
                                        addFilter={this.addFilter.bind(this)}
                                        university={this.props.university}
                                    />
                                ) : (
                                    <MentorFiltersLoading />
                                )}
                            </div>
                        )}

                        {!degreeId && anyFilters ? (
                            <div className={s.headerItem}>
                                <MentorFilterLabels
                                    filters={this.props.mentorFilters}
                                    labelStyle={"default"}
                                    onRemove={this.onRemoveFilter}
                                />
                            </div>
                        ) : null}
                    </div>
                    <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <div className={s.mentorPreviewCardsContainer}>
                            {finishedLoading ? this.renderBuddiesCards() : placeholders}
                        </div>

                        <Box marginTop="small">
                            <ForwardLoader
                                fetchMore={() => this.fetchMoreMentors(sequenceFilter)}
                                showLoadButton={showLoadButton}
                            />
                        </Box>
                    </Box>
                </div>
            </ErrorDisplay>
        );
    }
}

const mapStateToProps = (state) => ({
    mentorFilters: state.mentorFilterState.filters,
});

const sequenceFilterMentorListOptions = {
    name: "mentorListQuery",
    options(props) {
        const isDegreeTypesEnabled = isFeatureEnabled("enableDegreeTypes", props.university);
        const sequenceFilter = props.mentorFilters.map((filter) => {
            /* In case filter value is an array, we use a different input field called 'valueList'
             * instead of 'value' as graphql won't support multiple input types for the same field. */
            if (isArray(filter.value)) {
                return {
                    field: filter.field,
                    valueList: filter.value,
                };
            }
            return {
                field: filter.field,
                value: filter.value,
            };
        });
        return {
            variables: {
                universitySlug: props.match.params.universitySlug,
                sequenceFilter,
                limit: MENTOR_CARD_FETCH_LIMIT,
                // Apply degree filter if degreeId is present in URL
                // eslint-disable-next-line no-unneeded-ternary
                applyDegreeFilter: get(props, "match.params.degreeId") ? true : false,
                isDegreeTypesEnabled,
            },
            fetchPolicy: "cache-and-network",
        };
    },
    skip(props) {
        return !["students", "buddies"].includes(get(props, "match.params.buddyGroupType"));
    },
    props({mentorListQuery: {loading, sequenceFilterMentorList, refetch, error}}) {
        return {
            loading,
            mentors: get(sequenceFilterMentorList, "mentors", []),
            filters: get(sequenceFilterMentorList, "filters", []),
            mentorSeed: get(sequenceFilterMentorList, "mentorSeed", null),
            refetch,
            mentorsQueryError: error,
        };
    },
};

export default compose(
    withRouter,
    connect(mapStateToProps),
    withAuth,
    withAnalytics,
    withTranslation(IntlNamespaces.COMMON),
    withUniversityTheme(),
    graphql(sequenceFilterMentorListQuery, sequenceFilterMentorListOptions),
)(MentorListComponent);
