import React from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import classNames from "classnames";
import Icon from "../Icon/Icon";
import s from "./BackButton.pcss";

const BackButton = ({id, onBack, className, text, label}) => {
    const hasText = text.length > 0;
    return (
        <button
            id={id}
            onClick={onBack}
            className={classNames(className, s.button)}
            aria-label={label || "Back"}
        >
            <Icon
                name="filled_return_icon"
                width={16}
                height={hasText ? 16 : 24}
                className={s.arrow}
                aria-hidden="true"
            />
            {hasText && <span className={s.text}>{text}</span>}
        </button>
    );
};

BackButton.propTypes = {
    onBack: PropTypes.func,
    text: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
};

BackButton.defaultProps = {
    onBack: noop,
    id: "back",
    text: "Back",
    className: "",
};

export default BackButton;
