import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./PageHeader.pcss";
import {SkipNavContent} from "../../../Experimental/A11y/components/SkipNav/SkipNav";

const PageHeader = ({leftContent, rightContent, title, className, ...rest}) => {
    return (
        <SkipNavContent id={"content"}>
            <div className={classNames(s.container, className)} {...rest}>
                {leftContent && <div>{leftContent}</div>}
                <h1 className={s.title}>{title}</h1>
                {rightContent && <div>{rightContent}</div>}
            </div>
        </SkipNavContent>
    );
};

PageHeader.propTypes = {
    title: PropTypes.node.isRequired,
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    className: PropTypes.string,
};

PageHeader.defaultProps = {
    leftContent: null,
    rightContent: null,
    className: "",
};

export default PageHeader;
