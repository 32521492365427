import React from "react";
import EmptyState from "../EmptyState/EmptyState";
import Button from "../../../Experimental/General/components/Button/Button";
import forbiddenImage from "./403.png";
import notfoundImage from "./404.png";

const ErrorDisplay = ({error, actionButton, children}) => {
    if (error) {
        const emptyStateProps = {};
        let image = null;
        if (actionButton) {
            emptyStateProps.actionButton = (
                <Button onClick={actionButton.onClick}>{actionButton.text}</Button>
            );
        }
        if (error.message.includes("403 Forbidden")) {
            image = <img src={forbiddenImage} alt="403 Forbidden" />;
        } else if (error.message.includes("404 Not Found")) {
            image = <img src={notfoundImage} alt="404 Not Found" data-test-id="not-found-image" />;
        }
        return <EmptyState image={image} {...emptyStateProps} />;
    }
    return children;
};

export default ErrorDisplay;
