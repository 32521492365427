import React from "react";

import BackButton from "shared/General/components/BackButton/BackButton";
import PageHeader from "shared/Shared/components/PageHeader/PageHeader";
import {Box, Stack, TextBlock, Heading} from "@unibuddy/patron";

type AmbassadorRemovedCardProps = {
    university: {nickname?: string; name?: string};
    onBack: () => void;
};

export function AmbassadorRemovedCard({university, onBack}: AmbassadorRemovedCardProps) {
    return (
        <React.Fragment>
            <PageHeader leftContent={<BackButton text="All Buddies" onBack={onBack} />} />
            <Box padding="medium" backgroundColor="grey700">
                <Stack space="small">
                    <Heading level="4" color="white">
                        Sorry! This Ambassador is unavailable
                    </Heading>
                    <TextBlock color="white">
                        This Ambassador is no longer active on Unibuddy. They may have graduated, or
                        are taking a break. Feel free to chat with other ambassadors from{" "}
                        {university?.nickname || university?.name || "University"}!
                    </TextBlock>
                </Stack>
            </Box>
        </React.Fragment>
    );
}
