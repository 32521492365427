import gql from "graphql-tag";

const buddiesQuery = gql`
    query Buddies(
        $idIn: [String]
        $universitySlug: String
        $offset: Int
        $limit: Int
        $order: String
        $active: Boolean
        $role: String
        $staffFields: JSONString
        $customAttributesFilters: [CustomAttributesFilterInputField]
        $randomizeListSeed: Int
    ) {
        buddies(
            idIn: $idIn
            universitySlug: $universitySlug
            offset: $offset
            limit: $limit
            order: $order
            active: $active
            role: $role
            staffFields: $staffFields
            customAttributesFilters: $customAttributesFilters
            randomizeListSeed: $randomizeListSeed
            useRandomizeStaffFeature: true
        ) {
            count
            users {
                id
                firstName
                profilePhoto(raw: true)
                staffFields {
                    bio
                    department
                    role
                }
            }
            randomizeListSeed
        }
    }
`;

export default buddiesQuery;
