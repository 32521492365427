import gql from "graphql-tag";

const updateApplicantMutation = gql`
    mutation UpdateApplicantMutation(
        $phoneNumber: String
        $email: String
        $firstName: String
        $lastName: String
        $country: String
        $domesticRegion: String
        $degrees: [String]
        $degreeLevel: String
        $acceptMarketing: Boolean
        $hasReadChatRules: Boolean
        $universitySlug: String
        $customAttributes: [CustomAttributeValueInput]
        $ageVerificationInfo: AgeVerificationInfoInputField
        $localePreference: UserLocalePreferenceInput
    ) {
        updateApplicant(
            phoneNumber: $phoneNumber
            email: $email
            firstName: $firstName
            lastName: $lastName
            country: $country
            domesticRegion: $domesticRegion
            degrees: $degrees
            degreeLevel: $degreeLevel
            acceptMarketing: $acceptMarketing
            hasReadChatRules: $hasReadChatRules
            universitySlug: $universitySlug
            customAttributes: $customAttributes
            ageVerificationInfo: $ageVerificationInfo
            localePreference: $localePreference
        ) {
            me {
                anyUser {
                    id
                    email
                    firstName
                    lastName
                    country {
                        id
                        name
                    }
                    domesticRegion {
                        id
                        name
                    }
                }
                applicant {
                    id
                    phoneNumber
                    preferences {
                        newMessageNotifications
                    }
                    applicantUniversities {
                        university {
                            id
                            slug
                        }
                        applied
                        setupComplete
                        hasAcceptedMarketing
                        degreeLevel {
                            id
                            name
                        }
                        delinked
                        degreesInterested {
                            id
                            name
                        }
                        customAttributes(active: true) {
                            value
                            attribute {
                                id
                                name
                                type
                            }
                            ... on SingleChoiceValueField {
                                option {
                                    id
                                    value
                                }
                            }
                            ... on OpenTextFieldValueField {
                                text
                            }
                            ... on DateValueField {
                                date
                            }
                        }
                    }
                    hasReadChatRules
                    ageVerificationInfo {
                        countryCode
                        consentAgeForCountry
                        age
                        isMinor
                        dateOfBirth
                    }
                    localePreference {
                        id
                        locale
                        name
                    }
                }
            }
            error
        }
    }
`;

export default updateApplicantMutation;
