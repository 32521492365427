import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {autoRehydrate} from "redux-persist";
import {routerReducer} from "react-router-redux";
import rootReducer from "./rootReducer";

export default function configureStore(preloadedState) {
    // set middleware
    const middlewares = [thunk];

    const store = createStore(
        combineReducers({
            ...rootReducer,
            routing: routerReducer,
        }),
        preloadedState,
        compose(applyMiddleware(...middlewares), autoRehydrate()),
    );

    if (module.hot && process.env.NODE_ENV !== "production") {
        // Enable webpack hot module replacement for reducers
        module.hot.accept("./rootReducer", () => store.replaceReducer(rootReducer));
    }

    return store;
}
