/**
 * Checks that the list of requiredFields is included in properties object
 * @param properties
 * @param requiredFields
 * @returns {Array.<*>}
 */
export function getMissingFields(properties, requiredFields = []) {
    return requiredFields.filter(
        (field) => properties[field] === undefined || properties[field] === null,
    );
}

/**
 * String interpolation to return error message when an event is dispatched
 * with missing fields required by the event spec.
 * @param eventName
 * @param missingFields
 * @returns {string}
 */
export function getErrorMessage(eventName, missingFields) {
    return `Event ${eventName} is missing required fields ${JSON.stringify(missingFields)}`;
}

/**
 * Function looks at eventSpec (object that defines an event, see productSpecification.js for example)
 * and ensures that all required fields are passed before dispatching the event. If fields are missing
 * then an error is thrown.
 * @param dispatchHandler
 * @param eventSpec
 * @returns {dispatchEvent}
 */
export function getDispatchForEvent(dispatchHandler, eventSpec) {
    function dispatchEvent(properties = {}) {
        const missingFields = getMissingFields(properties, eventSpec.requiredFields);
        if (missingFields.length > 0) {
            console.error(getErrorMessage(eventSpec.name, missingFields));
        }
        const prefix =
            eventSpec.requiredFields && eventSpec.requiredFields.includes("prefix")
                ? `${properties.prefix} `
                : "";
        const eventName = `${prefix}${eventSpec.name}`;
        dispatchHandler(eventName, properties);
    }

    return dispatchEvent;
}

/**
 * Iterates through a key-value object of sharedEvents
 * Creates and returns a new object with the same sharedEvent key as the key, and
 * the dispatch function as the value  after getting it from getDispatchForEvent
 * @param dispatchHandler
 * @param sharedEventsSpec
 * @param productEvents: optional, object of events specific to a product.
 */
export const getEvents = (dispatchHandler, sharedEventsSpec, productEvents) => {
    const allEvents = {...sharedEventsSpec, ...productEvents};
    return Object.keys(allEvents).reduce((events, key) => {
        const eventSpec = allEvents[key];
        return {...events, [key]: getDispatchForEvent(dispatchHandler, eventSpec)};
    }, {});
};
