/* Patron dimensions */
export const PATRON_INPUT_HEIGHT = 50;

/* Patron colours */
export const PATRON_INPUT_BACKGROUND_COLOUR = "#F5F5F5";
export const PATRON_INPUT_PLACEHOLDER_COLOUR = "#5D5F70";

/* Colours that work well together */
export const ACCESSIBLE_BLUE = "#0582FF";
export const ACCESSIBLE_BLACK = "#333333";
export const ACCESSIBLE_TRANSPARENT = "transparent";
export const ACCESSIBLE_WHITE = "#FFFFFF";
export const ACCESSIBLE_GREY = "#CCCCCC";
export const ACCESSIBLE_DARK_BLUE = "#2672BD";
