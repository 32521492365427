import {Maybe} from "../types/generated";
import {getenv} from "./getEnv";

export function universityCreateVisitorLogoUrl(logoUrl?: string): Maybe<string> {
    if (!logoUrl) {
        return null;
    }

    const ubService = getenv("UB_SERVICE") as string;
    const url = new URL(logoUrl);
    const split = url.pathname.split("/");
    split[0] = split[1];
    split[1] = ubService;
    return `${url.origin}/${split.join("/")}`;
}
