import React from "react";
import styled from "styled-components";
import {useTranslation} from "@unibuddy/intl";

type MatchMeButtonProps = {
    onClick: () => void;
    className?: string;
    id?: string;
};

const StyledButton = styled.button`
    height: 44px;
    background: white;
    color: var(--theme-primary);
    border: 1px solid var(--theme-primary);
    border-radius: 6px;
    transition: 0.25s all ease-in-out;

    &:hover {
        background-color: var(--theme-primary);
        color: white;
    }
`;

export function MatchMeButton({onClick, className, id}: MatchMeButtonProps) {
    const {t} = useTranslation("mentorCard");

    return (
        <StyledButton className={className} onClick={onClick} id={id}>
            {t("matchAmbassador", "Match me with an ambassador")}
        </StyledButton>
    );
}
