import * as React from "react";
import {SVGProps} from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const {color = "#0171E0", ...rest} = props;
    return (
        <svg
            width={17}
            height={17}
            fill="none"
            viewBox="0 0 17 17"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M8.667 4.333A1.667 1.667 0 0 1 10.333 6v4.008a1.667 1.667 0 0 1-1.666 1.666H4a1.667 1.667 0 0 1-1.667-1.666V6A1.667 1.667 0 0 1 4 4.333h4.667Zm0-1H4A2.667 2.667 0 0 0 1.333 6v4.008A2.667 2.667 0 0 0 4 12.674h4.667a2.667 2.667 0 0 0 2.666-2.666V6a2.667 2.667 0 0 0-2.666-2.667Z"
                fill={color}
            />
            <path
                d="M13.34 5.414v5.191l-2-1.569V7.021l2-1.607Zm.332-1.364a.655.655 0 0 0-.414.149L10.59 6.342a.666.666 0 0 0-.248.52v2.333a.667.667 0 0 0 .255.525l2.666 2.092a.658.658 0 0 0 .88-.052.667.667 0 0 0 .196-.472v-6.57a.667.667 0 0 0-.667-.666V4.05Z"
                fill={color}
            />
        </svg>
    );
};

export default SvgComponent;
