import React from "react";
import PropTypes from "prop-types";
import s from "./MentorCardDegree.pcss";

const MentorCardDegree = (props) => <div className={s.degree}>{props.degree}</div>;

MentorCardDegree.propTypes = {
    degree: PropTypes.string.isRequired,
};

export default MentorCardDegree;
