import get from "lodash/get";
import requireAuth from "shared/Auth/requireAuth";

const requireWidgetAuth = requireAuth({
    accountRole: "applicant",
    reqVerification: true,
    redirectPath(props) {
        const universitySlug = get(props, "match.params.universitySlug");
        const colourCode = get(props, "match.params.colourCode");
        const degreeId = get(props, "match.params.degreeId");
        const chatGroupId = get(props, "match.params.chatGroupId", false);
        let redirectUrl = `/embed/${universitySlug}/colour/${colourCode}/auth/register`;
        const pwa = props.match.path.includes("pwa");
        // if its geodude, output geodude rootUrl
        if (chatGroupId && !pwa) {
            //
            // When a mentor sends a message to the prospect, he is notified
            // on his email, sms and whatsapp. This notification contains
            // a link to his inbox in the form /inbox/:chatGroupId
            // So when a link of that form is clicked, we know for sure
            // that the link is the one generated by one of our notifications.
            // In this scenario, when the prospect clicks on the link and is not
            // logged in, we want to redirect him to the auth login url.
            //
            redirectUrl = `/embed/${universitySlug}/colour/${colourCode}/auth/login`;
        } else if (degreeId && props.match.path.includes("course")) {
            redirectUrl = `/embed/${universitySlug}/course/${degreeId}/auth/register`;
        } else if (pwa) {
            // if it's charmander, output correct rootUrl

            //
            // If chatGroupId is set, we know that the redirect url needs to be
            // auth login. Please refer to the JSDOc starting line 14.
            //
            redirectUrl = chatGroupId
                ? `/pwa/${universitySlug}/auth/login`
                : `/pwa/${universitySlug}/auth/register`;
        }
        return redirectUrl;
    },
    verifyRedirectPath(props) {
        const universitySlug = get(props, "match.params.universitySlug", "");
        const colourCode = get(props, "match.params.colourCode", "");
        const degreeId = get(props, "match.params.degreeId", "");
        const chatGroupId = get(props, "match.params.chatGroupId", false);
        let redirectPath = `/embed/${universitySlug}/colour/${colourCode}/auth/verify`;
        const pwa = props.match.path.includes("pwa");
        if (chatGroupId && !pwa) {
            redirectPath = `/embed/${universitySlug}/colour/${colourCode}/auth/verify`;
        } else if (degreeId && props.match.path.includes("course")) {
            redirectPath = `/embed/${universitySlug}/course/${degreeId}/auth/verify`;
        } else if (pwa) {
            redirectPath = `/pwa/${universitySlug}/auth/verify`;
        }
        return redirectPath;
    },
});

export default requireWidgetAuth;
