import useAuth from "shared/Auth/hooks/useAuth";
import {ApplicantUniversityLinkField, Maybe} from "src/types/generated";

export function useUniversityApplicantLink(
    universityId: string,
): Maybe<ApplicantUniversityLinkField> {
    const auth = useAuth() as {authState};
    const applicantUniversityLinks = auth?.authState?.me?.applicant?.applicantUniversities;
    if (applicantUniversityLinks) {
        const applicantUniversityLink: ApplicantUniversityLinkField = applicantUniversityLinks.find(
            (link) => link.university.id === universityId,
        );
        return applicantUniversityLink;
    }
}
