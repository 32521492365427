import * as React from "react";
import {SVGProps} from "react";

export const IconShare = (props: SVGProps<SVGSVGElement>) => {
    const {color = "currentColor", width = "1em", height = "1em", ...rest} = props;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.277 2.006c1.497-.092 2.497.832 3.253 1.66.64.701 1.507 1.714 1.469 3.225-.037 1.475-1.741 4.015-3.862 6.188l-.291-1.965c2.043-2.094 2.57-3.422 2.592-4.265.02-.806-.414-1.394-1.049-2.09-.72-.791-1.282-1.2-2.018-1.155-.811.05-2.086.662-4.147 2.774-.182.187-.39.388-.61.603-.825.799-1.839 1.783-2.451 2.893-.375.679-.54 1.31-.453 1.895.083.565.424 1.228 1.347 1.938l-.936 1.28c-1.16-.89-1.795-1.894-1.955-2.979-.158-1.066.166-2.068.637-2.922.744-1.348 2.006-2.566 2.829-3.36.19-.184.356-.345.488-.48m5.157-3.24c-1.423.088-3.054 1.086-5.157 3.24l5.157-3.24Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.723 21.994c-1.497.092-2.498-.832-3.253-1.66-.64-.701-1.507-1.714-1.469-3.225.037-1.475 1.741-4.015 3.862-6.188l.291 1.965c-2.043 2.094-2.57 3.422-2.592 4.265-.02.806.414 1.394 1.049 2.09.72.791 1.282 1.2 2.018 1.155.811-.05 2.086-.662 4.147-2.774.182-.187.39-.388.61-.602.825-.8 1.839-1.784 2.451-2.894.375-.679.54-1.31.453-1.895-.083-.565-.424-1.228-1.347-1.938l.936-1.28c1.16.89 1.795 1.894 1.955 2.979.158 1.066-.166 2.068-.637 2.922-.744 1.348-2.006 2.566-2.829 3.36-.19.184-.356.345-.488.48m-5.157 3.24c1.423-.088 3.054-1.086 5.157-3.24l-5.157 3.24Z"
                fill={color}
            />
        </svg>
    );
};
