import get from "lodash/get";
import withCSSVariables from "./withCSSVariables";

export const DEFAULT_THEME_VALUE = "687787";

export function withTheme(themeFunc) {
    return withCSSVariables([
        {
            cssName: "--theme-primary",
            value: themeFunc,
        },
    ]);
}

export function withConstantTheme(themeValue) {
    return withTheme(() => themeValue);
}

export function withRouteParamTheme() {
    return withTheme((props) => `#${get(props.match, "params.colourCode", DEFAULT_THEME_VALUE)}`);
}

export function withUniversityTheme(path = "university.colour") {
    return withTheme((props) => `#${get(props, path, DEFAULT_THEME_VALUE)}`);
}
