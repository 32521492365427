const colours = {
    stoneGrey: "#282B3C",
    smartGrey: "#5D5F70",
    softGrey: "#B6B7BE",
    inkWater: "#D3D4D8",
    solitude: "#D3D4D8",
    deepSolitude: "#F5F5F5",
    endeavour: "#013E83",
    deepSky: "#013E83",
    dodgerBlue: "#2B8DFD",
    redCloud: "#AD0101",
    freshLeaves: "#377A15",
    bodyColour: "#131622",
    lightBodyColour: "#5D5F70",
    errorColour: "#AD0101",
    primaryColour: "#0264D4",
};

export default colours;
