import gql from "graphql-tag";

export default gql`
    fragment MentorFull on MentorField {
        id
        university {
            id
            name
            slug
        }
        country {
            id
            name
            code
        }
        languages {
            id
            name
        }
        degree {
            id
            name
        }
        degrees {
            degreeType
            degree {
                id
                name
            }
        }
        schoolHistory {
            highSchool {
                id
                name
            }
            highSchoolType {
                id
                name
            }
        }
        degreeLevel
        year
        bio
        prospectWelcomeMessage
        phoneNumber
        profilePhoto
        stageOneComplete
        stageTwoComplete
        placeName
        placeId
        isOnline
        pastQualification
        societies {
            id
            name
        }
        interests {
            id
            name
        }
        favouriteModules {
            id
            name
        }
        customAttributes {
            attribute {
                id
                type
            }
            value
        }
        mentorFields {
            primaryFlag {
                name
                code
            }
            secondaryFlag {
                name
                code
            }
        }
    }
`;
