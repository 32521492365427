import {IntlKeyValuePair, IntlNamespaces} from "./types";

export const INTL_PHONE_NUMBER: IntlKeyValuePair = {
    key: "phoneNumber",
    defaultValue: "Please enter a phone number to receive SMS notifications.",
    intlValue: "Please enter a phone number to receive SMS notifications.",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_REQUIRED_FIELD: IntlKeyValuePair = {
    key: "requiredField",
    defaultValue: " is a required field",
    intlValue: " is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_FIRST_NAME: IntlKeyValuePair = {
    key: "firstName",
    defaultValue: "Your first name must be at least 2 characters long.",
    intlValue: "Your first name must be at least 2 characters long.",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_FIRST_NAME_REQUIRED: IntlKeyValuePair = {
    key: "firstNameRequired",
    defaultValue: "First Name is a required field",
    intlValue: "First Name is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_LAST_NAME: IntlKeyValuePair = {
    key: "lastName",
    defaultValue: "Your last name must be at least 2 characters long.",
    intlValue: "Your last name must be at least 2 characters long.",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_LAST_NAME_REQUIRED: IntlKeyValuePair = {
    key: "lastNameRequired",
    defaultValue: "Last Name is a required field",
    intlValue: "Last Name is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_EMAIL: IntlKeyValuePair = {
    key: "email",
    defaultValue: "Please enter a valid email address.",
    intlValue: "Please enter a valid email address.",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_EMAIL_ALREADY_EXISTS: IntlKeyValuePair = {
    key: "emailAlreadyExists",
    defaultValue: "Email already exists",
    intlValue: "Email already exists",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_EMAIL_REQUIRED: IntlKeyValuePair = {
    key: "emailRequired",
    defaultValue: "Email is a required field",
    intlValue: "Email is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_CODE_REQUIRED: IntlKeyValuePair = {
    key: "codeRequired",
    defaultValue: "Code is a required field",
    intlValue: "Code is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_PASSWORD_REQUIRED: IntlKeyValuePair = {
    key: "passwordRequired",
    defaultValue: "Password is a required field",
    intlValue: "Password is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_CONFIRM_PASSWORD_REQUIRED: IntlKeyValuePair = {
    key: "confirmPasswordRequired",
    defaultValue: "Confirm Password is a required field",
    intlValue: "Confirm Password is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_PASSWORD_MIN_CHAT: IntlKeyValuePair = {
    key: "passwordMinChat",
    defaultValue: (minChar: string) => `Password must be at least ${minChar} characters`,
    intlValue: "Password must be at least {{minChar}} characters",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_PASSWORD_MIN_CHAR_SHORT: IntlKeyValuePair = {
    key: "passwordMinCharShort",
    defaultValue: (minChar: string) => `Must be at least ${minChar} characters`,
    intlValue: "Must be at least {{minChar}} characters",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_CONFIRM_PASSWORD_MIN_CHAT: IntlKeyValuePair = {
    key: "confirmPasswordMinChat",
    defaultValue: (minChar: string) => `Confirm Password must be at least ${minChar} characters`,
    intlValue: "Confirm Password must be at least {{minChar}} characters",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_CONFIRM_PASSWORD_DOESNT_MATCH: IntlKeyValuePair = {
    key: "confirmPasswordDoesntMatch",
    defaultValue: "Confirm Password doesn't match",
    intlValue: "Confirm Password doesn't match",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_PRIVACY_CHECKED: IntlKeyValuePair = {
    key: "privacyChecked",
    defaultValue: "Privacy Policy must be checked",
    intlValue: "Privacy Policy must be checked",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_DATE_OF_ENTRY_REQUIRED: IntlKeyValuePair = {
    key: "dateOfEntryRequired",
    defaultValue: "Date of entry is a required field",
    intlValue: "Date of entry is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_COUNTRY: IntlKeyValuePair = {
    key: "country",
    defaultValue: "Please select a country.",
    intlValue: "Please select a country.",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_COUNTRY_REQUIRED: IntlKeyValuePair = {
    key: "countryRequired",
    defaultValue: "Country is a required field",
    intlValue: "Country is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_DOMESTIC_REGION_REQUIRED: IntlKeyValuePair = {
    key: "domesticRegionRequired",
    defaultValue: "Domestic region is a required field",
    intlValue: "Domestic region is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_DEGREE_LEVEL_REQUIRED: IntlKeyValuePair = {
    key: "degreeLevelRequired",
    defaultValue: "Degree level is a required field",
    intlValue: "Degree level is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_DEGREES_REQUIRED: IntlKeyValuePair = {
    key: "degreesRequired",
    defaultValue: "Area of study is a required field",
    intlValue: "Area of study is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_REGION: IntlKeyValuePair = {
    key: "region",
    defaultValue: "Please select a domestic region.",
    intlValue: "Please select a domestic region.",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_DATE_OF_BIRTH_REQUIRED: IntlKeyValuePair = {
    key: "dateOfBirthRequired",
    defaultValue: "Date of birth is a required field",
    intlValue: "Date of birth is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_LANGUAGE_PREFERENCE_REQUIRED: IntlKeyValuePair = {
    key: "languagePreferenceRequired",
    defaultValue: "Language preference is a required field",
    intlValue: "Language preference is a required field",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_NOTIFICATION_OPTION_REQUIRED: IntlKeyValuePair = {
    key: "notificationOptionRequired",
    defaultValue: "At least one notification option must be selected",
    intlValue: "At least one notification option must be selected",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_INVALID_EMAIL: IntlKeyValuePair = {
    key: "invalidEmail",
    defaultValue: "Please enter a valid email address.",
    intlValue: "Please enter a valid email address.",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_INVALID_EMAIL_OR_PASSWORD: IntlKeyValuePair = {
    key: "invalidEmailOrPassword",
    defaultValue: "You have entered an invalid email or password",
    intlValue: "You have entered an invalid email or password",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_UNIVERSITY_PRIVACY_CHECKED: IntlKeyValuePair = {
    key: "universityPrivacyChecked",
    defaultValue: (universityName: string) => `${universityName} Privacy Policy must be checked`,
    intlValue: "{{universityName}} Privacy Policy must be checked",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_UNIBUDDY_PRIVACY_CHECKED: IntlKeyValuePair = {
    key: "unibuddyPrivacyChecked",
    defaultValue: "Unibuddy's Privacy Policy must be checked",
    intlValue: "Unibuddy's Privacy Policy must be checked",
    namespace: IntlNamespaces.VALIDATIONS,
};

export const INTL_APPLICANTS_ONLY: IntlKeyValuePair = {
    key: "applicantsOnly",
    defaultValue: "Only applicants can login here.",
    intlValue: "Only applicants can login here.",
    namespace: IntlNamespaces.VALIDATIONS,
};
