import React, {useEffect, useCallback} from "react";
import RemoteComponent from "shared/Utils/RemoteComponent";
import {usePage} from "../../../PageProvider/PageContext";
import {WIDGET_PAGE} from "../../../PageProvider/PageTitles";
import {getenv} from "../../../../../util/getEnv";

const Blog = (props) => {
    const {setPage} = usePage();
    const remoteScope = "blogRemoteEntry.js";
    const GATEWAY_URL = getenv("GATEWAY_URL");
    const API_URL = getenv("API_URL");
    const ASSETS_URL = getenv("ASSETS_URL");
    const CONTENT_FEDERATED_MODULES_APP_URL = getenv("CONTENT_FEDERATED_MODULES_APP_URL");

    const setPageValue = useCallback(() => {
        setPage(WIDGET_PAGE.WIDGET_CONTENT);
    }, [setPage]);

    useEffect(setPageValue, []);

    return (
        <React.Fragment>
            <RemoteComponent
                url={`${CONTENT_FEDERATED_MODULES_APP_URL}/${remoteScope}`}
                remoteScope="blog_page_federation"
                remoteModule="./ProspectContent"
                nestedProps={{
                    ...props,
                    env: {
                        API_URL,
                        GATEWAY_URL,
                        ASSETS_URL,
                    },
                }}
            />
        </React.Fragment>
    );
};

export default Blog;
