import get from "lodash.get";
import ERRORS from "shared/Utils/ErrorConstantsUtils";

/**
 * Checks if apollo error has `ERRORS.AMBASSADOR_REMOVED`
 * @param {*} error Apollo error
 */
export const hasAmbassadorRemovedError = (error) => {
    let errorMessage = error;

    if (Array.isArray(error)) {
        errorMessage = get(error, "[0].message", "");
    }

    return `${errorMessage}`.includes(ERRORS.AMBASSADOR_REMOVED);
};
