import React, {createContext, useMemo, useCallback} from "react";
import get from "lodash/get";
import {connect} from "react-redux";
import {setMe, setToken, clearAuth} from "../actions/authActions";

export const AuthContext = createContext({});

/**
 * Creates an auth context so we can extract user information with hooks anywhere
 * in the component tree without the annoyance of using redux connect and mapStateToProps.
 *
 * @param {*} {children, authState}
 * @returns
 */
export function ReduxAuthProvider({children, authState, dispatch}) {
    const user = get(authState, "me.anyUser");
    const isVerified = !!(
        authState &&
        (authState.accessToken || authState.token) &&
        authState.me &&
        user?.verified
    );
    const isLoggedIn = !!(authState && (authState.accessToken || authState.token));
    const setAuth = useCallback(
        (newAuth) => {
            if (!newAuth.me) {
                dispatch(clearAuth());
            } else {
                dispatch(setMe(newAuth.me));
                dispatch(setToken(newAuth.token));
            }
        },
        [dispatch],
    );
    const value = useMemo(
        () => ({user, isVerified, isLoggedIn, authState, setAuth}),
        [user, authState, isVerified, isLoggedIn, setAuth],
    ); // we add both user and authState because some apps need one or the other, we want the context to always be consistent
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default connect(({authState}) => ({
    authState,
}))(ReduxAuthProvider);
