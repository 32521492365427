/**
 * This is the single source of truth for analytics events
 * Each event is assigned an object consisting of two properties
 * @property {string} name - the name of the analytics event
 * @property {[strings]} requiredFields- the name of the properties that must be specified
 * when recording the analytics event
 */

export const sharedEvents = {
    productOpened: {
        name: "Product Opened",
        requiredFields: ["name", "universitySlug", "ubLang"],
    },
    productLeft: {
        name: "Product Left",
        requiredFields: ["name", "universitySlug"],
    },
    productVisible: {
        name: "Product Visible",
        requiredFields: ["name", "universitySlug", "visibleTimeInMs", "visiblePercentage"],
    },
    // Business events
    buddyViewed: {
        name: "Buddy Viewed",
        requiredFields: ["id"],
    },
    buddiesListViewed: {
        name: "Buddies List Viewed",
    },
    buddiesListViewedMore: {
        name: "Buddies List Viewed More",
        requiredFields: ["pageNumber"],
    },
    buddiesFiltered: {
        name: "Buddies Filtered",
        requiredFields: ["filterKey", "filterValue"],
    },
    buddyShared: {
        name: "Buddy Shared",
        requiredFields: ["id"],
    },
    blogViewed: {
        name: "Blog Viewed",
        requiredFields: ["id"],
    },
    blogShared: {
        name: "Blog Shared",
        requiredFields: ["id"],
    },
    inboxViewed: {
        name: "Inbox Viewed",
        requiredFields: [
            "universityId",
            "universityName",
            "universitySlug",
            "isRecommendationPromptShown",
        ],
    },
    recommendationPromptClickedOnInboxTab: {
        name: "Recommendation Prompt Clicked On Inbox Tab",
        requiredFields: ["universityId", "universityName", "universitySlug"],
    },
    // Content Filters
    contentSearchTitle: {
        name: "Search Content by Title",
        requiredFields: ["title"],
    },
    contentSort: {
        name: "Sorting Content",
        requiredFields: ["sortOption"],
    },
    contentFilterAreaOfStudy: {
        name: "Filtered Content by Area of Study",
        requiredFields: ["areaOfStudyNames"],
    },
    contentFilterTopic: {
        name: "Filtered Content by Topic",
        requiredFields: ["topicNames"],
    },
    conversationStarted: {
        name: "Conversation Started",
    },
    messageSent: {
        name: "Message Sent",
        requiredFields: ["conversationId", "opponentId", "chatVersion"],
    },
    accountCreated: {
        name: "Prospect Account Created",
        requiredFields: ["accountRole"],
    },
    prospectSignedUp: {
        name: "Prospect Signed Up",
    },
    // UI Events
    mentorCardChatNowClicked: {
        name: "Mentor Card Chat Now Clicked",
        requiredFields: ["buddyId", "feature"],
    },
    mentorCardSeeMoreStudentsClicked: {
        name: "Mentor Card See More Students Clicked",
        requiredFields: ["feature"],
    },
    feedbackFormSubmitted: {
        name: "Feedback Form Submitted",
        requiredFields: ["accountRole", "eventRating", "hasComments"],
    },
    feedbackFormSkipped: {
        name: "Feedback Form Skipped",
        requiredFields: ["accountRole"],
    },
    itemClicked: {
        name: `Item Clicked`,
        requiredFields: ["prefix"],
    },
    pageViewed: {
        name: "Page Viewed",
        requiredFields: ["prefix"],
    },
    prospectAccountVerified: {
        name: "Prospect Account Verified",
        requiredFields: [],
    },
    uniSwitcherOpened: {
        name: "UniSwitcher Opened",
        requiredFields: ["product", "originalUni"],
    },
    uniSwitcherUniSelected: {
        name: "UniSwitcher Uni Selected",
        requiredFields: ["product", "originalUni", "universitySlug"],
    },
    ageSignUpPageViewed: {
        name: "Age Sign Up Page Viewed",
        requiredFields: ["product", "universitySlug"],
    },
    continueToSignUpClickedOnAgePage: {
        name: "Continue To Sign Up  Button Clicked On Age Page",
        requiredFields: ["product", "universitySlug", "ageSuccess"],
    },
    loginClickedOnAgePage: {
        name: "Login Button Clicked On Age Page",
        requiredFields: ["product", "universitySlug"],
    },
    adminIntervention: {
        name: "Admin Intervention",
        requiredFields: ["conversationId", "messageCode"],
    },
    backClickedFromContent: {
        name: "Back button clicked from Content detail page",
        requiredFields: [],
    },
    chatNowClickedFromContent: {
        name: "Blog Detail Chat Now Clicked",
        requiredFields: ["mentorId"],
    },
    prospectDecisionStageSubmitted: {
        name: "Prospect Decision Stage Submitted",
        requiredFields: ["universityId", "universityName", "prospectStage", "where", "layout"],
    },
    prospectDecisionStageDismissed: {
        name: "Prospect Decision Stage Dismissed",
        requiredFields: ["universityId", "universityName", "where", "layout"],
    },
    prospectDecisionStagesShown: {
        name: "Prospect Shown Ver A Decision Stages",
        requiredFields: ["universityId", "universityName", "where"],
    },
    recommendationsPageViewed: {
        name: "Recommendations Page Viewed",
        requiredFields: ["universityId", "universityName"],
    },
    prospectDecisionTileStagesShown: {
        name: "Prospect Shown Tile Decision Stages",
        requiredFields: ["universityId", "universityName", "where"],
    },
    prospectDecisionShownLegacy: {
        name: "Prospect Shown Legacy Decision Stages",
        requiredFields: ["universityId", "universityName", "where"],
    },
    prospectNotInterestedSubmitted: {
        name: "Prospect Not Interested Submitted",
        requiredFields: ["universityId", "universityName", "where", "reason"],
    },
    nextBestActionsContentOpened: {
        name: "Next Best Actions Content Opened",
        requiredFields: ["universityId", "contentType", "contentId"],
    },
    nextBestActionsToggleButtonClicked: {
        name: "Next Best Actions Toggle Button Clicked",
        requiredFields: ["universityId", "universityName", "where"],
    },
    nextBestActionsShown: {
        name: "Next Best Actions Banner Shown",
        requiredFields: ["universityId", "universityName", "where"],
    },
    nextBestActionsNotShown: {
        name: "Next Best Actions Banner Not Shown",
        requiredFields: ["universityId", "universityName", "where"],
    },
    viewPostViaActions: {
        name: "View Post Via Actions",
        requiredFields: ["ambassadorId", "prospectId", "sharedURL", "shareType"],
    },
    chatWithAmbassadorsViaActions: {
        name: "Chat with Ambassadors Via Actions",
        requiredFields: ["ambassadorId", "prospectId", "sharedURL", "shareType"],
    },
    universityQuestionViewed: {
        name: "University Question Viewed",
        requiredFields: ["universityId"],
    },
    universityQuestionAnswered: {
        name: "University Question Answered",
        requiredFields: ["universityId", "questionId", "answerText", "type"],
    },
    universityQuestionCallOutClicked: {
        name: "University Question Call Out Clicked",
        requiredFields: ["universityId", "universityName", "universitySlug", "where"],
    },
    // Intro page events
    introPageCardClicked: {
        name: "Button clicked",
        requiredFields: ["universitySlug", "cta", "page", "ctaOrder"],
    },
    searchPageActionClicked: {
        name: "Button clicked",
        requiredFields: ["universitySlug", "cta", "page"],
    },
    introPageVisible: {
        name: "Intro Page Visible",
        requiredFields: ["universitySlug", "visibleTimeInMs", "visiblePercentage"],
    },
    ssoButtonClicked: {
        name: "Button clicked",
        requiredFields: ["universitySlug", "cta", "page"],
    },
    ssoButtonVisible: {
        name: "Sign up page - SSO option visible",
        requiredFields: ["universitySlug", "page"],
    },
    ssoLoginButtonVisible: {
        name: "Login page - SSO option visible",
        requiredFields: ["universitySlug"],
    },
    loginButtonClicked: {
        name: "Clicked Login Button on Login Page",
        requiredFields: ["uniSlug"],
    },
    loginPageViewed: {
        name: "Viewed Login Page - Widget Page",
        requiredFields: ["search"],
    },
    dummyChatEmailButtonClicked: {
        name: "Button clicked",
        requiredFields: ["universitySlug", "cta", "page"],
    },
};

export const itemNames = {
    buddyProfileChatNow: "Buddy Profile Chat Now",
    buddyCardChatNow: "Buddy Card Chat Now",
    chatRules: "Chat Rules",
};

export const pageNames = {
    buddiesList: "Buddies List",
    blogList: "Blog List",
    prospectSignUpStageOne: "Prospect Signup Stage 1",
    startChat: "Start Chat",
    dummyChat: "Dummy Chat",
    login: "Login",
};

/**
 * Unibuddy Products
 * Embedded University Widget, Carousel, University Buddy Cards, External Buddy Cards
 * Social Feed, PWA University Widget, Live Events, Popcard, Ucas, Chat Bubble,
 * Engagement Portal
 */
export const productSpec = {
    freshersApp: {
        NAME: "Freshers App",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    ambassadorApp: {
        NAME: "Ambassador App",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    ambassadorDashboard: {
        NAME: "Ambassador Dashboard",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedUniversityWidget: {
        NAME: "Embedded University Widget",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    pwaUniversityWidget: {
        NAME: "PWA University Widget",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedUniversityCarousel: {
        NAME: "Embedded University Carousel",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedUniversityBuddyCards: {
        NAME: "Embedded University Buddy Cards",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedExternalBuddyCards: {
        NAME: "Embedded External Buddy Cards",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedExternalBuddyCardsCarousel: {
        NAME: "Embedded External Buddy Cards Carousel",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    embeddedUniversitySocialFeed: {
        NAME: "Embedded University Social Feed",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    liveEvents: {
        NAME: "Live Events",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
            LIVE_EVENT_SLUG: "liveEventSlug",
        },
        events: {
            messageSent: {
                name: "Message Sent",
            },
        },
    },
    engagementPortal: {
        NAME: "Engagement Portal",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    openDay: {
        NAME: "Open Day",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    ucas: {
        NAME: "UCAS Widget",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    discover: {
        NAME: "Discover Widget",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            DISCOVER_SLUG: "discoverSlug",
            PRODUCT: "product",
        },
    },
    universityPopcard: {
        NAME: "University Popcard",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    universityDashboard: {
        NAME: "University Dashboard",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
    chatBubble: {
        NAME: "Chat Bubble",
        properties: {
            UNIVERSITY_SLUG: "universitySlug",
            PRODUCT: "product",
        },
    },
};

/**
 * Unibuddy Source tracking params
 * ub_medium, ub_source, ub_campaign, ub_content, invite_id
 */
export const sourceTracking = {
    sourceTrackingParams: {
        NAME: "sourceTrackingParams",
        properties: {
            UB_MEDIUM: "ub_medium",
            UB_SOURCE: "ub_source",
            UB_CAMPAIGN: "ub_campaign",
            UB_CONTENT: "ub_content",
            INVITE_ID: "invite_id",
        },
    },
};
