import React, {useState} from "react";
import {useMutation} from "react-apollo";
import {useTranslation} from "@unibuddy/intl";
import styled from "styled-components";
import {Box, Text, Button} from "@unibuddy/patron";
import useAnalytics from "shared/AnalyticsNew/hooks/useAnalytics";
import {sharedEvents} from "shared/AnalyticsNew/constants/productSpecification";
import {useUniversity} from "../../../UniversityProvider/UniversityProvider";
import {
    ProspectStageLocation,
    prospectStageMutation,
    getProspectStageQuery,
    DecisionStageLayout,
} from "../../../Chat/components/PopupQuestions/prospectStages";
import {IdentifyingStage} from "../../images/IdentifyingStage";
import {ProspectStage} from "../../../Chat/components/PopupQuestions/ProspectStage";
import {useRecommendations} from "../../../Recommendations/RecommendationsProvider/RecommendationsProvider";
import useUniversityColor from "../../../UniversityProvider/hooks/useUniversityColor";
import {IntlNamespaces} from "../../../../../translations/types";
import {
    INTL_RECOMMENDATION_PROMPT_LINK_TEXT,
    INTL_RECOMMENDATION_PROMPT_TEXT,
} from "../../../../../translations/recommendations";

const StyledButton = styled(Button)<{buttonColor: string}>`
    border: 0;
    background-color: ${(props) => props.buttonColor ?? "#0171E0"};
    &:hover:not([disabled]) {
        background-color: ${(props) => props.buttonColor ?? "#0171E0"};
        opacity: 0.85;
    }
`;

type PromptContentProps = {
    recommendationsPromptText: string;
    recommendationsPromptLinkText: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

const PromptContent: React.FC<PromptContentProps> = ({
    recommendationsPromptText,
    recommendationsPromptLinkText,
    isOpen,
    setIsOpen,
}) => {
    const color = useUniversityColor();
    return (
        <Box
            display="flex"
            flexDirection={["column", "row"]}
            justifyContent="center"
            alignItems="center"
            gap="medium"
            borderColor="#D0D9D9"
            backgroundColor="#F3F5F5"
            padding="small"
        >
            <Box flexShrink={0}>
                <IdentifyingStage />
            </Box>
            <Text color="#404446" weight="600" align={["center", "left"]}>
                {recommendationsPromptText}
            </Text>
            <StyledButton buttonColor={color} size="lg" onClick={() => setIsOpen(!isOpen)}>
                <Text color="#FFF" weight="700">
                    {recommendationsPromptLinkText}
                </Text>
            </StyledButton>
        </Box>
    );
};

type RecommendationPromptProps = {
    showPopup: boolean;
    batchSize: number;
    location: ProspectStageLocation;
    hideTrigger?: boolean;
};

export const RecommendationPrompt: React.FC<RecommendationPromptProps> = ({
    showPopup,
    batchSize,
    location,
    hideTrigger,
}) => {
    const [isOpen, setIsOpen] = useState(showPopup);

    const {id, name} = useUniversity();
    const {trackEvent} = useAnalytics();
    const {t, ready} = useTranslation([IntlNamespaces.RECOMMENDATIONS]);

    const [saveUserDecisionStage] = useMutation(prospectStageMutation);
    const {getRecommendationsCallback} = useRecommendations();

    const handleSubmit = React.useCallback(
        async (prospectStage) => {
            try {
                await saveUserDecisionStage({
                    variables: {
                        universityId: id,
                        decisionStage: prospectStage,
                        prospectStageLocation: location,
                    },
                    refetchQueries: [
                        {
                            query: getProspectStageQuery,
                            variables: {
                                universityId: id,
                            },
                        },
                    ],
                });
                await getRecommendationsCallback(batchSize);
            } catch (e) {
                console.error("Error submitting university applied question", e);
            }

            trackEvent(sharedEvents.prospectDecisionStageSubmitted.name, {
                universityId: id,
                universityName: name,
                prospectStage,
                where: location,
                layout: DecisionStageLayout.STACK,
            });
            setIsOpen(!isOpen);
        },
        [
            batchSize,
            getRecommendationsCallback,
            id,
            isOpen,
            location,
            name,
            saveUserDecisionStage,
            trackEvent,
        ],
    );

    const handleDismiss = React.useCallback(() => {
        trackEvent(sharedEvents.prospectDecisionStageDismissed.name, {
            universityId: id,
            universityName: name,
            where: location,
            layout: DecisionStageLayout.STACK,
        });
        setIsOpen(!isOpen);
    }, [trackEvent, id, name, isOpen, location]);

    if (!ready) return <Text>Loading...</Text>;

    const recommendationsPromptText = t(
        INTL_RECOMMENDATION_PROMPT_TEXT.key,
        INTL_RECOMMENDATION_PROMPT_TEXT.defaultValue,
    );

    const recommendationsPromptLinkText = t(
        INTL_RECOMMENDATION_PROMPT_LINK_TEXT.key,
        INTL_RECOMMENDATION_PROMPT_LINK_TEXT.defaultValue,
    );

    return (
        <>
            {!hideTrigger && (
                <PromptContent
                    recommendationsPromptText={recommendationsPromptText}
                    recommendationsPromptLinkText={recommendationsPromptLinkText}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
            {isOpen && (
                <ProspectStage
                    onDismiss={handleDismiss}
                    onSubmit={handleSubmit}
                    isOpen={isOpen}
                    location={location}
                />
            )}
        </>
    );
};
