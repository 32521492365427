// / action types

export const ADD_MENTOR_FILTER = "ADD_MENTOR_FILTER";
export const REMOVE_MENTOR_FILTER = "REMOVE_MENTOR_FILTER";

// / action creators

export function addMentorFilter(field, label, value) {
    return {
        type: ADD_MENTOR_FILTER,
        field,
        label,
        value,
    };
}

export function removeMentorFilter(field, label, value) {
    return {
        type: REMOVE_MENTOR_FILTER,
        field,
        label,
        value,
    };
}
