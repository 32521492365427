/* eslint-disable jsx-a11y/aria-props*/
import React from "react";
import StudentsHeaderTab from "./StudentsHeaderTab";
import StaffHeaderTab from "./StaffHeaderTab";

export default function BuddiesGroupsSubNavHeaderTab(props) {
    return (
        <React.Fragment>
            {props.activeTabs.students ? (
                <StudentsHeaderTab
                    {...props}
                    onClick={() => props.onSelectTab("Students")}
                    to={`${props.rootUrl}/buddies/students`}
                    aria-current={props.currentPage === "Students" ? "page" : false}
                />
            ) : null}
            {props.activeTabs.staff ? (
                <StaffHeaderTab
                    {...props}
                    onClick={() => props.onSelectTab("Staff")}
                    to={`${props.rootUrl}/buddies/staff`}
                    aria-current={props.currentPage === "Staff" ? "page" : false}
                />
            ) : null}
        </React.Fragment>
    );
}
