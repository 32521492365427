import React from "react";
import {boolean, date} from "yup";
import {format} from "date-fns";
import {
    Box,
    Button,
    CheckBox,
    DateInput,
    Form,
    FormGroup,
    Stack,
    SubmitButton,
    TextBlock,
} from "@unibuddy/patron";
import compose from "lodash/flowRight";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {Trans, useTranslation} from "@unibuddy/intl";
import get from "lodash/get";

import AlreadyHaveAccount from "shared/Register/components/AlreadyHaveAccount/AlreadyHaveAccount";
import PageHeader from "shared/Shared/components/PageHeader/PageHeader";
import {PRIVACY_POLICY} from "shared/Shared/links/links";
import ThemedLink from "shared/Navigation/components/ThemedLink/ThemedLink";
import {PRIVACY_CHECKED} from "shared/Shared/validations/validationMessages";
import withAuth from "shared/Auth/withAuth/withAuth";
import updateApplicantMutation from "shared/Applicant/mutations/updateApplicantMutation";
import {setMe} from "shared/Auth/actions/authActions";
import {rootURL} from "shared/Utils/WhereAmI";
import ErrorMessage from "shared/Forms/components/ErrorMessage/ErrorMessage";
import {productSpec} from "shared/AnalyticsNew/constants/productSpecification";
import withAnalytics from "shared/AnalyticsNew/withAnalytics/withAnalytics";
import Auth from "shared/Auth/auth";

import {PageTitle} from "../../../../components/PageTitle/PageTitle";
import {getAgeGateInfo} from "./AgeVerificationApi";
import {WideContainer} from "../../../../components/WideContainer/WideContainer";
import {IntlNamespaces} from "../../../../translations/types";
import {
    INTL_AGE_VERIFICATION,
    INTL_CONTINUE_TO_LOGIN,
    INTL_CONTINUE_TO_SIGN_UP,
    INTL_DATE_OF_BIRTH_LABEL,
    INTL_FUTURE_AWAITS,
    INTL_PRIVACY_TEXT,
    INTL_TODAY,
    INTL_VERIFY_HOW_OLD,
} from "../../../../translations/verifyAge";
import {
    INTL_DATE_OF_BIRTH_REQUIRED,
    INTL_PRIVACY_CHECKED,
} from "../../../../translations/validations";

const Container = styled(WideContainer)`
    box-shadow: 0 3px 11px ${({theme}) => theme.colors.grey300};
`;

export const VerifyAgeComponent = (props) => {
    const {t} = useTranslation([IntlNamespaces.VERIFY_AGE, IntlNamespaces.VALIDATIONS]);
    const rootUrl = rootURL(props.location.pathname);
    const alreadyLoggedIn = Auth.loggedIn(props.authState);
    const ageInfo = get(props.authState, "me.applicant.ageVerificationInfo", false);
    const redirectToBuddies = () => {
        const hasNextPath = props.location.state && props.location.state.nextPathname;
        const path = hasNextPath ? props.location.state.nextPathname : `${rootUrl}/buddies`;
        return props.history.replace({pathname: path, search: props.location.search});
    };

    React.useEffect(() => {
        props.analytics.events.ageSignUpPageViewed({
            product: productSpec.embeddedUniversityWidget.NAME,
            universitySlug: props.universitySlug,
        });
        if (ageInfo) {
            return redirectToBuddies();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (values, formActions) => {
        try {
            const dateOfBirth = format(values.dob, "yyyy-MM-dd");
            const response = await getAgeGateInfo(dateOfBirth);
            await props.analytics.events.continueToSignUpClickedOnAgePage({
                product: productSpec.embeddedUniversityWidget.NAME,
                universitySlug: props.universitySlug,
                ageSuccess: !response.isMinor,
            });
            const ageVerificationInfo = {
                countryCode: response.country,
                age: response.age,
                consentAgeForCountry: response.consentAgeForCountry,
                isMinor: response.isMinor,
                dateOfBirth,
            };
            props.dispatch(
                setMe({
                    applicant: {
                        ageVerificationInfo,
                    },
                }),
            );

            if (alreadyLoggedIn) {
                await props.client.mutate({
                    mutation: updateApplicantMutation,
                    variables: {ageVerificationInfo},
                });

                if (response.isMinor) {
                    return props.history.replace({
                        pathname: `${rootUrl}/auth/minor`,
                        search: props.location.search,
                    });
                }

                return redirectToBuddies();
            }
            props.setReload(true);
        } catch (e) {
            formActions.setStatus({error: true});
        } finally {
            formActions.setSubmitting(false);
        }
    };

    return (
        <Container>
            <PageTitle
                title={t(
                    `${IntlNamespaces.VERIFY_AGE}:${INTL_AGE_VERIFICATION.key}`,
                    INTL_AGE_VERIFICATION.defaultValue,
                )}
            />
            <Box padding={["small", "large"]}>
                <Stack space={"small"} dividers>
                    <Box paddingX={["small", "xxlarge"]}>
                        <Stack space={"medium"}>
                            <TextBlock align={"center"} size={"standard"}>
                                <PageHeader
                                    title={t(
                                        `${IntlNamespaces.VERIFY_AGE}:${INTL_FUTURE_AWAITS.key}`,
                                        INTL_FUTURE_AWAITS.defaultValue,
                                    )}
                                />
                            </TextBlock>
                            <TextBlock align={"center"} size={"standard"}>
                                {t(
                                    `${IntlNamespaces.VERIFY_AGE}:${INTL_VERIFY_HOW_OLD.key}`,
                                    INTL_VERIFY_HOW_OLD.defaultValue,
                                )}
                            </TextBlock>
                            <Form
                                onSubmit={onSubmit}
                                validationSchema={{
                                    dob: date().required(
                                        t(
                                            `${IntlNamespaces.VALIDATIONS}:${INTL_DATE_OF_BIRTH_REQUIRED.key}`,
                                            INTL_DATE_OF_BIRTH_REQUIRED.defaultValue,
                                        ),
                                    ),
                                    privacyChecked: boolean()
                                        .required(PRIVACY_CHECKED)
                                        .oneOf(
                                            [true],
                                            t(
                                                `${IntlNamespaces.VALIDATIONS}:${INTL_PRIVACY_CHECKED.key}`,
                                                INTL_PRIVACY_CHECKED.defaultValue,
                                            ),
                                        ),
                                }}
                                initialValues={{privacyChecked: false, dob: ""}}
                            >
                                {({isValid, status}) => (
                                    <Stack space={"medium"}>
                                        <FormGroup
                                            label={t(
                                                `${IntlNamespaces.VERIFY_AGE}:${INTL_DATE_OF_BIRTH_LABEL.key}`,
                                                INTL_DATE_OF_BIRTH_LABEL.defaultValue,
                                            )}
                                        >
                                            <DateInput
                                                name={"dob"}
                                                showMonthDropdown
                                                showYearDropdown
                                                dateFormat="dd-MM-yyyy"
                                                placeholder={"DD-MM-YYYY"}
                                                maxDate={new Date()}
                                                todayButton={
                                                    <Button size="sm" clear>
                                                        {t(
                                                            `${IntlNamespaces.VERIFY_AGE}:${INTL_TODAY.key}`,
                                                            INTL_TODAY.defaultValue,
                                                        )}
                                                    </Button>
                                                }
                                                required
                                            />
                                        </FormGroup>
                                        <CheckBox id="privacy" name="privacyChecked" required>
                                            <Trans t={t} i18nKey={INTL_PRIVACY_TEXT.key}>
                                                {"I agree to Unibuddy’s "}
                                                <ThemedLink
                                                    external
                                                    to={PRIVACY_POLICY}
                                                    target="_blank"
                                                >
                                                    Privacy Policy
                                                </ThemedLink>
                                            </Trans>
                                        </CheckBox>
                                        {status && status.error ? (
                                            <ErrorMessage>{"Error Occurred"}</ErrorMessage>
                                        ) : null}
                                        <SubmitButton
                                            id="continue"
                                            color="primary"
                                            disabled={!isValid}
                                            block
                                        >
                                            {alreadyLoggedIn
                                                ? t(
                                                      `${IntlNamespaces.VERIFY_AGE}:${INTL_CONTINUE_TO_LOGIN.key}`,
                                                      INTL_CONTINUE_TO_LOGIN.defaultValue,
                                                  )
                                                : t(
                                                      `${IntlNamespaces.VERIFY_AGE}:${INTL_CONTINUE_TO_SIGN_UP.key}`,
                                                      INTL_CONTINUE_TO_SIGN_UP.defaultValue,
                                                  )}
                                        </SubmitButton>
                                    </Stack>
                                )}
                            </Form>
                        </Stack>
                    </Box>
                    {!alreadyLoggedIn ? (
                        <AlreadyHaveAccount
                            onClick={() => {
                                props.analytics.events.loginClickedOnAgePage({
                                    product: productSpec.embeddedUniversityWidget.NAME,
                                    universitySlug: props.universitySlug,
                                });
                                return props.history.push({
                                    pathname: `${rootUrl}/auth/login`,
                                    search: props.location.search,
                                });
                            }}
                        />
                    ) : null}
                </Stack>
            </Box>
        </Container>
    );
};

export default compose(withRouter, withAuth, withAnalytics)(VerifyAgeComponent);
