import PropTypes from "prop-types";
import React from "react";
import BuddiesTabRoutes from "./BuddiesTabRoutes";

const BuddiesTab = (props) => (
    <BuddiesTabRoutes universitySlug={props.match.params.universitySlug} {...props} />
);

BuddiesTab.propTypes = {
    universitySlug: PropTypes.string.isRequired,
};

export default BuddiesTab;
