import {useQuery} from "react-apollo";

import {universityQuestionsWithAnswersQuery} from "../requests";
import {useUniversity} from "../../../../UniversityProvider/UniversityProvider";
import {UniversityQuestionsWithAnswersQueryQuery} from "../../../../../../types/generated";

/**
 * Determine how many questions a prospect has not answered
 */
export function useUniversityMessageCount(): number {
    const university = useUniversity();
    const questionIdWithAnswersResponse = useQuery<UniversityQuestionsWithAnswersQueryQuery>(
        universityQuestionsWithAnswersQuery,
        {
            variables: {
                universityQuestionInput: {
                    universityId: university.id,
                    universitySlug: university.slug,
                    context: {type: "WIDGET"},
                },
                universityQuestionAnswerInput: {
                    universityId: university.id,
                    context: {type: "WIDGET"},
                },
            },
        },
    );
    const data = questionIdWithAnswersResponse?.data?.universityQuestions ?? [];

    if (questionIdWithAnswersResponse.error || questionIdWithAnswersResponse.loading) {
        return 0;
    }

    /* Answers being a length of `0` means the prospect has not submitted an answer */
    const messageCount = data.reduce((acc: number, cur) => {
        if (cur.answers.length === 0) {
            return acc + 1;
        }
        return acc;
    }, 0);

    return messageCount;
}
